import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Post } from 'store/sna/snaApi'
import { FilterManager, Filters } from './FilterManager'
import { AccordionBody, AccordionHeader, AccordionItem, Input, Label, UncontrolledAccordion } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setFilters } from 'store/sna/postSlice'
import { checkEmptyArrays } from 'utils/utils'
import useSharedPostsFindAllQuery from '../../hooks/useSharedPostsFindAllQuery'

type nestedKeys =
  | 'topics.topic'
  | 'globalVerdictLabel.label'
  | 'behavior.toxicity.label'
  | 'behavior.sentimentPolarity.sentiment'
  | 'behavior.emotions.emotion'

type KeyLabelType = { key: keyof Post | nestedKeys; label: string }

// NOTE If we want to add more filters, we should add them here.
const keysLabel: KeyLabelType[] = [
  { key: 'platform', label: 'Platforms' },
  { key: 'lang', label: 'Languages' },
  { key: 'nbShared', label: 'Shared' },
  { key: 'analysed', label: 'Analysed' },
  { key: 'checked', label: 'Verified' },
  { key: 'topics.topic', label: 'Topics' },
  { key: 'garm', label: 'GARM' },
  { key: 'globalVerdictLabel.label', label: 'Verdicts' },
  { key: 'behavior.sentimentPolarity.sentiment', label: 'Sentiments' },
  { key: 'behavior.toxicity.label', label: 'Toxicity' },
  { key: 'behavior.emotions.emotion', label: 'Emotions' },
]

const keys: (keyof Post | nestedKeys)[] = keysLabel.map((k) => k.key)

export type FilteredKeys = (typeof keys)[number]

export const TwitterFilters = () => {
  const dispatch = useDispatch()
  const previousAlertId = useRef<string | undefined>()
  const alertId = useSelector((state: RootState) => state.post.currentAlertId)
  const searchAfter = useSelector((state: RootState) => state.post.searchAfter)

  const { posts } = useSharedPostsFindAllQuery()

  const filtersOptions = useMemo(
    () =>
      FilterManager.createFiltersOptions(
        posts?.filter((val) => val !== undefined)?.filter((post) => post?.sharedFrom == null) || [],
        keys as (keyof Post)[],
      ) as {
        key: FilteredKeys
        values: any[]
        count: any
      }[],
    [posts],
  )

  const allCheckedInitialState = useMemo(
    () => FilterManager.initialState(filtersOptions, { defaultNbShared: posts && posts?.length < 100 ? 0 : 1 }),
    [filtersOptions, posts],
  )

  // state of opened menu for each key and checked value
  const [checked, setChecked] = useState<{ [key in FilteredKeys]: string[] }>(allCheckedInitialState)

  const getNewFilters = (): Filters<Post>[] =>
    filtersOptions.map(({ key, values }) => {
      const checkedValues = checked[key] || []
      // If value is all then select all values
      if (checkedValues.includes('all')) {
        const allSelected = checkedValues.length <= values.length
        const newValues = allSelected ? values.filter((value) => value !== 'all') : []
        setChecked((prevState) => ({ ...prevState, [key]: newValues }))
      }
      return {
        key: key as unknown as keyof Post,
        values: values.filter((value) => checkedValues.includes(value)),
      }
    })

  React.useEffect(
    () => {
      // Set and select all possible filter values when the selected alert changes
      if (previousAlertId.current !== `${alertId}-${searchAfter}`) {
        let checkedOptions = allCheckedInitialState
        setChecked(checkedOptions)
        if (!checkEmptyArrays(allCheckedInitialState)) previousAlertId.current = `${alertId}-${searchAfter}`
      }
    },
    // eslint-disable-next-line
    [allCheckedInitialState],
  )

  const checkEmptyFiltersOptions = (filters: Filters<Post>[]) => !(filters && filters?.length && filters?.[0]?.values?.length)

  useEffect(() => {
    const filters = getNewFilters()
    if (!checkEmptyFiltersOptions(filters)) dispatch(setFilters(filters))
    // eslint-disable-next-line
  }, [checked])

  const trackClick = useCallback(
    (key: FilteredKeys, value: string) => {
      const isValueNumeric = !isNaN(parseFloat(value))

      if (isValueNumeric) {
        setChecked((prevState) => ({
          ...prevState,
          [key]: [value],
        }))
      } else {
        const currentValues = checked[key] ?? []
        const isValueChecked = currentValues.includes(value)
        const newValues = isValueChecked ? currentValues.filter((v) => v !== value) : [...currentValues, value]

        setChecked((prevState) => ({
          ...prevState,
          [key]: newValues,
        }))
      }
    },
    [checked],
  )

  const getCountByValue = (count: { name: string; count: number }[], value: string) => {
    const element = count.find((e) => e.name === `${value}`)
    return element?.count
  }

  // open menu for each key
  return (
    <div className="postsFilters">
      <UncontrolledAccordion toggle={() => {}}>
        {filtersOptions.map(({ key, values, count }, index) => {
          const allSelected = checked[key]?.length === values.length
          return (
            <div key={key} style={{ width: '100%' }}>
              <AccordionItem className="filterContainer">
                <AccordionHeader
                  targetId={key}
                  // expandIcon={<img src="/icons/icon--expand.svg" alt="expand" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <div className="filterHeader">
                    <span>{keysLabel.find((e) => e.key === key)?.label} </span>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={key}>
                  <div className="options-box">
                    {isNaN(parseFloat(values[0])) && values?.length > 1 && (
                      <div className="selectAll" onClick={() => trackClick(key, 'all')}>
                        {allSelected ? 'Unselect all ' : 'Select all'}
                      </div>
                    )}

                    {values.map((value) => (
                      <div key={`op-${index}-${value}`} className="filterOptions" onClick={() => trackClick(key, value)}>
                        <div>
                          <Input
                            type={!isNaN(parseFloat(values[0])) ? 'radio' : 'checkbox'}
                            readOnly
                            checked={!!checked[key]?.includes(value)}
                            className="me-2"
                          />
                          <Label for={value?.toString()}>{value?.toString()?.replaceAll('_', ' ')?.toLowerCase() ?? 'NA'}</Label>
                        </div>
                        {isNaN(parseFloat(values[0])) && <span className="count">{getCountByValue(count, value)}</span>}
                      </div>
                    ))}
                  </div>
                </AccordionBody>
              </AccordionItem>
              {index + 1 !== filtersOptions.length && <hr style={{ margin: '4px 12px !important' }} />}
            </div>
          )
        })}
      </UncontrolledAccordion>
    </div>
  )
}
