import { DropdownToggle } from 'reactstrap'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import OptionsDropdown from './OptionsDropdown'
import AllOptionsDropdown from './AllOptionsDropdown'
import { customFilters, dateFilterOptions } from './filters'
import { isoDateToLocale } from '../../utils/dates'
import ISO6391 from 'iso-639-1'
import ISO3166 from 'iso-3166-1'
import { SUPPORTED_LANGUAGES_CODES } from '../../utils/constants'
import { useOnChangeInterval } from './hooks/useOnChangeInterval'
import { useHighLight } from './hooks/useHighLight'
import { useClickOutside } from './hooks/useClickOutside'
import { useSharedSearchMutation } from './hooks/useSharedSearchMutation'
import { useSharedHighlightMutation } from './hooks/useSharedHighlightMutation'
import { Keyphrase } from './Keyphrase'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setKeywords } from 'store/search/searchSlice'

const fieldsNames = {
  KEYPHRASE: 'Keyphrase',
  INTERVAL: 'Interval',
  LANGUAGES: 'Languages',
  COUNTRIES: 'Countries',
  CLAIM: 'Claim',
  DATES: 'Dates',
}

const SearchBar = () => {
  const dispatch = useDispatch()
  const searchRef = useClickOutside(() => setActive(false))
  const keywords = useSelector((state: RootState) => state.search.keywords)

  const { control, register, handleSubmit, watch, getValues, setFocus, setValue } = useForm()
  const [postSearch, { isLoading, reset }] = useSharedSearchMutation()
  const [, { reset: resetHighlight }] = useSharedHighlightMutation()
  const [active, setActive] = useState(false)

  useOnChangeInterval(fieldsNames.INTERVAL, fieldsNames.DATES, control, watch, setValue)
  useHighLight()

  const isFiltersNotEmpty = () =>
    watch('custom-filters') && Object.values(watch('custom-filters')).some((arr) => (arr as string[])?.length > 0)

  const handleSearchInputKeyDown = (event: any) => {
    if (event.key === 'Enter' && event?.target?.value !== '') {
      const keyphrases = getValues(fieldsNames.KEYPHRASE) || []
      setValue(fieldsNames.KEYPHRASE, [...keyphrases, event?.target?.value])
      setValue(fieldsNames.CLAIM, '')
    }
  }

  const onDeleteKeyphrase = (event: React.MouseEvent<HTMLElement>, text: string) => {
    event?.stopPropagation()
    const keyphrases = getValues(fieldsNames.KEYPHRASE)
    keyphrases.splice(keyphrases.indexOf(text), 1)
    setValue(fieldsNames.KEYPHRASE, keyphrases)
    dispatch(setKeywords(keyphrases))
    setTimeout(() => setFocus(fieldsNames.CLAIM), 0)
  }

  const handleSearchInputClick = () => {
    setActive(!active)
    setTimeout(() => setFocus(fieldsNames.CLAIM), 0)
  }

  const onSubmit = () => {
    reset()
    resetHighlight()
    dispatch(setKeywords(getValues(fieldsNames.KEYPHRASE)))
    const languages =
      getValues(fieldsNames.LANGUAGES) instanceof Array
        ? getValues(fieldsNames.LANGUAGES).map((l: string) => ISO3166.whereAlpha2(l)?.alpha3)
        : []
    const countries =
      getValues(fieldsNames.COUNTRIES) instanceof Array
        ? getValues(fieldsNames.COUNTRIES).map((l: string) => ISO3166.whereAlpha2(l)?.alpha3)
        : []

    postSearch({
      articleSearchRequest: {
        keyphrases: getValues(fieldsNames.KEYPHRASE),
        publication_date_start: getValues(fieldsNames.DATES)?.start,
        publication_date_end: getValues(fieldsNames.DATES)?.end,
        top_articles_with_content: 50,
        with_articles_identifiers_per_day: false,
        with_aggregated_stats: false,
        result_size_max: 50,
        languages,
        source_countries: countries,
      },
    })
  }

  useEffect(() => {
    setValue(fieldsNames.KEYPHRASE, keywords)
  }, [keywords, setValue])

  return (
    <div className="search-bar">
      <div className="default-filters d-flex">
        <div className={`search-item search w-50 ${active ? 'active' : ''}`} onClick={handleSearchInputClick} ref={searchRef}>
          <div className="d-flex">
            <label className="text-nowrap">Search Terms</label>
            <div className="terms">
              {watch(fieldsNames.KEYPHRASE) &&
                watch(fieldsNames.KEYPHRASE)?.map((text: string, index: number) => (
                  <Keyphrase key={`${text}-${index}`} text={text} onDelete={onDeleteKeyphrase} />
                ))}
            </div>
          </div>
          <div className="desc">
            {(watch(fieldsNames.KEYPHRASE)?.length > 0 &&
              watch(fieldsNames.KEYPHRASE)?.map((text: string, index: number) => (
                <Keyphrase key={`${text}-${index}`} text={text} onDelete={onDeleteKeyphrase} />
              ))) ||
              'Of the source you search'}
          </div>
          <div className="text-input">
            <input
              type="text"
              {...register(fieldsNames.CLAIM)}
              style={{ border: 'none', width: '100%' }}
              onKeyDown={(event) => handleSearchInputKeyDown(event)}
            />
          </div>
        </div>
        <div className="search-item w-25">
          <OptionsDropdown
            header={fieldsNames.LANGUAGES}
            options={ISO6391.getLanguages(SUPPORTED_LANGUAGES_CODES)}
            keys={{ id: 'code', label: 'name' }}
            register={register}>
            <DropdownToggle tag="div" style={{ height: '100%' }}>
              <div className="dropdown-toggle">
                <label>Select language</label>
                {(watch?.(fieldsNames.LANGUAGES)?.length && (
                  <div className="desc-1">
                    {' '}
                    {watch?.(fieldsNames.LANGUAGES)
                      .map((l: string) => ISO6391.getName(l))
                      ?.join(', ')}{' '}
                  </div>
                )) || <p>Of the source you search</p>}
              </div>
            </DropdownToggle>
          </OptionsDropdown>
        </div>
        <div className="search-item w-25">
          <OptionsDropdown
            header={fieldsNames.COUNTRIES}
            options={ISO3166.all()}
            keys={{ id: 'alpha2', label: 'country' }}
            register={register}>
            <DropdownToggle tag="div" style={{ height: '100%' }}>
              <div className="dropdown-toggle">
                <label>Select Country</label>
                {(watch?.(fieldsNames.COUNTRIES)?.length && (
                  <div className="desc-1">
                    {' '}
                    {watch?.(fieldsNames.COUNTRIES)
                      .map((l: string) => ISO3166.whereAlpha2(l)?.country)
                      ?.join(', ')}{' '}
                  </div>
                )) || <p>Of the source you search</p>}
              </div>
            </DropdownToggle>
          </OptionsDropdown>
        </div>
        <div className="search-item w-25">
          <OptionsDropdown header={fieldsNames.INTERVAL} options={dateFilterOptions} defaultChecked="Last 12 Month" register={register}>
            <DropdownToggle tag="div" style={{ height: '100%' }}>
              <div className="dropdown-toggle">
                <label>Select interval</label>
                {(watch?.(fieldsNames.DATES) && (
                  <div className="desc-1 d-flex" style={{ gap: '4px' }}>
                    <div className="p-1 font-size-12 lh-1 fw-bold">{isoDateToLocale(watch?.(fieldsNames.DATES)?.start)}</div>
                    <div className="p-1 font-size-12 lh-1 fw-bold">{isoDateToLocale(watch?.(fieldsNames.DATES)?.end)}</div>{' '}
                  </div>
                )) || <p>Of the source you search</p>}
              </div>
            </DropdownToggle>
          </OptionsDropdown>
        </div>
      </div>
      <div className="extras-filters">
        <AllOptionsDropdown filters={customFilters} register={register}>
          <DropdownToggle tag="a" className={`rounded-button ${isFiltersNotEmpty() ? 'filled' : ''}`}>
            <span className="bx bx-slider-alt"></span>
          </DropdownToggle>
        </AllOptionsDropdown>
        <a
          href="/#"
          className={`rounded-button search ${
            isLoading || (watch(fieldsNames.KEYPHRASE) && watch(fieldsNames.KEYPHRASE)?.length === 0) ? 'disabled' : ''
          }`}
          onClick={handleSubmit(onSubmit)}>
          <span className="bx bx-search-alt"></span>
        </a>
      </div>
    </div>
  )
}

export default SearchBar
