import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Form, Input, Label } from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { MultiSelect } from 'react-multi-select-component'
import { SUPPORTED_LANGUAGES_CODES } from '../../../utils/constants'
import ISO6391 from 'iso-639-1'
import { useAlertsNewMutation, useAlertsUpdateAlertMutation } from 'store/sna/snaApi'
import { dateToISO } from 'utils/dates'
import NotificationsTable from './NotificationsTable'

const AlertForm = ({ isEdit, alert, closeModal }) => {
  const [updateAlert, { error: errorUpdate }] = useAlertsUpdateAlertMutation()
  const [createAlert, { error: errorCreate }] = useAlertsNewMutation()

  const FIELDS = {
    TEXT: { name: 'text', default: alert?.text ?? '' },
    START_TIME: { name: 'startTime', default: (alert?.startTime && new Date(alert?.startTime)) ?? new Date() },
    END_TIME: { name: 'endTime', default: (alert?.endTime && new Date(alert?.endTime)) ?? '' },
    REFRESH_RATE: { name: 'refreshRateMinutes', default: alert?.refreshRateMinutes ?? 30, disabled: true },
    LANG: {
      name: 'lang',
      default: alert?.lang?.map((l) => ({ value: l, label: ISO6391.getName(l) })) ?? [{ value: 'fr', label: 'French' }],
    },
    RETENTION_DAYS: { name: 'dataRetentionDays', default: alert?.dataRetentionDays ?? 60 },
  }

  const defaultValues = Object.keys(FIELDS).reduce((obj, fieldName) => {
    return { ...obj, [FIELDS[fieldName].name]: FIELDS[fieldName].default || '' }
  }, {})

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm({
    resolver: yupResolver(yup.object().shape({ [FIELDS.TEXT.name]: yup.string(), [FIELDS.REFRESH_RATE.name]: yup.number().min(2) })),
    mode: 'onChange',
    defaultValues,
  })

  const onSubmit = async (data) => {
    if (isEdit) {
      try {
        const payload = await updateAlert({
          alertId: alert?.id,
          alertUpdate: {
            ...data,
            lang: data?.lang.sort((a, b) => a.label.localeCompare(b.label)).map((l) => l.value),
            startTime: dateToISO(data?.startTime),
            endTime: dateToISO(data?.endTime),
          },
        }).unwrap()
        payload && closeModal()
      } catch (error) {
        console.error('rejected', error)
      }
      return
    }
    try {
      const payload = await createAlert({
        alert: {
          ...data,
          lang: data?.lang.sort((a, b) => a.label.localeCompare(b.label)).map((l) => l.value),
          startTime: dateToISO(data?.startTime),
          endTime: dateToISO(data?.endTime),
        },
      }).unwrap()
      payload && closeModal()
    } catch (error) {
      console.error('rejected', error)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-container">
        <Label className="text-black">Text</Label>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Input
              type="text"
              placeholder="text..."
              value={value}
              onChange={onChange}
              className={`${errors?.[FIELDS.TEXT.name] ? 'error' : ''}`}
            />
          )}
          name={FIELDS.TEXT.name}
          control={control}
          defaultValue=""
        />
      </div>
      <div className="d-flex" style={{ gap: 10 }}>
        <div className="input-container mt-2">
          <Label className="text-black">Start date</Label>

          <DatePicker
            selected={watch(FIELDS.START_TIME.name)}
            onChange={(d) => {
              setValue(FIELDS.START_TIME.name, d)
            }}
            maxDate={new Date()}
            className="form-floating"
            placeholderText={''}
            customInput={<Input type="text" {...register(FIELDS.START_TIME.name)} className={'text-muted'} />}
          />
        </div>
        <div className="input-container mt-2">
          <Label className="text-black">End date</Label>
          <DatePicker
            selected={watch(FIELDS.END_TIME.name)}
            onChange={(d) => {
              setValue(FIELDS.END_TIME.name, d)
            }}
            minDate={new Date().setDate(new Date().getDate() - 2)}
            className="form-floating"
            placeholderText={''}
            customInput={<Input type="text" {...register(FIELDS.END_TIME.name)} className={'text-muted'} />}
          />
        </div>
      </div>
      <div className="input-container mt-2">
        <Label className="text-black">Languages</Label>
        <MultiSelect
          options={ISO6391.getLanguages(SUPPORTED_LANGUAGES_CODES)
            .map((element) => ({ value: element.code, label: element.name }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          value={watch(FIELDS.LANG.name) ?? []}
          onChange={(lang) => setValue(FIELDS.LANG.name, lang)}
          labelledBy="Select"
        />
      </div>

      <div className="input-container mt-2">
        <Label className="text-black">Refresh rate (minutes)</Label>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Input value={value} onChange={onChange} className={`${errors?.[FIELDS.REFRESH_RATE.name] ? 'error' : ''}`} />
          )}
          name={FIELDS.REFRESH_RATE.name}
          control={control}
        />
      </div>

      <div className="input-container mt-2">
        <Label className="text-black">Data Retention Days</Label>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Input value={value} onChange={onChange} className={`${errors?.[FIELDS.RETENTION_DAYS.name] ? 'error' : ''}`} />
          )}
          name={FIELDS.RETENTION_DAYS.name}
          control={control}
        />
      </div>
      {errorCreate?.data?.errors && (
        <div className="d-flex">
          {Object.entries(errorCreate?.data?.errors?.json).map(([key, value]) => {
            return (
              <span className="error" key={`error${key}`}>
                {key}: {value}
              </span>
            )
          })}
        </div>
      )}
      {errorUpdate?.data?.errors && (
        <div className="d-flex flex-column">
          {Object.entries(errorUpdate?.data?.errors?.json).map(([key, value]) => {
            return (
              <span className="error" key={`error${key}`}>
                {key}: {value}
              </span>
            )
          })}
        </div>
      )}
      <NotificationsTable />
      <div className="mt-3 d-grid">
        <button className="btn btn-primary btn-block" disabled={!isValid || isSubmitting} onClick={handleSubmit(onSubmit)}>
          Save
        </button>
      </div>
    </Form>
  )
}
export default AlertForm
