import { apiAnaSlice } from './store/ana/anaApiSlice'
import { postSlice } from './store/sna/postSlice'
import { apiSnaSlice } from './store/sna/snaApiSlice'
import { apiSlice } from './store/nlpApiSlice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userReducer'
import recordingReducer from './reducers/recordingReducer'
import currentUserReducer from './reducers/currentUserReducer'
import flashMessageReducer from './reducers/flashMessageReducer'
import layoutReducer from './reducers/layoutReducer'
import stopWordReducer from './reducers/stopWordReducer'
import { nlpApi } from './store/nlpApi'
import { checkSlice } from 'store/check/checkSlice'
import { searchSlice } from 'store/search/searchSlice'
import { setupListeners } from '@reduxjs/toolkit/query'

const appReducer = combineReducers({
  users: userReducer,
  recordings: recordingReducer,
  currentUser: currentUserReducer,
  flashMessage: flashMessageReducer,
  layout: layoutReducer,
  stopWords: stopWordReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [apiSnaSlice.reducerPath]: apiSnaSlice.reducer,
  [apiAnaSlice.reducerPath]: apiAnaSlice.reducer,
  post: postSlice.reducer,
  check: checkSlice.reducer,
  search: searchSlice.reducer,
})

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(nlpApi.middleware, apiSnaSlice.middleware, apiAnaSlice.middleware),
})

export { store }

export type RootState = ReturnType<typeof store.getState>

setupListeners(store.dispatch)
