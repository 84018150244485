import { format, isValid, parseISO } from 'date-fns'
import { useMemo, useState } from 'react'
import ReactHighlightWords from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { countryCodeEmoji } from 'country-code-emoji'
import ISO3166 from 'iso-3166-1'
import { updateDocument } from 'store/check/checkSlice'
import { useClaimsTranslateMutation } from 'store/sna/snaApi'
import { truncateText } from 'utils/formatting'
import AuthService from 'auth/AuthService'
import { franc } from 'franc-min'

const Article = ({
  tagId,
  id,
  url,
  verdictLabel,
  authorName,
  publisherName,
  publisherCountry,
  publisherKind,
  publisherCategory,
  datePublished,
  title,
  translatedTitle,
  headline,
  passages,
  className,
}) => {
  const [showTranslation, setShowTranslation] = useState(false)
  const [translateClaim, { isLoading }] = useClaimsTranslateMutation()

  const { status, reportHighlights: highlights } = useSelector((state) => state.check)

  const dispatch = useDispatch()

  const passageInUserLang = useMemo(() => {
    if (passages?.[0]) {
      const userLang = AuthService.getLang()
      const textLang = franc(passages?.[0].text)
      return ISO3166.whereAlpha2(userLang)?.alpha3 === textLang.toUpperCase()
    }
    return false
  }, [passages])

  const translate = (passageId) => {
    if (!passages?.[0]?.translation) {
      translateClaim({
        translation: {
          lang: AuthService.getLang(),
          sourceTitle: title ?? '',
          sourceHeadline: headline ?? '',
          sourceText: passages?.[0]?.text,
        },
      }).then((data) => {
        dispatch(
          updateDocument({ documentId: id, passageId, translation: data?.data?.targetText, translatedTitle: data?.data?.targetTitle }),
        )
        setShowTranslation(!showTranslation)
      })
    } else {
      setShowTranslation(!showTranslation)
    }
  }

  return (
    <div className={`source card p-3 pb-1 mb-2 mb-3 ${className}`} id={tagId}>
      {verdictLabel && <div className={`polarity ${verdictLabel}`} />}
      {publisherName && (
        <h5>
          <a href={url} target="_blank" rel="noreferrer">
            {publisherName}
          </a>
        </h5>
      )}
      <div className="d-flex justify-content-between">
        {publisherKind && (
          <span className="org text-capitalize fw-bold">
            {publisherKind.split('_').join(' ')?.toLowerCase()} - {publisherCategory.split('_').join(' ')?.toLowerCase()}
          </span>
        )}
        {datePublished && (
          <span className="time">{datePublished && isValid(parseISO(datePublished)) && format(parseISO(datePublished), 'dd-MM-yyyy')}</span>
        )}
      </div>
      {passages.length > 0 && (
        <div>
          <hr />
          <p>
            {passages.length > 0 ? (
              <ReactHighlightWords
                highlightClassName="highlight"
                searchWords={highlights ?? []}
                autoEscape
                highlightTag={({ children }) => <strong>{children}</strong>}
                textToHighlight={truncateText(showTranslation && translatedTitle ? translatedTitle : title)}
              />
            ) : (
              truncateText(showTranslation && translatedTitle ? translatedTitle : title)
            )}
          </p>
          {passages.slice(0, 1).map((passage, idx) => (
            <p key={`${url}-${idx}`}>
              <ReactHighlightWords
                highlightClassName="highlight"
                searchWords={highlights ?? []}
                autoEscape
                highlightTag={({ children }) => <strong>{children}</strong>}
                textToHighlight={truncateText(showTranslation && passage?.translation ? passage?.translation : passage.text)}
              />
            </p>
          ))}
        </div>
      )}
      <div className="d-flex justify-content-between">
        <div style={{ fontWeight: 600, color: '#495057', gap: 5 }} className="d-flex align-items-center">
          {authorName && (
            <div className="rounded p-1" style={{ background: '#F0F0F0' }}>
              <i className="bx bx-edit-alt me-1" />
              <span>{truncateText(authorName ?? '', 20)}</span>
            </div>
          )}
          {publisherCountry && (
            <div className="rounded p-1" style={{ background: '#F0F0F0' }}>
              <span>{countryCodeEmoji(publisherCountry)} </span>
              {truncateText(ISO3166.whereAlpha2(publisherCountry)?.country ?? '', 20)}
            </div>
          )}
        </div>
        {['INITIAL', 'CHECK', 'REPORT', 'HIGHLIGHT'].includes(status) && !passageInUserLang && (
          <button
            className="p-1 translate-btn font-16 btn btn-link d-flex"
            style={{ gap: 10 }}
            onClick={() => translate(passages?.[0]?.orderId)}>
            {isLoading && <Spinner color="primary" size="sm"></Spinner>}
            {showTranslation && passages?.[0]?.translation ? 'Show original' : 'Translate'}
          </button>
        )}
      </div>
    </div>
  )
}

export default Article
