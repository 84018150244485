import { subDays, startOfDay, endOfDay } from 'date-fns'

export const getNestedValue = (obj, key) => {
  const keys = key.split('.') // Split the key by dot (".") to get an array of keys

  let value = obj
  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i]
    if (value && value.hasOwnProperty(currentKey)) {
      // If the current key exists in the current value
      value = value[currentKey]
    } else if (Array.isArray(value)) {
      let sortedValue = value
      // sort by score if exist
      if (value?.[0]?.score) {
        sortedValue = [...value].sort((a, b) => b.score - a.score)
      }
      value = sortedValue.map((e) => (e ? e[currentKey] : undefined))
    } else {
      // If the current key does not exist in the current value or value is not an array, return undefined
      return undefined
    }
  }

  return value
}

export const checkEmptyArrays = (obj) => Object.values(obj).every((arr) => Array.isArray(arr) && arr.length === 0)

export const calculateExponentialDistance = (arr) => {
  // Find the minimum and maximum values in the array
  const min = Math.min(...arr)
  const max = Math.max(...arr)

  // Calculate the distance between min and max in an exponential way
  const distance = max - min
  const base = Math.pow(10, Math.floor(Math.log10(distance)) - 1) // Base for exponential calculation

  // Initialize the result array with the minimum value
  const result = [min]

  // Calculate up to 4 additional points of distance in exponential steps
  for (let i = 1; i < 5; i++) {
    const step = Math.pow(base, i) // Exponential step
    const point = min + step // Calculate the point of distance
    if (point < max) {
      // Add the point to the result array if it's less than max
      result.push(point)
    } else {
      // Break the loop if we reach max
      break
    }
  }

  return result
}

export function calculateLogarithmicDistance(arr) {
  // Find the minimum and maximum values in the array
  const min = Math.min(...arr)
  const max = Math.max(...arr)

  // Calculate the distance between min and max in a logarithmic way
  const distance = max - min
  const base = Math.log10(distance + 1) // Base for logarithmic calculation

  // Initialize the result array with the minimum value (rounded to nearest integer)
  const result = [Math.round(min)]

  // Calculate additional points of distance in logarithmic steps until reaching half of the maximum
  let i = 1
  while (true) {
    const step = Math.round(Math.pow(10, i / base)) // Logarithmic step (rounded to nearest integer)
    const point = min + step // Calculate the point of distance
    if (point < max / 2) {
      // Add the point to the result array if it's less than half of the maximum
      result.push(Math.round(point))
      i++
    } else {
      // Break the loop if we reach half of the maximum
      break
    }
  }

  return result
}

export function getBrowserTimezone() {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return browserTimezone
}

export function getDateInterval(option, lastFetched) {
  const today = new Date(lastFetched)
  let startDate, endDate

  if (option === 'today') {
    startDate = startOfDay(today)
    endDate = endOfDay(today)
  }

  if (option === 'yesterday') {
    startDate = startOfDay(subDays(today, 1))
    endDate = endOfDay(subDays(today, 1))
  }

  if (option === 'lastWeek') {
    startDate = startOfDay(subDays(today, 6))
    endDate = endOfDay(today)
  }
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return null
  } else {
    return { start: startDate, end: endDate }
  }
}

export function getTimezone() {
  const now = new Date()
  const formattedTimezoneOffset = `+${(now.getTimezoneOffset() / -60).toFixed(0).padStart(2, '0')}:00`
  return formattedTimezoneOffset
}
