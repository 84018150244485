/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap'
import SimpleBar from 'simplebar-react'

//i18n
import { withTranslation } from 'react-i18next'

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
        <DropdownToggle className="btn header-item noti-icon position-relative" tag="button" id="page-header-notifications-dropdown">
          <i className="bx bx-bell" />
          <span className="badge bg-danger rounded-pill">3</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t('Notifications')} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small">
                  {' '}
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: '230px' }}>
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span
                    className="px-1 fw-bold font-size-11 d-block"
                    style={{
                      backgroundColor: 'rgb(244, 248, 254)',
                      border: '1px solid rgb(205, 220, 252)',
                      color: 'red',
                      borderRadius: '3px',
                      width: 'max-content',
                    }}>
                    <i className="fa fa-retweet" aria-hidden="true"></i>
                    30
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">{props.t("Macron Government's Policies on Automobiles")}</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("Criticism of the Macron government's policies favoring certain automotive technologies.")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" /> {props.t('3 min ago')}{' '}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span
                    className="px-1 fw-bold font-size-11 d-block"
                    style={{
                      backgroundColor: 'rgb(244, 248, 254)',
                      border: '1px solid rgb(205, 220, 252)',
                      color: 'green',
                      borderRadius: '3px',
                      width: 'max-content',
                    }}>
                    <i className="fa fa-retweet" aria-hidden="true"></i>
                    55
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">{props.t("Renault's Presence in Formula 1")}</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">{props.t("Renault's commitment to Formula 1 as a flagship offering in the automotive market.")}</p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" /> {props.t('10 min ago')}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="#" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span
                    className="px-1 fw-bold font-size-11 d-block"
                    style={{
                      backgroundColor: 'rgb(244, 248, 254)',
                      border: '1px solid rgb(205, 220, 252)',
                      color: 'green',
                      borderRadius: '3px',
                      width: 'max-content',
                    }}>
                    <i className="fa fa-retweet" aria-hidden="true"></i>
                    19
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">{props.t('Renault Twingo 30th anniversary gathering')}</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t('Passionate Renault Twingo owners gathered to celebrate the 30th anniversary of the car.')}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" /> {props.t('15 min ago')}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t('View More..')}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
