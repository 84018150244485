import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SearchSate {
  keywords: string[]
}

const initialState: SearchSate = {
  keywords: [],
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setKeywords(state, action: PayloadAction<string[]>) {
      return { ...state, keywords: action.payload }
    },
  },
})

export const { setKeywords } = searchSlice.actions
