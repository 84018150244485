import React, { useRef, useEffect } from 'react'
import lottie from 'lottie-web'

const LottieAnimation = ({
  animationData,
  autoplay,
  style,
}: {
  animationData: { [key: string]: any }
  autoplay: boolean
  style: React.CSSProperties
}) => {
  const animationContainer = useRef(null)

  useEffect(() => {
    lottie.destroy()
    lottie.loadAnimation({
      container: animationContainer.current!,
      renderer: 'svg',
      loop: true,
      autoplay,
      animationData,
    })
  }, [autoplay, animationData])

  return <div ref={animationContainer} style={style} />
}

export default LottieAnimation
