import { createSlice } from '@reduxjs/toolkit'

import api from '../ApiClient'
import { ValidationError } from '../utils/errors'

interface UserState {
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  roles?: string[]
  picture?: string
  accessToken?: string
  refreshToken?: string
  language: string
  clientSlug?: string
}

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    language: 'en',
    email: 'test@email.com',
    firstName: 'test',
    lastName: 'test',
    roles: [],
  } as UserState,
  reducers: {
    setUser(state, action) {
      return action.payload
    },
    updateUser(state, action) {
      return action.payload
    },
  },
})

export const { setUser, updateUser } = currentUserSlice.actions

export const updateCurrentUser = (user: UserState) => {
  return async (dispatch: any) => {
    const response = await api.put(`/users/me`, user)
    if (response.ok) {
      dispatch(updateUser(response.body))
    } else if (response.status === 400) {
      throw new ValidationError(response.body.message, response.body.errors.json)
    }
  }
}

export default currentUserSlice.reducer
