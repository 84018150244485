import React, { useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import { useSharedSearchMutation } from './hooks/useSharedSearchMutation'
import LoadingComponent from './LoadingComponent'
import { HighLight } from './HighLight'
import { truncateText } from './../../utils/formatting'
import { useSharedHighlightMutation } from './hooks/useSharedHighlightMutation'
import FlipMove from 'react-flip-move'
import countryCodeEmoji from 'country-code-emoji'
import ISO3166 from 'iso-3166-1'

const ArticlesCards = () => {
  const [, { isLoading, data: searchResponse, error }] = useSharedSearchMutation()
  const [, { data: highlights }] = useSharedHighlightMutation()
  const documents = useMemo(
    () =>
      searchResponse?.articles_content &&
      Object.values(searchResponse.articles_content)
        .map((article) => ({
          ...article,
          datePublished: +new Date(article.publication_date),
        }))
        .sort((a, b) => b.datePublished - a.datePublished),
    [searchResponse?.articles_content],
  )

  return (
    <>
      {(!documents || documents?.length === 0) && (
        <LoadingComponent hasData={!!searchResponse} isEmpty={documents?.length === 0} error={error} isLoading={isLoading} />
      )}
      <FlipMove
        className="articles-cards"
        staggerDelayBy={20}
        maintainContainerHeight
        disableAllAnimations={documents?.length === 0 || !searchResponse}>
        {documents?.map((document) => (
          <div className="article-card" key={document.identifier}>
            <p className="date">
              {format(parseISO(document?.publication_date!), 'dd.MM.yyyy')} - {document.source_original_name}
            </p>
            <h3 className="title">
              {/* <a href={document.url} rel="noreferrer" target="_blank"> */}
              {document.title}
              {/* </a> */}
            </h3>
            <div className="passage mb-3">
              <HighLight text={truncateText(document.selected_passage)} highlights={highlights?.matchingKeywords || []} />
            </div>
            {/* <div className="score">
              {document.id && searchResponse?.scores && new Map(Object.entries(searchResponse?.scores)).get(document.id)?.toFixed(2)}
            </div> */}
            <div style={{ fontWeight: 600, color: '#495057', gap: 5 }} className="d-flex align-items-center mt-auto">
              {document?.authors && document.authors?.length > 0 && (
                <div className="rounded p-1" style={{ background: '#F0F0F0' }}>
                  <i className="bx bx-edit-alt me-1" />
                  <span>{truncateText(document?.authors?.[0] ?? '', 20)}</span>
                </div>
              )}
              {document?.source_country && (
                <div className="rounded p-1" style={{ background: '#F0F0F0' }}>
                  <span>
                    {countryCodeEmoji(ISO3166.whereAlpha3(document?.source_country.toLowerCase())?.alpha2?.toLowerCase() ?? '') ?? ''}{' '}
                  </span>
                  {truncateText(ISO3166.whereAlpha3(document?.source_country.toLowerCase())?.country ?? '', 20)}
                </div>
              )}
            </div>
          </div>
        ))}
      </FlipMove>
    </>
  )
}

export default ArticlesCards
