import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

import SearchBar from '../../components/search/SearchBar'

const SearchPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={12} lg={12}>
              <SearchBar />
              <Row className="mt-4" style={{ minHeight: 600 }}>
                <div style={{ width: 256 }}>
                  <div className="pages px-5">
                    <NavLink to="articles">Articles</NavLink>
                    <NavLink to="events">Events</NavLink>
                  </div>
                  <div className="sub-pages mt-3 d-flex flex-column px-5">
                    <NavLink to="articles/list">
                      <i className="bx bx-file"></i> List of Articles
                    </NavLink>
                    <NavLink to="articles/polarity">
                      <i className="bx bx-pie-chart-alt"></i> Polarity
                    </NavLink>
                    <NavLink to="articles/volume">
                      <i className="bx bx-detail"></i> News Volume
                    </NavLink>
                    <NavLink to="articles/entities">
                      <i className="bx bx-align-left"></i> Entities
                    </NavLink>
                    <NavLink to="articles/topics">
                      <i className="bx bxs-component"></i> Topics
                    </NavLink>
                    {/* <NavLink to="articles/location">
                      <i className="bx bxs-map"></i> Location
                    </NavLink> */}
                  </div>
                </div>
                <Col>
                  <Outlet />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SearchPage
