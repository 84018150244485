import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Container, Row, Col, Card, CardBody, CardTitle, Spinner } from 'reactstrap'

import PostsTimeline from '../../components/sna/PostsTimeline'

import Post from '../../components/sna/Post'
import SourcesModal from '../../components/sna/SourcesModal'
import SelectSearchBar from '../../components/SelectSearchBar'
import UserCard from '../../components/sna/UserCard'
import PolarityFilters from '../../components/sna/PolarityFilters'
import { useAlertsFindAllQuery, useAuthorsFindAllMutation } from '../../store/sna/snaApi'
import { useDispatch, useSelector } from 'react-redux'
import { selectAlert } from 'store/sna/postSlice'
import UsersNetwork from 'components/sna/UsersNetwork'
import { FilterManager } from 'components/sna/FilterManager'
import useGetLastValue from 'hooks/useGetLastValue'
import useSharedPostsFindAllQuery from '../../hooks/useSharedPostsFindAllQuery'
import PaginationButtons from '../../components/sna/PaginationButtons'
import FilterButton from '../../components/sna/FilterButton'
const SocialNetworkPage = () => {
  const dispatch = useDispatch()

  const [polarityFilter, setPolarityFilter] = useState(null)
  const [navClass] = useState('')

  const currentAlertId = useSelector((state) => state.post.currentAlertId)

  const filters = useSelector((state) => state.post.filters)

  const selectedPostId = useSelector((state) => state.post.selectedPost)
  const selectedPostIds = useSelector((state) => state.post.selectedPosts)

  const expandedPostId = useSelector((state) => state.post.expandedPostId)

  const getTwitterUser = (id) => users?.find((user) => user?.id === id)

  const { currentData: alertsData, isFetching } = useAlertsFindAllQuery()

  const { posts, interval, isFetching: isPostsLoading } = useSharedPostsFindAllQuery()

  const [getUsers, { data: currentUsers }] = useAuthorsFindAllMutation()
  // To avoid empty users when re-fetch
  const users = useGetLastValue(currentUsers)

  const alerts = useMemo(() => alertsData?.slice().sort((a, b) => a?.text.localeCompare(b?.text)), [alertsData])

  const selectedPosts = useMemo(() => {
    if (selectedPostId) return [posts?.find((p) => p.id === selectedPostId)]
    return posts?.filter((p) => selectedPostIds?.includes(p?.id))
  }, [selectedPostId, selectedPostIds, posts])

  const onSearch = useCallback(
    (id) => {
      dispatch(selectAlert(id))
    },
    [dispatch],
  )

  // set initial default alert
  useEffect(() => {
    if (alerts?.length && !currentAlertId) dispatch(selectAlert(alerts?.[0]?.id))
  }, [alerts, currentAlertId, dispatch])

  // get all uniques users, based on selected alert posts
  useEffect(() => {
    if (posts?.length) getUsers({ queryAuthor: { ids: [...new Set(posts?.map((post) => post.authorId))] } })
  }, [posts, getUsers])

  // scroll to the selected posts
  useEffect(() => {
    if (selectedPosts?.length > 0) {
      const element = document.getElementById(selectedPosts[0]?.id)
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [selectedPosts, filters])

  const originPosts = useMemo(() => posts.filter((post) => !post.sharedFrom), [posts])

  const filteredPosts = useMemo(() => {
    let filtered = originPosts

    if (filters.length) filtered = FilterManager.filter(filtered, filters)
    if (!filters?.length && filtered.length > 100) {
      filtered = filtered.filter((post) => post.nbShared > 0)
    }

    for (let key in polarityFilter) {
      if (!polarityFilter[key]) {
        filtered = filtered?.filter((post) => {
          const polarity = post?.behavior?.sentimentPolarity?.sentiment
          return polarity?.toLowerCase() !== key
        })
      }
    }

    return filtered || []
  }, [originPosts, polarityFilter, filters])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            {!isFetching && (
              <SelectSearchBar
                navClass={navClass}
                search={alerts?.find((alert) => alert.id === currentAlertId) ?? alerts?.[0]}
                onSearch={onSearch}
                options={alerts?.map((alert) => ({
                  value: alert?.id,
                  label: alert?.text,
                  lang: alert?.lang,
                  date: alert?.startTime,
                  dateEnd: alert?.endTime,
                }))}
              />
            )}
            <div className="d-flex flex-columns" style={{ gap: 5 }}>
              <PaginationButtons interval={interval} firstPostDate={posts?.at(0)?.createdAt} lastPostDate={posts?.at(-1)?.createdAt} />
              <FilterButton />
            </div>
          </div>

          <Row className="justify-content-center">
            <Col xl={4} lg={4}>
              <Card className="posts-container">
                <CardTitle className="p-3 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Posts</span>
                    <span className="count-badge ms-1 text-white p-1 px-2 font-size-10 fw-bold rounded shadow">
                      {!isPostsLoading || filteredPosts ? filteredPosts?.length : <Spinner animation="border" variant="light" size="sm" />}
                    </span>
                  </div>
                  <div>
                    <PolarityFilters posts={filteredPosts || []} onSelected={setPolarityFilter} />
                  </div>
                </CardTitle>
                <CardBody className="pt-1">
                  <div className="posts-container-body">
                    {filteredPosts?.map((post, id) => {
                      const user = getTwitterUser(post?.authorId)
                      return (
                        <Post
                          post={post}
                          user={{ name: user?.name, username: user?.username }}
                          key={`post-${id}`}
                          selected={selectedPosts?.map((p) => p?.id)?.includes(post?.id)}
                          expanded={expandedPostId && expandedPostId === post?.id}
                        />
                      )
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={8} lg={8}>
              <Row>
                <Col>
                  <PostsTimeline
                    posts={filteredPosts || []}
                    dateFormat={'%Y-%m-%dT%H:%M:%SZ'}
                    brushAction={originPosts?.length > 100 ? 'end' : 'brush'}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={9} lg={9}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">Network of Users</CardTitle>
                      <UsersNetwork
                        users={users || []}
                        posts={[
                          ...filteredPosts,
                          ...(filteredPosts?.flatMap((post) => posts?.filter((p) => p?.sharedFrom === post?.id)) || []),
                        ]}
                        selectedPosts={selectedPosts}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={3}>
                  <Card className="users-container">
                    <CardTitle className="p-3 d-flex justify-content-between header">
                      <span>Sharing</span>
                      <div>
                        <span className="count-badge text-white p-1 px-2 font-size-10 fw-bold rounded shadow">
                          {selectedPosts?.map((post) => post?.nbShared)?.reduce((accumulator, current) => accumulator + current, 0)}
                        </span>
                      </div>
                    </CardTitle>
                    <CardBody className="pt-1">
                      <div className="users-container-body">
                        {selectedPosts
                          ?.flatMap((post) => posts?.filter((p) => p?.sharedFrom === post?.id))
                          ?.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                          ?.map((item, id) => {
                            const user = getTwitterUser(item?.authorId)
                            return (
                              <UserCard
                                key={id}
                                user={{ name: user?.name, username: user?.username }}
                                publishedTime={item?.createdAt}
                                postId={item?.id?.split('twitter-')[1]}
                              />
                            )
                          })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <SourcesModal />
    </React.Fragment>
  )
}

export default SocialNetworkPage
