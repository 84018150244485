import { splitSnaApi as api } from './splitSnaApi'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    usersFindAll: build.query<UsersFindAllApiResponse, UsersFindAllApiArg>({
      query: () => ({ url: `/api/users` }),
    }),
    usersNew: build.mutation<UsersNewApiResponse, UsersNewApiArg>({
      query: (queryArg) => ({ url: `/api/users`, method: 'POST', body: queryArg.user }),
    }),
    postsFindAll: build.query<PostsFindAllApiResponse, PostsFindAllApiArg>({
      query: (queryArg) => ({
        url: `/api/posts`,
        params: { alert_id: queryArg.alertId, search_after: queryArg.searchAfter, order: queryArg.order, platform: queryArg.platform },
      }),
    }),
    tokensNew: build.mutation<TokensNewApiResponse, TokensNewApiArg>({
      query: () => ({ url: `/api/tokens`, method: 'POST' }),
    }),
    tokensRefresh: build.mutation<TokensRefreshApiResponse, TokensRefreshApiArg>({
      query: (queryArg) => ({ url: `/api/tokens`, method: 'PUT', body: queryArg.token }),
    }),
    tokensRevoke: build.mutation<TokensRevokeApiResponse, TokensRevokeApiArg>({
      query: () => ({ url: `/api/tokens`, method: 'DELETE' }),
    }),
    alertsFindAll: build.query<AlertsFindAllApiResponse, AlertsFindAllApiArg>({
      query: () => ({ url: `/api/alerts` }),
    }),
    alertsNew: build.mutation<AlertsNewApiResponse, AlertsNewApiArg>({
      query: (queryArg) => ({ url: `/api/alerts`, method: 'POST', body: queryArg.alert }),
    }),
    dashboardGetMetrics: build.query<DashboardGetMetricsApiResponse, DashboardGetMetricsApiArg>({
      query: (queryArg) => ({
        url: `/api/metrics`,
        params: {
          end: queryArg.end,
          start: queryArg.start,
          platform: queryArg.platform,
          alert_id: queryArg.alertId,
          timezone: queryArg.timezone,
        },
      }),
    }),
    authorsFindAll: build.mutation<AuthorsFindAllApiResponse, AuthorsFindAllApiArg>({
      query: (queryArg) => ({ url: `/api/authors`, method: 'POST', body: queryArg.queryAuthor }),
    }),
    reviewsFind: build.query<ReviewsFindApiResponse, ReviewsFindApiArg>({
      query: (queryArg) => ({
        url: `/api/reviews`,
        params: {
          end: queryArg.end,
          interval: queryArg.interval,
          start: queryArg.start,
          platform: queryArg.platform,
          alert_id: queryArg.alertId,
        },
      }),
    }),
    usersMe: build.query<UsersMeApiResponse, UsersMeApiArg>({
      query: () => ({ url: `/api/users/me` }),
    }),
    usersSelfRegister: build.mutation<UsersSelfRegisterApiResponse, UsersSelfRegisterApiArg>({
      query: (queryArg) => ({ url: `/api/users/me`, method: 'POST', body: queryArg.user }),
    }),
    usersPut: build.mutation<UsersPutApiResponse, UsersPutApiArg>({
      query: (queryArg) => ({ url: `/api/users/me`, method: 'PUT', body: queryArg.updateUser }),
    }),
    claimsCheck: build.mutation<ClaimsCheckApiResponse, ClaimsCheckApiArg>({
      query: (queryArg) => ({ url: `/api/claim/check`, method: 'POST', body: queryArg.claim }),
    }),
    tokensReset: build.mutation<TokensResetApiResponse, TokensResetApiArg>({
      query: (queryArg) => ({ url: `/api/tokens/reset`, method: 'POST', body: queryArg.passwordResetRequest }),
    }),
    tokensPasswordReset: build.mutation<TokensPasswordResetApiResponse, TokensPasswordResetApiArg>({
      query: (queryArg) => ({ url: `/api/tokens/reset`, method: 'PUT', body: queryArg.passwordReset }),
    }),
    claimsReport: build.mutation<ClaimsReportApiResponse, ClaimsReportApiArg>({
      query: (queryArg) => ({ url: `/api/claim/report`, method: 'POST', body: queryArg.claim }),
    }),
    authorsResetAuthors: build.mutation<AuthorsResetAuthorsApiResponse, AuthorsResetAuthorsApiArg>({
      query: (queryArg) => ({ url: `/api/authors/reset`, method: 'POST', body: queryArg.queryAuthor }),
    }),
    claimsHighlight: build.mutation<ClaimsHighlightApiResponse, ClaimsHighlightApiArg>({
      query: (queryArg) => ({ url: `/api/claim/highlight`, method: 'POST', body: queryArg.claim }),
    }),
    claimsTranslate: build.mutation<ClaimsTranslateApiResponse, ClaimsTranslateApiArg>({
      query: (queryArg) => ({ url: `/api/claim/translate`, method: 'POST', body: queryArg.translation }),
    }),
    assetsGetStopWords: build.query<AssetsGetStopWordsApiResponse, AssetsGetStopWordsApiArg>({
      query: (queryArg) => ({ url: `/api/assets/stop-words`, params: { lang: queryArg.lang } }),
    }),
    usersUpdateUser: build.mutation<UsersUpdateUserApiResponse, UsersUpdateUserApiArg>({
      query: (queryArg) => ({ url: `/api/users/${queryArg.userId}`, method: 'PUT', body: queryArg.updateUser }),
    }),
    usersDeleteUser: build.mutation<UsersDeleteUserApiResponse, UsersDeleteUserApiArg>({
      query: (queryArg) => ({ url: `/api/users/${queryArg.userId}`, method: 'DELETE' }),
    }),
    alertsUpdateAlert: build.mutation<AlertsUpdateAlertApiResponse, AlertsUpdateAlertApiArg>({
      query: (queryArg) => ({ url: `/api/alerts/${queryArg.alertId}`, method: 'PUT', body: queryArg.alertUpdate }),
    }),
    alertsDeleteAlert: build.mutation<AlertsDeleteAlertApiResponse, AlertsDeleteAlertApiArg>({
      query: (queryArg) => ({ url: `/api/alerts/${queryArg.alertId}`, method: 'DELETE' }),
    }),
    claimsSearchAndCheck: build.query<ClaimsSearchAndCheckApiResponse, ClaimsSearchAndCheckApiArg>({
      query: (queryArg) => ({ url: `/api/claim/check/${queryArg.claim}` }),
    }),
    reviewsDeleteReview: build.mutation<ReviewsDeleteReviewApiResponse, ReviewsDeleteReviewApiArg>({
      query: (queryArg) => ({ url: `/api/reviews/${queryArg.reviewId}`, method: 'DELETE' }),
    }),
    cascadesFindByPostId: build.query<CascadesFindByPostIdApiResponse, CascadesFindByPostIdApiArg>({
      query: (queryArg) => ({
        url: `/api/cascades/${queryArg.rootPostId}`,
        params: { alert_id: queryArg.alertId, platform: queryArg.platform },
      }),
    }),
    postsGetPostDocuments: build.query<PostsGetPostDocumentsApiResponse, PostsGetPostDocumentsApiArg>({
      query: (queryArg) => ({ url: `/api/posts/documents/${queryArg.postId}` }),
    }),
    alertsFactcheck: build.query<AlertsFactcheckApiResponse, AlertsFactcheckApiArg>({
      query: (queryArg) => ({ url: `/api/alerts/${queryArg.alertId}/verify_missing` }),
    }),
    alertsAnalyse: build.query<AlertsAnalyseApiResponse, AlertsAnalyseApiArg>({
      query: (queryArg) => ({ url: `/api/alerts/${queryArg.alertId}/analyse_missing` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as snaApi }
export type UsersFindAllApiResponse = /** status 200 OK */ User[]
export type UsersFindAllApiArg = void
export type UsersNewApiResponse = /** status 201 Created */ User
export type UsersNewApiArg = {
  user: User
}
export type PostsFindAllApiResponse = /** status 200 OK */ PostsResponse
export type PostsFindAllApiArg = {
  alertId?: string
  searchAfter?: string
  order?: string
  platform?: string
}
export type TokensNewApiResponse = /** status 200 OK */ Token
export type TokensNewApiArg = void
export type TokensRefreshApiResponse = /** status 200 Newly issued access and refresh tokens */ Token
export type TokensRefreshApiArg = {
  token: Token
}
export type TokensRevokeApiResponse = /** status 204 Token revoked */ Empty
export type TokensRevokeApiArg = void
export type AlertsFindAllApiResponse = /** status 200 OK */ Alert[]
export type AlertsFindAllApiArg = void
export type AlertsNewApiResponse = /** status 201 Created */ Alert
export type AlertsNewApiArg = {
  alert: Alert
}
export type DashboardGetMetricsApiResponse = unknown
export type DashboardGetMetricsApiArg = {
  end?: string
  start?: string
  platform?: string
  alertId?: string
  timezone?: string
}
export type AuthorsFindAllApiResponse = /** status 200 OK */ Author[]
export type AuthorsFindAllApiArg = {
  queryAuthor: QueryAuthor
}
export type ReviewsFindApiResponse = /** status 200 OK */ Review
export type ReviewsFindApiArg = {
  end?: string
  interval?: string
  start?: string
  platform?: string
  alertId?: string
}
export type UsersMeApiResponse = /** status 200 OK */ User
export type UsersMeApiArg = void
export type UsersSelfRegisterApiResponse = /** status 201 Created */ User
export type UsersSelfRegisterApiArg = {
  user: User
}
export type UsersPutApiResponse = /** status 200 OK */ User
export type UsersPutApiArg = {
  updateUser: UpdateUser
}
export type ClaimsCheckApiResponse = /** status 200 OK */ Claim
export type ClaimsCheckApiArg = {
  claim: Claim
}
export type TokensResetApiResponse = /** status 204 Password reset email sent */ Empty
export type TokensResetApiArg = {
  passwordResetRequest: PasswordResetRequest
}
export type TokensPasswordResetApiResponse = /** status 204 Password reset successful */ Empty
export type TokensPasswordResetApiArg = {
  passwordReset: PasswordReset
}
export type ClaimsReportApiResponse = /** status 200 OK */ Claim
export type ClaimsReportApiArg = {
  claim: Claim
}
export type AuthorsResetAuthorsApiResponse = /** status 204 Authors sucessfully reset. */ Empty
export type AuthorsResetAuthorsApiArg = {
  queryAuthor: QueryAuthor
}
export type ClaimsHighlightApiResponse = /** status 200 OK */ Claim
export type ClaimsHighlightApiArg = {
  claim: Claim
}
export type ClaimsTranslateApiResponse = /** status 200 OK */ Translation
export type ClaimsTranslateApiArg = {
  translation: Translation
}
export type AssetsGetStopWordsApiResponse = /** status 200 OK */ StopWords
export type AssetsGetStopWordsApiArg = {
  lang?: string
}
export type UsersUpdateUserApiResponse = /** status 200 OK */ User
export type UsersUpdateUserApiArg = {
  userId: string
  updateUser: UpdateUser
}
export type UsersDeleteUserApiResponse = /** status 204 User successfully deleted. */ Empty
export type UsersDeleteUserApiArg = {
  userId: string
}
export type AlertsUpdateAlertApiResponse = /** status 200 OK */ Alert
export type AlertsUpdateAlertApiArg = {
  alertId: string
  alertUpdate: AlertUpdate
}
export type AlertsDeleteAlertApiResponse = /** status 204 Alert successfully deleted. */ Empty
export type AlertsDeleteAlertApiArg = {
  alertId: string
}
export type ClaimsSearchAndCheckApiResponse = /** status 200 OK */ Claim
export type ClaimsSearchAndCheckApiArg = {
  claim: string
}
export type ReviewsDeleteReviewApiResponse = /** status 204 Review successfully deleted. */ Empty
export type ReviewsDeleteReviewApiArg = {
  reviewId: string
}
export type CascadesFindByPostIdApiResponse = /** status 200 OK */ Cascade
export type CascadesFindByPostIdApiArg = {
  rootPostId: string
  alertId?: string
  platform?: string
}
export type PostsGetPostDocumentsApiResponse = /** status 200 OK */ PostDocumentResponse
export type PostsGetPostDocumentsApiArg = {
  postId: string
}
export type AlertsFactcheckApiResponse = /** status 204 Alert successfully factchecked. */ Empty
export type AlertsFactcheckApiArg = {
  alertId: string
}
export type AlertsAnalyseApiResponse = /** status 204 Alert successfully analysed. */ Empty
export type AlertsAnalyseApiArg = {
  alertId: string
}
export type Permission = {
  name?: 'READ' | 'UPLOAD' | 'CREATE_USER' | 'MONITOR'
  value?: boolean
}
export type User = {
  id?: string
  firstName: string
  lastName: string
  organization?: string
  email: string
  password: string
  avatarUrl?: string
  subscription?: 'free' | 'ondemand' | 'pro' | 'org'
  role: 'User' | 'Contributor' | 'Administrator'
  permissions?: Permission[]
  firstSeen?: string
  lastSeen?: string
}
export type SentimentPolarity = {
  sentiment?: string
  score?: number
}
export type Category = {
  label?: string
  score?: number
}
export type Emotion = {
  emotion?: string
  score?: number
}
export type Behavior = {
  sentimentPolarity?: SentimentPolarity
  toxicity?: Category[]
  emotions?: Emotion[]
}
export type Topic = {
  topic?: string
  score?: number
}
export type Entity = {
  entity?: string
  score?: number
  text?: string
  surfaceForm?: string
  start?: number
  end?: number
}
export type Post = {
  id?: string
  createdAt?: string
  text?: string
  lang?: string
  platform?: string
  alert_id?: string
  authorId?: string
  sharedFrom?: string
  nbShared?: number
  behavior?: Behavior
  topics?: Topic[]
  garm?: string[]
  entities?: Entity[]
  claim?: string
  globalVerdictLabel?: Category
  analysed?: boolean
  checked?: boolean
}
export type Interval = {
  end?: string
  start?: string
}
export type PostsResponse = {
  posts?: Post[]
  interval?: Interval
}
export type Token = {
  accessToken: string
  refreshToken?: string
}
export type Empty = {}
export type Alert = {
  id?: string
  version?: number
  lang: string[]
  text: string
  startTime?: string
  endTime?: string
  lastFetched?: string
  refreshRateMinutes?: number
  dataRetentionDays?: number
  enableFactChecking?: boolean
  enforceFetch?: boolean
  active?: any
}
export type Author = {
  id?: string
  username?: string
  name?: string
  location?: string
  followersCount?: number
  createdAt?: string
  platform?: string
  alert_id?: string
  posX?: number
  posY?: number
  communityIdx?: number
  centrality?: number
  sharedCentrality?: number
  sharingCentrality?: number
}
export type QueryAuthor = {
  alert_id?: string
  platform?: string
  ids?: string[]
}
export type Theme = {
  name?: string
  description?: string
  polarity?: string
  nbPosts?: number
  nbShared?: number
}
export type Review = {
  id?: string
  startTime?: string
  endTime?: string
  interval?: string
  alert_id?: string
  platform?: string
  minNbShared?: number
  lastUpdated?: string
  topics?: Theme[]
}
export type UpdateUser = {
  id?: string
  firstName: string
  lastName: string
  organization?: string
  email: string
  password?: string
  avatarUrl?: string
  subscription?: 'free' | 'ondemand' | 'pro' | 'org'
  role: 'User' | 'Contributor' | 'Administrator'
  permissions?: Permission[]
  firstSeen?: string
  lastSeen?: string
  oldPassword?: string
}
export type DocumentPassage = {
  text?: string
  dateProcessed?: string
  orderId?: number
  highlights?: string[]
}
export type PublisherType = {
  name?: string
  category?: string
}
export type Publisher = {
  name?: string
  nameEn?: string
  country?: string
  type?: PublisherType
}
export type Document = {
  id?: string
  authors?: string[]
  datePublished?: string
  dateProcessed?: string
  headline?: string
  name?: string
  passages?: DocumentPassage[]
  text?: string
  publisher?: Publisher
  url?: string
  verdictLabel?: string
}
export type Claim = {
  claim?: string
  globalVerdictLabel?: string
  documents?: Document[]
  report?: string
  reportHighlights?: string[]
  lang?: string
}
export type PasswordResetRequest = {
  email: string
}
export type PasswordReset = {
  token: string
  newPassword: string
}
export type Translation = {
  lang?: string
  sourceTitle?: string
  targetTitle?: string
  sourceHeadline?: string
  targetHeadline?: string
  sourceText: string
  targetText?: string
}
export type StopWords = {
  stopWords?: string[]
}
export type AlertUpdate = {
  id?: string
  version?: number
  lang?: string[]
  text?: string
  startTime?: string
  endTime?: string
  lastFetched?: string
  refreshRateMinutes?: number
  dataRetentionDays?: number
  enableFactChecking?: boolean
  enforceFetch?: boolean
  active?: any
}
export type CascadeLink = {
  parentPostId?: string
  postId?: string
  parentAuthorId?: string
  authorId?: string
  createdAt?: string
  cascadeDepth?: number
  cascadeBreadth?: number
  cascadeSize?: number
  cascadeVirality?: number
}
export type Cascade = {
  id?: string
  rootPostId?: string
  platform?: string
  alert_id?: string
  links?: CascadeLink[]
}
export type PostDocumentResponse = {
  documents?: Document[]
}
export const {
  useUsersFindAllQuery,
  useLazyUsersFindAllQuery,
  useUsersNewMutation,
  usePostsFindAllQuery,
  useLazyPostsFindAllQuery,
  useTokensNewMutation,
  useTokensRefreshMutation,
  useTokensRevokeMutation,
  useAlertsFindAllQuery,
  useLazyAlertsFindAllQuery,
  useAlertsNewMutation,
  useDashboardGetMetricsQuery,
  useLazyDashboardGetMetricsQuery,
  useAuthorsFindAllMutation,
  useReviewsFindQuery,
  useLazyReviewsFindQuery,
  useUsersMeQuery,
  useLazyUsersMeQuery,
  useUsersSelfRegisterMutation,
  useUsersPutMutation,
  useClaimsCheckMutation,
  useTokensResetMutation,
  useTokensPasswordResetMutation,
  useClaimsReportMutation,
  useAuthorsResetAuthorsMutation,
  useClaimsHighlightMutation,
  useClaimsTranslateMutation,
  useAssetsGetStopWordsQuery,
  useLazyAssetsGetStopWordsQuery,
  useUsersUpdateUserMutation,
  useUsersDeleteUserMutation,
  useAlertsUpdateAlertMutation,
  useAlertsDeleteAlertMutation,
  useClaimsSearchAndCheckQuery,
  useLazyClaimsSearchAndCheckQuery,
  useReviewsDeleteReviewMutation,
  useCascadesFindByPostIdQuery,
  useLazyCascadesFindByPostIdQuery,
  usePostsGetPostDocumentsQuery,
  useLazyPostsGetPostDocumentsQuery,
  useAlertsFactcheckQuery,
  useLazyAlertsFactcheckQuery,
  useAlertsAnalyseQuery,
  useLazyAlertsAnalyseQuery,
} = injectedRtkApi
