/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Card, CardBody, CardTitle, Progress } from 'reactstrap'
import { truncateText } from 'utils/formatting'

const colors = ['primary', 'success', 'warning']

const TopPublisher = ({ publishers, users, isTwitter = true }) => {
  // Step 1: Calculate the total count
  const totalCount = publishers.reduce((total, publisher) => total + publisher.count, 0)

  // Step 2: Assign percentages to each count
  publishers.forEach((publisher) => {
    publisher.percentage = (publisher.count / totalCount) * 100
  })

  const getTwitterUser = (id) => users?.find((user) => user?.id === id)
  return (
    <Card className="flex-fill">
      <CardBody className="pt-2">
        <CardTitle className="mb-3">Top Publishers</CardTitle>
        <div className="text-center">
          <div className="mb-4">
            <i className="bx bx-map-pin text-primary display-4" />
          </div>
          <h3>{publishers?.[0]?.count}</h3>
          <p>{isTwitter ? getTwitterUser(publishers?.[0]?.name)?.name : publishers?.[0]?.name}</p>
        </div>

        <div className="table-responsive mt-4">
          <table className="table align-middle table-nowrap">
            <tbody>
              {publishers?.map((p, i) => {
                const user = getTwitterUser(p?.name)
                return (
                  <tr key={`pub-${p?.id ?? i}`}>
                    <td style={{ width: '30%' }}>
                      {isTwitter && (
                        <a className="text-dark mb-0" href={`https://twitter.com/${user?.username}`} target="_blank" rel="noreferrer">
                          {truncateText(user?.name ?? '', 20)}
                        </a>
                      )}
                      {!isTwitter && (
                        <a className="text-dark mb-0" href="#" target="_blank" rel="noreferrer">
                          {truncateText(p?.name ?? '', 20)}
                        </a>
                      )}
                    </td>
                    <td style={{ width: '25%' }}>
                      <h5 className="mb-0">{p.count}</h5>
                    </td>
                    <td>
                      <Progress value={p.percentage} color={colors[i]} className="bg-transparent progress-sm" size="sm" />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}

export default TopPublisher
