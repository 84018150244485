export const customFilters = [
  {
    name: 'Type',
    options: ['News Media', 'Fact Checking Org.'],
  },
  {
    name: 'Location',
    options: ['Europe', 'North America'],
  },
  {
    name: 'Entity',
    options: [
      'PERSON',
      'NORP',
      'FACILITY',
      'ORGANIZATION',
      'GPE',
      'LOCATION',
      'PRODUCT',
      'EVENT',
      'WORK_OF_ART',
      'LAW',
      'LANGUAGE',
      'DATE',
      'TIME',
      'PERCENT',
      'MONEY',
      'QUANTITY',
      'ORDINAL',
      'CARDINAL',
    ],
  },
  {
    name: 'Topic',
    options: [
      'SPORT',
      'CRIME_LAW_JUSTICE',
      'SOCIAL_ISSUE',
      'ARTS_CULTURE_ENTERTAINMENT',
      'POLITICS',
      'HEALTH',
      'SCIENCE_TECHNOLOGY',
      'ECONOMY_BUSINESS_FINANCE',
      'EDUCATION',
      'LIFESTYLE_LEISURE',
      'DISASTER_ACCIDENT',
      'HUMAN_INTEREST',
      'UNREST_CONFLICTS_WAR',
      'RELIGION_BELIEF',
      'WEATHER',
      'LABOUR',
    ],
  },
  {
    name: 'Content',
    options: [
      'ACADEMIC_JOURNAL',
      'ASSOCIATION',
      'COMPANY',
      'DEVOLVED_ADMINISTRATION',
      'EMBASSY_OR_CONSULATE',
      'ENCYCLOPEDIA',
      'EUROPEAN_ORGANIZATION',
      'FACT_CHECKING_ORGANIZATION',
      'INDEPENDENT_AUTHORITY',
      'INTERNATIONAL_DELEGATION',
      'INTERNATIONAL_ORGANIZATION',
      'LEARNED_SOCIETY',
      'LOCAL_ADMINISTRATION',
      'MINISTERIAL_DEPARTMENT',
      'NATIONAL_INSTITUTION',
      // 'NATIONAL_JURISDICTION',
      'NEWS_AGENCY',
      'NEWS_MEDIA',
      // 'NON_GOVERNMENTAL_ORGANIZATION',
      // 'NON_PROFIT_ORGANIZATION',
      // 'OPEN_DATABASE',
      // 'POLITICAL_PARTY',
      // 'PRE_PRINT_SERVER',
      // 'PUBLIC_BODY',
      // 'PUBLIC_CORPORATION',
      // 'PUBLISHING_COMPANY',
      // 'STATISTICAL_OFFICE',
      // 'STOCK_EXCHANGE',
      // 'TRADE_UNION',
      // 'UNIVERSITY_PRESS',
    ],
  },
]

export const dateFilterOptions = [
  'Last 12 hours',
  'Last 24 Hours',
  'Today',
  'Last 7 Days',
  'Last 30 Days',
  'Last 12 Month',
  'This Week',
  'This Month',
  'This Quarter',
  'This Year',
  // 'Custom',
]
