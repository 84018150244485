import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../reducers/currentUserReducer'
import AuthService from './AuthService'
import { getCookie } from './cookieUtils'
import { RootState } from '../store'
import { useTranslation } from 'react-i18next'

export interface AuthProviderProps {
  location: Location
  path: string
  children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const user = useSelector((state: RootState) => state.currentUser)
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const checkUserHasValidToken = async () => {
    const response = await AuthService.refresh(() => {
      dispatch(updateUser(null))
      AuthService.eraseCookies()
    })
    if (response && response.accessToken) {
      dispatch(updateUser(response))
    }
  }

  useLayoutEffect(() => {
    if (process.env.REACT_APP_SKIP_AUTH === 'true') {
      return
    }
    // get user from cookies
    const token = getCookie('bi_access_token')
    if (token && !user.accessToken) {
      const userFromToken = AuthService.getUserFromToken(token)
      if (userFromToken) dispatch(updateUser(userFromToken))
    }
    // check the user has the good token else refresh
    void checkUserHasValidToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // refresh token, interval = tokenLifeTime / 2
  useEffect(() => {
    const interval = setInterval(() => {
      void AuthService.refresh()
    }, AuthService.getRefreshInterval())
    return () => clearInterval(interval)
  }, [])

  // get current user language
  useEffect(() => {
    void i18n.changeLanguage(user?.language)
  }, [i18n, user?.language])

  return <>{children}</>
}

export default AuthProvider
