import { useState, useEffect, useRef, useMemo } from 'react'
import * as d3 from 'd3'
import { FormGroup, Input, Card, CardBody, CardTitle } from 'reactstrap'

import Axis from './Axis'
import { useChartDimensions, useChartZoom } from '../../contexts/ChartProvider'
import TopKAnalysis from './TopKAnalysis'
import { default as mapper } from '../../utils/analysisMapper'
import { useDispatch, useSelector } from 'react-redux'
import { selectPosts } from 'store/sna/postSlice'

const chartSettings = {
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 60,
  height: 90,
}

const PostsTimeline = ({ posts, dateFormat, brushAction }) => {
  const dispatch = useDispatch()
  const [ref, dms] = useChartDimensions(chartSettings)
  const [svgRef, scale] = useChartZoom()
  const [displayCascSize, setDisplayCascSize] = useState(true)
  const dateParser = d3.utcParse(dateFormat)
  const brushRef = useRef(null)

  const xScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain(
          d3.extent(posts, (p) => {
            return dateParser(p.createdAt)
          }),
        )
        .range([0 + dms.boundedHeight / 2, dms.boundedWidth + scale - 50 - dms.boundedHeight / 2]),
    [dms.boundedHeight, dms.boundedWidth, posts, scale, dateParser],
  )

  const selectedPostId = useSelector((state) => state.post.selectedPost)
  const selectedPostIds = useSelector((state) => state.post.selectedPosts)

  useEffect(() => {
    const brush = d3.brushX().extent([
      [dms.marginLeft, dms.marginTop],
      [dms.marginLeft + dms.boundedWidth + scale - 50, dms.marginTop + dms.boundedHeight],
    ])
    brush(d3.select(brushRef.current))
    brush.on(brushAction, () => {
      const brushExtent = d3.event.selection.map(xScale.invert)
      const selectedPosts = posts?.filter((p) => {
        const postDate = dateParser(p.createdAt)
        return !p.sharedFrom && postDate >= brushExtent[0] && postDate <= brushExtent[1]
      })
      dispatch(selectPosts(selectedPosts?.map((p) => p.id)))
    })
  }, [
    dms.marginLeft,
    dms.marginTop,
    dms.boundedWidth,
    dms.boundedHeight,
    xScale,
    posts,
    scale,
    dateParser,
    dispatch,
    brushAction,
    selectedPostIds,
  ])

  const rScale = d3
    .scaleLinear()
    .domain([1, d3.max(posts?.map((p) => p?.nbShared))])
    .range([4, dms.boundedHeight / 2])

  return (
    <>
      <Card>
        <CardTitle className="p-3 d-flex justify-content-between header" style={{ minHeight: 59 }}>
          <div className="d-flex">
            <span>Timeline</span>
            <span className="count-badge ms-1 text-white p-1 px-2 font-size-10 fw-bold rounded shadow">
              {selectedPostIds?.length || posts?.length}
            </span>
          </div>
          <TopKAnalysis posts={selectedPostIds ? posts?.filter((p) => selectedPostIds?.includes(p?.id)) : []} />
        </CardTitle>
        <CardBody>
          <div className="Chart__wrapper" ref={ref}>
            <svg width={dms.width + scale - 50} height={dms.height} ref={svgRef}>
              <g transform={`translate(${[dms.marginLeft, dms.marginTop].join(',')})`}>
                <rect width={dms.boundedWidth} height={dms.boundedHeight} fill="white" />
                <line y1={dms.boundedHeight / 2} x2={dms.boundedWidth + scale - 50} y2={dms.boundedHeight / 2} stroke="#dddddd" />
                <g>
                  {xScale && <Axis domain={xScale?.domain()} range={xScale?.range()} heightOffset={dms.boundedHeight + dms.marginTop} />}
                </g>
                <g>
                  {xScale &&
                    posts?.map((p) => {
                      const cx = xScale?.(dateParser(p?.createdAt))
                      const polarity = p?.behavior?.sentimentPolarity?.sentiment
                      const color = mapper.getPolarityColor(polarity)
                      const isSelected = selectedPostId === p?.id
                      return (
                        <g key={p.id}>
                          {displayCascSize ? (
                            <circle cx={cx} cy={dms.boundedHeight / 2} r={rScale(p?.nbShared)} fill={color} opacity={0.6} />
                          ) : (
                            <circle cx={cx} cy={dms.boundedHeight / 2} r={5} fill="transparent" stroke={color} />
                          )}
                          <circle cx={cx} cy={dms.boundedHeight / 2} r={3} fill={color} />
                          {isSelected && (
                            <line y2={dms.boundedHeight} x1={cx} x2={cx} stroke="rgb(99, 92, 255)" strokeWidth={2} strokeDasharray={3} />
                          )}
                        </g>
                      )
                    })}
                </g>
              </g>
              <g ref={brushRef}></g>
            </svg>
          </div>
          <div className="d-flex justify-content-end">
            <FormGroup switch className="">
              <span className="fw-bold mb-0">Display cascade size</span>
              <Input type="switch" role="switch" checked={displayCascSize} onChange={() => setDisplayCascSize(!displayCascSize)} />
            </FormGroup>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default PostsTimeline
