import { calculateLogarithmicDistance, getNestedValue } from './../../utils/utils'

export type Filters<T> = {
  key: keyof T
  values: T[keyof T][]
}

export type FiltersOptions<T> = {
  key: keyof T
  values: T[keyof T][]
}

type InitialStateOptions = {
  defaultNbShared?: number
}

export class FilterManager {
  /** Filter the data and return only the wanted subset */
  static filter<T>(data: T[], filters: Filters<T>[]) {
    return data.filter((value) =>
      filters.every((filter) => {
        const fieldValue = getNestedValue(value, filter.key)
        if (Array.isArray(fieldValue)) {
          // return filter.values.some((value) => fieldValue.includes(value))
          return filter.values.some((value) => fieldValue[0] === value)
        } else if (typeof fieldValue === 'number') {
          return (filter.values?.[0] as number) <= fieldValue
        } else {
          return filter.values.includes(fieldValue)
        }
      }),
    )
  }

  static initialState<T>(filtersOptions: FiltersOptions<T>[], options: InitialStateOptions = {}) {
    return filtersOptions.reduce((acc, curr) => {
      const isNbShared = curr?.key === 'nbShared' && typeof curr.values[0] === 'number'
      acc[curr.key] = curr.values.map((value, index) => {
        if (isNbShared && (index === options?.defaultNbShared ?? 1)) {
          return value // Set only the first value item if it is a number
        } else if (isNbShared) {
          return undefined // Set undefined for other value items if they are numbers
        } else {
          return value
        }
      })
      return acc
    }, {} as { [key in keyof T]: (T[keyof T] | undefined)[] })
  }

  /** Generate the options of the filters, which one are available */
  static createFiltersOptions<T>(data: T[], filterKeys: (keyof T)[]) {
    const filters = filterKeys.map((key) => {
      // getting all possibles values for given filter key
      let values = data.flatMap((item) => {
        const value = getNestedValue(item, key)
        return Array.isArray(value) ? [value?.[0]] : [value]
      })

      // in case of numeric field, get only interval
      if (typeof values?.[0] === 'number') {
        values = [0, 1, 3, ...calculateLogarithmicDistance(values)].sort((a, b) => a - b)
      }

      // to get occurance
      const count = values.reduce((acc, curr) => {
        const existing = acc.find((v: { name: string; count: number }) => v.name === `${curr}`)
        if (existing) {
          existing.count += 1
        } else {
          acc.push({ name: `${curr}`, count: 1 })
        }
        return acc
      }, [] as { name: string; count: number }[])
      return {
        key,
        values: [...new Set(values)],
        count,
      }
    })
    return filters
  }
}
