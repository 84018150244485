import React from 'react'
import TooltipComponent from '../../TooltipComponent'
import ISO6391 from 'iso-639-1'
import { isoDateToLocale } from '../../../utils/dates'
import { formatDistance } from 'date-fns'

/**Informations of table */
export const getColumns = (onEdit, onDelete) => [
  {
    dataField: 'id',
    text: '#',
    formatter: (cellContent, alert) => <>{alert.id}</>,
  },
  {
    dataField: 'text',
    text: 'Text',
    sort: true,
    style: { whiteSpace: 'break-spaces' },
    formatter: (cellContent, alert) => (
      <>
        <h5 className="font-size-14 mb-1" data-testid={'name_' + alert.id}>
          <span className="text-dark">{alert.text}</span>
        </h5>
      </>
    ),
  },
  {
    dataField: 'lang',
    text: 'Languages',
    sort: true,
    headerStyle: () => {
      return { width: '310px' }
    },
    formatter: (cellCorolentent, alert) => (
      <div className="lang-container d-flex flex-wrap" style={{ overflow: 'hidden' }}>
        {alert?.lang?.map((l) => (
          <span className="badge badge-soft-primary font-size-11 m-1" key={`lang-${alert.id}-${l}`}>
            {ISO6391.getName(l)}
          </span>
        ))}
      </div>
    ),
  },
  {
    dataField: 'startTime',
    text: 'Start',
    sort: true,
    formatter: (cellContent, alert) => (
      <>
        <p className="mb-0 text-muted">{isoDateToLocale(alert?.startTime)}</p>
      </>
    ),
  },
  {
    dataField: 'endTime',
    text: 'End',
    sort: true,
    formatter: (cellContent, alert) => (
      <>
        <p className="mb-0 text-muted">{isoDateToLocale(alert?.endTime)}</p>
      </>
    ),
  },
  {
    dataField: 'lastFetched',
    text: 'Last fetch',
    sort: true,
    formatter: (cellContent, alert) => (
      <>
        <p className="mb-0 text-muted">{formatDistance(new Date(alert?.lastFetched), new Date(), { addSuffix: true })}</p>
      </>
    ),
  },
  {
    dataField: 'refreshRateMinutes',
    text: 'Refresh rate',
    sort: true,
    headerStyle: () => {
      return { width: '120px' }
    },
    formatter: (cellContent, alert) => (
      <>
        <p className="mb-0 text-muted">{alert?.refreshRateMinutes}</p>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    editable: false,
    text: 'action',
    formatter: (cellContent, alert) => (
      <div className="d-flex gap-3">
        <i id={'edittooltip_' + alert.id} className="bx bx-pencil text-muted font-size-18" onClick={() => onEdit(alert)} />
        <i id={'deletetooltip_' + alert.id} className="bx bx-trash text-muted font-size-18" onClick={() => onDelete(alert)} />
        <TooltipComponent text="Edit" id={'edittooltip_' + alert.id} position="left"></TooltipComponent>
        <TooltipComponent text="Delete" id={'deletetooltip_' + alert.id} position="right"></TooltipComponent>
      </div>
    ),
  },
]
