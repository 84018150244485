import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: '',
  msgType: 'info',
  duration: 5,
  visible: false,
}

const flashMessageSlice = createSlice({
  name: 'flash',
  initialState,
  reducers: {
    updateFlash(state, action) {
      return { ...state, ...action.payload }
    },
    setVisible(state, action) {
      return { ...state, visible: action.payload }
    },
  },
})

export const { updateFlash, setVisible } = flashMessageSlice.actions

let flashTimer

const wait = async (duration) => {
  return new Promise((resolve) => {
    flashTimer = setTimeout(resolve, duration * 1000)
  })
}

export const flash = (message, msgType, duration = 5) => {
  return async (dispatch) => {
    if (flashTimer) {
      clearTimeout(flashTimer)
      flashTimer = undefined
    }
    dispatch(updateFlash({ message, msgType, duration, visible: true }))
    if (duration) {
      await wait(duration)
      dispatch(updateFlash({ visible: false }))
    }
  }
}

export default flashMessageSlice.reducer
