import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { nerColors, verdictColors } from '../../pages/app/CheckTextData'
import { default as mapper } from '../../utils/analysisMapper'
import Donut from '../Donut'
import PostCharts from './PostCharts'
import { useDispatch, useSelector } from 'react-redux'
import { showPostDocuments } from 'store/sna/postSlice'
import { useCascadesFindByPostIdQuery } from 'store/sna/snaApi'
import { find as findUrl } from 'linkifyjs'
import { useEffect, useMemo } from 'react'
import { usePostAnalyzeUrlMutation } from 'store/nlpApi'
import { skipToken } from '@reduxjs/toolkit/query'

const Ner = ({ entity, color, text, id }) => {
  return (
    <>
      <span className="p-1 font-size-10 fw-bold text-white rounded shadow" id={id} style={{ backgroundColor: color }}>
        {text}
      </span>
      <UncontrolledTooltip placement="top" target={id}>
        {entity}
      </UncontrolledTooltip>
    </>
  )
}

const PostAnalysis = ({ post, showDetails }) => {
  const { sentimentPolarity, toxicity, emotions } = post?.behavior || null
  const behaviorScores = mapper.computeBehaviorScores({ sentimentPolarity, emotions, toxicity })
  const dispatch = useDispatch()
  const expandedPostId = useSelector((state) => state.post.expandedPostId)
  const currentAlertId = useSelector((state) => state.post.currentAlertId)

  const { currentData: cascades } = useCascadesFindByPostIdQuery(
    post && expandedPostId === post?.id ? { rootPostId: post?.id, platform: 'twitter', alertId: currentAlertId } : skipToken,
  )

  const [analyseUrl, { data: urlsData, isLoading: isUrlsLoading }] = usePostAnalyzeUrlMutation()

  const hasUrl = useMemo(() => findUrl(post?.text)?.length > 0, [post?.text])

  useEffect(() => {
    if (hasUrl) analyseUrl({ body: post?.text })
  }, [analyseUrl, hasUrl, post?.text])

  return (
    <div className="d-flex flex-column mb-2 post-analysis">
      {/* Topics */}
      {!!post?.topics?.length && (
        <div>
          <p className="fw-bold me-3 mb-2 text-black">Topics</p>
          <div className="d-flex flex-wrap" style={{ gap: 5 }}>
            {post?.topics
              ?.filter((topic) => topic?.score >= 0.4)
              ?.sort((a, b) => b?.score - a?.score)
              .map((t, idx) => {
                return (
                  <span key={'topic-' + idx} className="topics p-1 font-size-11 lh-1 fw-bold" style={{ opacity: t.score + 0.4 }}>
                    {t.topic.replaceAll('_', ' ')}
                  </span>
                )
              })}
          </div>
        </div>
      )}

      {/* GARM */}
      {!!post?.garm?.length && (
        <div className="mt-3">
          <p className="fw-bold me-3 mb-2 text-black">GARM classification</p>
          <div className="d-flex flex-wrap" style={{ gap: 5 }}>
            {post?.garm.map((t, idx) => {
              return (
                <span key={'garm-' + idx} className="topics p-1 font-size-11 lh-1 fw-bold">
                  {t.replaceAll('_', ' ')}
                </span>
              )
            })}
          </div>
        </div>
      )}

      {/* Named Entities */}
      {!!post?.entities?.length && (
        <div className="mt-3">
          <p className="fw-bold me-3 mb-2 text-black">Named Entities </p>
          <div className="d-flex flex-wrap" style={{ gap: 5 }}>
            {post?.entities?.map((ner, idx) => (
              <Ner
                key={'ner-' + idx + post?.id}
                entity={ner.entity}
                color={nerColors[ner.entity]}
                text={ner.text}
                id={'ner-' + idx + post?.id}
              />
            ))}
          </div>
        </div>
      )}

      {/* Behaviorial Signals */}
      <div className="fw-bold mt-3 text-black">Behaviorial Signals</div>
      {showDetails && (
        <div className="mt-2 row">
          {behaviorScores &&
            behaviorScores?.map(({ signal, label, score, color, scores }, idx) => {
              return <Donut key={'pol-' + idx} radius={30} signal={signal} label={label} score={score} color={color} scores={scores} />
            })}
        </div>
      )}

      {/* Url */}
      {hasUrl && (
        <>
          <div className="fw-bold mt-3 text-black">
            Urls analysis {isUrlsLoading && <Spinner className="ml-3" color="primary" size="sm"></Spinner>}
          </div>
          <div className="d-flex flex-wrap mt-2" style={{ gap: 5 }}>
            {urlsData?.urls?.map((t, idx) => {
              return (
                <span key={'url-' + idx} id={'url-' + idx} className={`urls p-1 font-size-11 lh-1 fw-bold ${t?.urlSafety?.toLowerCase()}`}>
                  {t?.detectedUrl.replaceAll('_', ' ')}
                  <UncontrolledTooltip placement="top" target={'url-' + idx}>
                    {t?.urlSafety}
                  </UncontrolledTooltip>
                </span>
              )
            })}
          </div>
        </>
      )}

      {/* Cascade of shares */}
      {cascades && cascades?.links?.length > 1 && (
        <>
          <div className="fw-bold mt-3 text-black">Cascade of shares</div>
          {showDetails && <PostCharts links={cascades?.links} />}
        </>
      )}

      {/* Verdict */}
      {post?.globalVerdictLabel && (
        <>
          <div className="fw-bold mt-3 text-black">Fact Checking</div>
          <div className="d-flex justify-content-end">
            <span
              style={{ background: verdictColors[post?.verdict?.label || post?.globalVerdictLabel?.label], cursor: 'pointer' }}
              onClick={() => !['UNSUPPORTED', 'NA'].includes(post?.globalVerdictLabel?.label) && dispatch(showPostDocuments(post))}
              className="p-1 font-size-12 fw-bold text-white rounded">
              {post?.globalVerdictLabel?.label?.replaceAll('_', ' ')}
            </span>
          </div>
        </>
      )}
    </div>
  )
}

export default PostAnalysis
