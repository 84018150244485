import { useTranslation } from 'react-i18next'
import { HashLink as Link } from 'react-router-hash-link'

const CopyRight = ({ signup, signin, resetPassword, forgotPassword }) => {
  const { t } = useTranslation()

  const message = () => {
    if (signup) {
      return t('haveAccount') + ' '
    } else if (signin) {
      return t('noAccount') + ' '
    } else if (resetPassword || forgotPassword) {
      return t('goBackTo') + ' '
    }
    return ''
  }

  const linkTo = () => {
    if (signup || resetPassword || forgotPassword) {
      return '/signin'
    } else if (signin) {
      return '/#price'
    }
    return ''
  }

  const linkMessage = () => {
    if (signup || resetPassword || forgotPassword) {
      return t('signinNow')
    } else if (signin) {
      return t('signupNow')
    }
    return ''
  }

  return (
    <div className="text-center">
      <div>
        <p>
          {message()}
          <Link to={linkTo()} className="fw-medium text-primary">
            {linkMessage()}
          </Link>
        </p>
      </div>
      <div>© {new Date().getFullYear()} Buster.Ai</div>
    </div>
  )
}

export default CopyRight
