import { useEffect, useMemo, useRef } from 'react'

const useGetLastValue = <T>(value: T[]): T[] | undefined => {
  const previousValueRef = useRef<T[]>()

  useEffect(() => {
    previousValueRef.current = value
  }, [value])

  const result = useMemo(() => {
    if (Array.isArray(value) && value.length > 0) {
      return value
    } else {
      return previousValueRef.current
    }
  }, [value])

  return result
}

export default useGetLastValue
