export const fullText = `
The news is confirmed: Elon Musk has agreed to buy Twitter Inc. in a deal valued at about $44 billion, moving the world's richest man a step closer to taking control of the social-media platform.

Twitter shareholders will receive $54.20 per share in cash, a 38% premium to Twitter's closing stock price on April 1, the last trading day before Mr. Musk disclosed his roughly 9% stake in the company.

Shares of Twitter, which were halted when the deal was announced, rose 5.9% to $51.80 after news of the agreement.

In the news release announcing the deal, Mr. Musk reiterated his push to soften Twitter's stance on content moderation. "Free speech is the bedrock of a functioning democracy, and Twitter is the digital town square where matters vital to the future of humanity are debated," he said.

He also promised other new features, such as "making the algorithms open source to increase trust, defeating the spam bots and authenticating all humans."
`

export const defaultText = fullText

/*
   `Des groupes armés s'affrontaient dimanche dans la capitale libyenne pour le contrôle de l'aéroport de Tripoli, illustrant la lutte d'influence entre libéraux et islamistes dans ce pays miné par l'anarchie depuis la chute du régime de Mouammar Kadhafi en 2011.`
 */

export const busterTopics = [
  'PRODUCTION_MANUFACTURING',
  'SPORTS',
  'WEATHER_GEOGRAPHY',
  'SCIENTIFIC_ARTICLES',
  'GEOPOLITICS_WAR',
  'GENERAL_INFORMATION',
  'PUBLIC_HEALTH',
  'MEDICAL_STUDIES',
  'BUSINESS_CONSULTING',
  'LEGAL_INVESTMENTS',
  'ECONOMY',
  'BASEBALL',
  'PHILOSOPHY_RELIGIONS_WELLBEING',
  'ELECTIONS_AGREEMENTS',
]

export const nerMeaning = {
  PERSON: 'People, including fictional.',
  NORP: 'Nationalities or religious or political groups.',
  FACILITY: 'Buildings, airports, highways, bridges, etc.',
  ORGANIZATION: 'Companies, agencies, institutions, etc.',
  GPE: 'Countries, cities, states.',
  LOCATION: 'Non-GPE locations, mountain ranges, bodies of water.',
  PRODUCT: 'Objects, vehicles, foods, etc. (Not services.)',
  EVENT: 'Named hurricanes, battles, wars, sports events, etc.',
  WORK_OF_ART: 'Titles of books, songs, etc.',
  LAW: 'Named documents made into laws.',
  LANGUAGE: 'Any named language.',
  DATE: 'Absolute or relative dates or periods.',
  TIME: 'Times smaller than a day.',
  PERCENT: 'Percentage, including "%".',
  MONEY: 'Monetary values, including unit.',
  QUANTITY: 'Measurements, as of weight or distance.',
  ORDINAL: '"first", "second", etc.',
  CARDINAL: 'Numerals that do not fall under another type.',
}

const colors = [
  '#EF5350',
  '#EC407A',
  '#BA68C8',
  '#6A1B9A',
  '#4527A0',
  '#651FFF',
  '#F57F17',
  '#536DFE',
  '#0097A7',
  '#4DB6AC',
  '#D4E157',
  '#607D8B',
  '#00BFA5',
  '#F57F17',
  '#03a9f4',
  '#FF8F00',
  '#0288D1',
  '#5d4037',
]
export const nerColors = Object.fromEntries(Object.keys(nerMeaning).map((entity, idx) => [entity, colors[idx]]))

export const behaviorMapping = {
  sentimentPolarity: 'Polarity',
  toxicity: 'Toxicity',
  emotions: 'Emotion',
}

export const verdictColors = {
  CANNOT_SAY: '#D1CFD5',
  CONFIRMED: '#00E1BD',
  CONTROVERSIAL: '#D1CFD5',
  UNSUPPORTED: '#868b8e',
  NA: '#868b8e',
  RATHER_CONFIRMED: '#82C4C1',
  RATHER_REFUTED: '#CF86A9',
  REFUTED: '#FF126D',
  UNDEF: '#868b8e',
  UNVERIFIABLE: '#ADABBE',
}
