import { createSlice } from '@reduxjs/toolkit'

import api from '../ApiClient'

const initialState = []

const stopWordSlice = createSlice({
  name: 'stopWords',
  initialState,
  reducers: {
    setStopWords(state, action) {
      return action.payload
    },
  },
})

export const { setStopWords } = stopWordSlice.actions

export const updateStopWords = (lang) => {
  return async (dispatch) => {
    const response = await api.get('/assets/stop-words', { lang })
    if (response.ok) {
      dispatch(setStopWords(response.body.stopWords))
    } else {
      dispatch(setStopWords(initialState))
    }
  }
}

export default stopWordSlice.reducer
