import React, { useMemo } from 'react'
import * as d3 from 'd3'
import { format } from 'date-fns'

const dateFormatter = d3.timeFormat('%H:%M')

const Axis = ({ domain, range, heightOffset, line = false }) => {
  const ticks = useMemo(() => {
    const xScale = d3.scaleTime().domain(domain).range(range)
    const width = range[1] - range[0]
    const pixelsPerTick = 50
    const numberOfTicksTarget = Math.max(1, Math.floor(width / pixelsPerTick))
    return xScale.ticks(numberOfTicksTarget).map((value) => ({
      value,
      xOffset: xScale(value),
    }))
  }, [domain, range])

  const renderDate = (currentDate, xOffset, oldDate) => {
    if (currentDate?.toDateString() === oldDate?.toDateString()) return
    if (!oldDate) xOffset = 10 // first date
    return (
      <g transform={`translate(${xOffset}, 35)`}>
        <text
          dy={heightOffset}
          fill="#009688"
          style={{
            fontSize: '8px',
            textAnchor: 'middle',
            transform: 'translateX(20px)',
            fontWeight: '600',
          }}>
          {format(currentDate, 'dd-MM-yyyy')}
        </text>
      </g>
    )
  }

  return (
    <svg>
      {line && <path d={['M', range[0], 6, 'v', -6, 'H', range[1], 'v', 6].join(' ')} fill="none" stroke="currentColor" />}
      {ticks.map(({ value, xOffset }, index) => (
        <React.Fragment key={value}>
          <g transform={`translate(${xOffset}, 0)`}>
            <line y2={heightOffset} stroke="#dddddd" />
            <text
              dy={heightOffset}
              fill="#353d42"
              style={{
                fontSize: '10px',
                textAnchor: 'middle',
                transform: 'translateY(20px)',
              }}>
              {dateFormatter(value)}
            </text>
          </g>
          {renderDate(value, xOffset, ticks[index - 1]?.value)}
        </React.Fragment>
      ))}
    </svg>
  )
}

export default Axis
