import React, { useMemo, useEffect } from 'react'
import { useState } from 'react'
import { Container, Row, Col, Card, CardBody, CardTitle, Tooltip } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'

import BreadCrumb from '../../components/BreadCrumb'
import SearchBar from '../../components/SearchBar'
import Articles from '../../components/Articles'
import TextLinkify from '../../components/TextLinkify'
import { useLazyResultQuery, useQueryMutation } from '../../store/nlpApi'
import { subYears, subMinutes } from 'date-fns'
import { dateToISO } from 'utils/dates'
import { nextStep, setClaim, setCurrentRequestId, setStopCheck } from 'store/check/checkSlice'
import { verdictColors } from './CheckTextData'
import { useDispatch, useSelector } from 'react-redux'
import AuthService from 'auth/AuthService'
import Clipboard from 'react-clipboard.js'
import { useNavigate, useParams } from 'react-router-dom'

const maxNbDocuments = 10

const STEP_MESSAGES =
  // : {[key in STEPS]: string}
  {
    INITIAL: 'Fetching related articles...',
    QUERY: 'Fetching related articles...',
    SEARCH: 'Checking claim...',
    CHECK: 'Generating report...',
    REPORT: '',
    HIGHLIGHT: '',
  }

const STEPS = {
  INITIAL: 'INITIAL',
  QUERY: 'QUERY',
  SEARCH: 'SEARCH',
  CHECK: 'CHECK',
  REPORT: 'REPORT',
  HIGHLIGHT: 'HIGHLIGHT',
}

const CHECK_URL = '/app/check/claim/'

const CheckClaimPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { requestId } = useParams()
  const [navClass] = useState('')
  const [isCopied, setIsCopied] = useState(false)

  const {
    id,
    claim,
    sourceTypes,
    report,
    globalVerdict,
    status,
    error,
    documents: articles,
    stopCheck,
  } = useSelector((state) => state.check)
  const [postSearch, { isLoading: searchLoading, data: searchData, reset: resetSearch }] = useQueryMutation()
  const [getResult, { isFetching: resultsLoading, currentData: result }] = useLazyResultQuery()

  // step one: search and format documents
  const sourcesDocuments = useMemo(() => {
    const formattedDocuments = result?.documents
      ?.filter((d) => d.datePublished !== undefined)
      ?.slice(0, maxNbDocuments)
      .map((doc) => {
        const { hasPart, nlpAnalysis: analysis, ...restDoc } = doc
        const { id, nlpAnalysis, ...firstPassage } = restDoc.passages?.[0]
        restDoc.passages = [{ ...firstPassage, highlights: [] }]
        return restDoc
      })
    return formattedDocuments
  }, [result])

  const documents = useMemo(() => articles ?? sourcesDocuments, [sourcesDocuments, articles])

  const hasVerdict = useMemo(() => documents?.some((d) => ['CONFIRMED', 'REFUTED'].includes(d.verdictLabel)), [documents])

  // Handle API requests based on status
  const handleApiRequest = async () => {
    switch (status) {
      case STEPS.QUERY:
        if (searchData) getResult({ input: searchData, waitFor: 'DOCUMENTS' })
        break
      case STEPS.SEARCH:
        if (result?.documents?.length) {
          getResult({ input: searchData, waitFor: 'VERDICT' })
        }
        break
      case STEPS.CHECK:
        if (result?.globalVerdictLabel?.label) getResult({ input: searchData, waitFor: 'REPORT' })
        break
      case STEPS.REPORT:
        if (result?.report) getResult({ input: searchData, waitFor: 'HIGHLIGHT' })
        break
      default:
        break
    }
  }
  useEffect(() => {
    handleApiRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, searchData])

  useEffect(() => {
    if (stopCheck) {
      getResult()?.abort()
      dispatch(setStopCheck(false))
    }
  }, [dispatch, getResult, stopCheck])

  // load factcheck result from requestId
  useEffect(() => {
    if (requestId && !claim && status === 'INITIAL') {
      dispatch(setCurrentRequestId(requestId))
      getResult({ input: requestId, waitFor: 'HIGHLIGHT' })
    }
  }, [dispatch, getResult, claim, requestId, status])

  const isLoading = useMemo(() => searchLoading || resultsLoading, [resultsLoading, searchLoading])

  const onSearch = async (data) => {
    dispatch(nextStep(STEPS.INITIAL))
    dispatch(setClaim(data.search))
    resetSearch()

    const claim = data.search
    const result = await postSearch({
      body: {
        claim,
        preprocess: true,
        processHighlight: true,
        processReport: true,
        publicationEndDate: dateToISO(subMinutes(new Date(), 1)),
        publicationStartDate: dateToISO(subYears(new Date(), 1)),
        organizationTypeCategory: sourceTypes ?? [],
        userLanguage: AuthService.getLang(),
      },
    })
    navigate(CHECK_URL + result.data)
  }

  const handleCopySuccess = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1500)
  }

  return (
    <React.Fragment>
      <div className="page-content check-claim-page">
        <Container fluid>
          <SearchBar
            navClass={navClass}
            value={claim ?? ''}
            placeholder={t('claim') + '...'}
            onSearch={onSearch}
            isLoading={resultsLoading}
          />
          <BreadCrumb title={t('check')} breadcrumbItem={t('claim')} />
          <Row className="justify-content-center mt-4">
            <Col lg={4} className="order-md-first order-last">
              <Card className="documents">
                <CardTitle className="pb-0">
                  {t('supportingArticles')}
                  <hr />
                </CardTitle>
                <CardBody className="pt-3">
                  {documents && <Articles documents={documents?.filter((d) => d.verdictLabel === 'CONFIRMED')} enableSort />}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="mt-lg-0 mt-3">
              <Card className="documents">
                <CardTitle className="pb-0">
                  <div className="d-flex justify-content-between">
                    <div>
                      {t('factChecking')}
                      {globalVerdict && (
                        <>
                          <Clipboard
                            className="ms-1 p-0 px-1 btn btn-link font-size-16"
                            data-clipboard-text={window.location.hostname + CHECK_URL + id}
                            onSuccess={handleCopySuccess}>
                            <i id="share" className="bx bxs-copy" />
                          </Clipboard>
                          <Tooltip autohide={true} isOpen={isCopied} flip target="share">
                            Copied!
                          </Tooltip>
                        </>
                      )}
                    </div>
                    {globalVerdict && (
                      <div className="text-end">
                        <span style={{ background: verdictColors[globalVerdict] }} className="p-1 font-size-12 fw-bold text-white rounded">
                          {globalVerdict.replace('_', ' ')}
                        </span>
                      </div>
                    )}
                  </div>
                  <hr />
                </CardTitle>
                <CardBody className="pt-3">
                  {isLoading && STEP_MESSAGES[status] && (
                    <Row>
                      <Col>
                        <p className="fw-bold">{STEP_MESSAGES[status]}</p>
                      </Col>
                      <Col className="text-end">
                        <span className="fw-bold"> </span> <Spinner color="primary" size="sm"></Spinner>
                      </Col>
                    </Row>
                  )}
                  {error && (
                    <Row>
                      <Col>
                        <p className="error fw-bold">{error}</p>
                      </Col>
                    </Row>
                  )}
                  {!hasVerdict && status === 'CHECK' && <p className="fw-bold">Sorry. No relevant source retrieved to assess the claim.</p>}
                  {searchData && searchData?.sources?.length === 0 && (
                    <p className="fw-bold">Sorry. No relevant source retrieved to assess the claim.</p>
                  )}

                  {report && (
                    <div>
                      {report
                        ?.split('\n')
                        .filter((p) => p !== '')
                        .map((p, idx) => {
                          return (
                            <p key={`report-par-${idx}`}>
                              <TextLinkify text={p} />
                            </p>
                          )
                        })}
                    </div>
                  )}
                  {documents && (status === STEPS.SEARCH || result?.globalVerdictLabel?.label === 'UNSUPPORTED') && (
                    <Articles documents={documents} />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="documents">
                <CardTitle className="pb-0">
                  {t('refutingArticles')}
                  <hr />
                </CardTitle>
                <CardBody className="pt-3">
                  {documents && <Articles documents={documents?.filter((d) => d.verdictLabel === 'REFUTED')} enableSort />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CheckClaimPage
