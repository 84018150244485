import React, { useState } from 'react'
import { Card, CardBody, Button, Container } from 'reactstrap'
import md5 from 'md5'
import { useSelector } from 'react-redux'

const ChatBox = () => {
  const currentUser = useSelector((state) => state.currentUser)

  const defaultMessages = [
    { text: 'Show me the assets to monitor closely', sender: 'user' },
    {
      text: 'Here are 8 assets to monitor closely: Gold, Silver, Material A, Material B, Material C, Material D, Material E, Material F',
      sender: 'bot',
    },
  ]
  const [messages, setMessages] = useState(defaultMessages)
  const [newMessage, setNewMessage] = useState('')

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      setMessages([...messages, { text: newMessage, sender: 'user' }])
      setNewMessage('')
    }
  }
  return (
    <Container>
      <Card className="mt-2">
        <CardBody className="p-3" style={{ borderRadius: 5, background: '#F9F9FA', border: '1px solid #E3E5ED' }}>
          <div style={{ height: '300px', overflowY: 'auto' }}>
            {messages.map((message, index) => (
              <div key={index} className={`mt-2 d-flex ${message.sender === 'user' ? 'justify-content-end' : ''}`}>
                {message.sender !== 'user' && (
                  <span className="me-2">
                    <img
                      className="rounded-circle header-profile-user"
                      src={`https://www.gravatar.com/avatar/${md5('bot@bot.com')}?d=identicon&s=48`}
                      alt="Header Avatar"
                    />
                  </span>
                )}
                <div
                  className="mt-2 p-2"
                  style={{
                    width: 'fit-content',
                    boxShadow: '1px 2px 4px 0px #D8D5E6',
                    background: 'white',
                    borderRadius: '0px 8px 8px 8px',
                    border: '1px solid white',
                    color: '#475560',
                  }}>
                  {message.text}
                </div>
                {message.sender === 'user' && (
                  <span className="ms-2">
                    <img
                      className="rounded-circle header-profile-user"
                      src={`https://www.gravatar.com/avatar/${md5(currentUser?.email)}?d=identicon&s=48`}
                      alt="Header Avatar"
                    />
                  </span>
                )}
              </div>
            ))}
          </div>
        </CardBody>
        <div className="mt-3">
          <div className="d-flex">
            <textarea
              className="form-control"
              rows="3"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <Button color="primary" className="ml-2" onClick={handleSendMessage}>
              Send
            </Button>
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default ChatBox
