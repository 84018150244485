import { useState } from 'react'
import { Badge } from 'reactstrap'

const PolarityFilters = ({ posts, onSelected }) => {
  const [selected, setSelected] = useState({
    negative: true,
    positive: true,
    neutral: true,
  })
  const getPolarity = (sentiment) => {
    return posts?.filter((p) => p?.behavior?.sentimentPolarity?.sentiment === sentiment)?.length
  }

  const toggle = (polarity) => {
    setSelected({ ...selected, [polarity]: !selected[polarity] })
    onSelected({ ...selected, [polarity]: !selected[polarity] })
  }
  return (
    <div>
      <span
        onClick={() => toggle('negative')}
        className={`filter text-white p-1 font-size-10 fw-bold rounded shadow ${selected?.['negative'] ? '' : 'disabled'}`}
        style={{ background: '#e91e63' }}>
        NEGATIVE <Badge className="ms-1 bg-white font-size-11 fw-bold rounded text-black">{getPolarity('NEGATIVE')}</Badge>
      </span>
      <span
        onClick={() => toggle('positive')}
        className={`ms-1 filter text-white p-1 font-size-10 fw-bold rounded shadow ${selected?.['positive'] ? '' : 'disabled'}`}
        style={{ background: '#009688' }}>
        POSITIVE <Badge className="ms-1 bg-white font-size-11 fw-bold rounded text-black">{getPolarity('POSITIVE')}</Badge>
      </span>
      <span
        onClick={() => toggle('neutral')}
        className={`ms-1 filter text-white p-1 font-size-10 fw-bold rounded shadow ${selected?.['neutral'] ? '' : 'disabled'}`}
        style={{ background: '#607d8b' }}>
        NEUTRAL <Badge className="ms-1 bg-white font-size-11 fw-bold rounded text-black">{getPolarity('NEUTRAL')}</Badge>
      </span>
    </div>
  )
}

export default PolarityFilters
