import React from 'react'
import CountUp from 'react-countup'
import { Card, CardBody, Spinner, Col } from 'reactstrap'
import SimpleBar from 'simplebar-react'

const Badges = ({ reports, currentReview, isFetchingReview, isTwitter = true }) => {
  const defaultReports = [
    { title: isTwitter ? 'Tweet' : 'Article', iconClass: 'bx-copy-alt', count: '-' },
    { title: 'Positive', iconClass: 'bxs-right-top-arrow-circle', count: '-' },
    {
      title: 'Negative',
      iconClass: 'bxs-right-down-arrow-circle',
      count: '-',
    },
  ]

  const labelMapping = { Positive: ['POSITIVE'], Negative: ['NEGATIVE'], Tweet: ['NEUTRAL', 'MIXED'], Article: ['NEUTRAL', 'MIXED'] }
  const colorMapping = {
    POSITIVE: 'rgb(0, 150, 136)',
    NEGATIVE: 'rgb(244, 67, 54)',
    NEUTRAL: 'rgb(100, 100, 100)',
    MIXED: 'rgb(245, 127, 23)',
  }

  return (
    <React.Fragment>
      {(reports ?? defaultReports).map((report, key) => (
        <Col md="3" key={'_col_' + key}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">{report.title}</p>
                  <h4 className="mb-0">
                    <CountUp end={report.count} />
                  </h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={'bx ' + report.iconClass + ' font-size-24'}></i>
                  </span>
                </div>
              </div>
              <div>
                <hr />
                {isFetchingReview ? (
                  <Spinner color="primary" size="sm"></Spinner>
                ) : (
                  <SimpleBar style={{ height: '150px' }}>
                    <div>
                      <ul className="list-group list-group-flush">
                        {currentReview?.topics
                          .filter((t) => labelMapping[report.title].includes(t.polarity))
                          .sort((a, b) => b.nbShared - a.nbShared)
                          .map((t, idx) => (
                            <li className="list-group-item p-1" key={'_li_' + idx}>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <h5 className="font-size-14 m-1">
                                    <span
                                      className="px-1 fw-bold font-size-11"
                                      style={{
                                        backgroundColor: 'rgb(244, 248, 254)',
                                        border: '1px solid rgb(205, 220, 252)',
                                        color: colorMapping[t.polarity],
                                        borderRadius: '3px',
                                        width: 'max-content',
                                      }}>
                                      <i className="fa fa-retweet" aria-hidden="true"></i>
                                      {t.nbShared}
                                    </span>
                                    {'  '}
                                    {t?.name}
                                  </h5>
                                  <p className="text-muted m-1">{t?.description}</p>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </SimpleBar>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default Badges
