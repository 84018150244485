import { Routes, Route, useSearchParams, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './assets/scss/theme.scss'
import imgUnder from './assets/images/construction.png'
import CheckTextPage from './pages/app/CheckTextPage.jsx'
import CheckImagePage from './pages/app/CheckImagePage.jsx'
import SearchPage from './pages/app/SearchPage'
import CheckClaimPage from './pages/app/CheckClaimPage'
import DashboardPage from './pages/app/DashboardPage'
import VDashBoard from './components/VDashBoard'
import HDashBoard from './components/HDashBoard'
import PrivateRoute from './components/PrivateRoute'
import SocialNetworkAlertPage from './pages/app/SocialNetworkAlertPage'
import ArticlesCards from './components/search/ArticlesCards'
import SocialNetworkPage from './pages/app/SocialNetworkPage'
import DashboardAssetsPage from 'pages/app/DashboardAssetsPage'

const App = () => {
  const layout = useSelector((state) => state.layout)
  const [searchParams] = useSearchParams()

  const search = searchParams.get('q')

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/app/dashboard" />} />
      <Route
        path="/app"
        element={
          <PrivateRoute permission={'USER_INFO_READ'}>{layout.layoutType === 'vertical' ? <VDashBoard /> : <HDashBoard />}</PrivateRoute>
        }>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="assets/dashboard" element={<DashboardAssetsPage />} />
        <Route path="check/claim" element={<CheckClaimPage />} />
        <Route path="check/claim/:requestId" element={<CheckClaimPage />} />
        <Route path="check/text" element={<CheckTextPage />} />
        <Route path="check/image" element={<CheckImagePage />} />
        <Route path="search" element={<SearchPage search={search} />}>
          <Route path="articles">
            <Route index element={<ArticlesCards />} />
            <Route path="list" element={<ArticlesCards />} />
            <Route
              path="polarity"
              element={
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img src={imgUnder} alt="under construction" />
                </div>
              }
            />
            <Route
              path="*"
              element={
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img src={imgUnder} alt="under construction" />
                </div>
              }
            />
          </Route>
          <Route
            path="events"
            element={
              <div className="d-flex justify-content-center align-items-center h-100">
                <img src={imgUnder} alt="under construction" />
              </div>
            }
          />
        </Route>
        <Route path="sna" element={<SocialNetworkPage />} />
        <Route path="sna-config" element={<SocialNetworkAlertPage />} />
      </Route>
    </Routes>
  )
}

export default App
