import { useEffect } from 'react'
import { useSharedHighlightMutation } from './useSharedHighlightMutation'
import { useSharedSearchMutation } from './useSharedSearchMutation'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

export const useHighLight = () => {
  const [, { data: searchResponse }] = useSharedSearchMutation()
  const [postHighlight] = useSharedHighlightMutation()
  const keywords = useSelector((state: RootState) => state.search.keywords)

  // trigger Highlight
  useEffect(() => {
    const textsFromSources =
      searchResponse?.articles_content &&
      Object.values(searchResponse.articles_content)?.map(({ selected_passage }) => selected_passage?.substring(0, 400))
    if (keywords && textsFromSources && textsFromSources?.length > 0)
      postHighlight({
        body: {
          referenceText: keywords?.join(', '),
          texts: textsFromSources.slice(0, 5) as string[],
        },
      })
  }, [searchResponse, postHighlight, keywords])
}
