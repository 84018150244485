import i18n from '../i18n'

export class ValidationError extends Error {
  constructor(message, fields, ...params) {
    super(...params)
    this.name = 'ValidationError'
    this.message = message
    this.fields = fields
  }
}

export class InvalidCredentialsError extends Error {
  constructor(...params) {
    super(...params)
    this.name = 'InvalidCredentialsError'
    this.message = i18n.t('credentialsInvalid')
  }
}

export class UnauthorizedError extends Error {
  constructor(description, ...params) {
    super(...params)
    this.name = 'UnauthorizedError'
    this.message = description
  }
}

export class BadRequestError extends Error {
  constructor(...params) {
    super(...params)
    this.name = 'BadRequestError'
    this.message = 'Request failed'
  }
}
