import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectArticle } from 'store/check/checkSlice'
import ReactHighlightWords from 'react-highlight-words'
import { RootState } from 'store'

interface Props {
  text: string
}

function TextLinkify({ text }: Props): JSX.Element {
  const reportHighlights = useSelector((state: RootState) => state.check.reportHighlights)
  const dispatch = useDispatch()
  const regex = /(\[\d+\])/g
  const parts = text.split(regex).filter(Boolean)

  const goToArticle = (articleNumber: string) => {
    dispatch(selectArticle(articleNumber))
    const element = document.getElementById(`article-${articleNumber}`)
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  return (
    <>
      {parts.map((part, index) => {
        if (part.match(regex)) {
          const articleNumber = part.replace('Article [', '').replace('article [', '').replace('[', '').replace(']', '')
          return (
            <a key={index} href={`#${articleNumber}`} onClick={() => goToArticle(articleNumber)}>
              {part}
            </a>
          )
        } else {
          return (
            <span key={index}>
              <ReactHighlightWords
                highlightClassName="highlight"
                searchWords={reportHighlights ?? []}
                autoEscape
                highlightTag={({ children }) => <strong>{children}</strong>}
                textToHighlight={part}
              />
            </span>
          )
        }
      })}
    </>
  )
}

export default TextLinkify
