import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AuthService from '../auth/AuthService'

const PrivateRoute = ({ permission, children }) => {
  const currentUser = useSelector((state) => state.currentUser)
  const hasPermission = AuthService.hasRole(permission, currentUser)
  const skipAuth = process.env.REACT_APP_SKIP_AUTH === 'true'
  if ((currentUser && hasPermission) || skipAuth) {
    return children
  } else if (currentUser && !hasPermission) {
    return <div>Not Authorized</div>
  } else {
    AuthService.redirectToAuthApp()
  }
}

PrivateRoute.propTypes = {
  permission: PropTypes.oneOf([
    'USER_INFO_READ',
    'EDIT_USER',
    'MONITOR',
    'READ',
    'UPLOAD',
  ]),
}

export default PrivateRoute
