// Given a cookie key `name`, returns the value of
// the cookie or `undefined`, if the key is not found.
export function getCookie(name: string): string | undefined {
  const nameLenPlus = name.length + 1
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
      .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] ||
    undefined
  )
}

export function eraseCookie(name: string, domain: string | undefined) {
  const domainName = domain === 'localhost' ? domain : `.${domain}`
  document.cookie = `${name}=; path=/; domain=${domainName}; max-age=0`
}
