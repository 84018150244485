import { createSlice } from '@reduxjs/toolkit'

import api from '../ApiClient'
import { ValidationError } from '../utils/errors'
import { formatPermissions } from '../utils/formatting'

const users = [
  {
    avatarUrl:
      'https://www.gravatar.com/avatar/62404b2962c0a1a8fb24b23797f30ae8?d=identicon',
    email: 'julien@buster.ai',
    firstName: 'Julien',
    firstSeen: '2022-12-10T11:22:23.453616Z',
    id: 'LkTF-4QBfEFywU3Ln5xe',
    lastName: 'Madras',
    lastSeen: '2022-12-10T11:22:23.453618Z',
    organization: 'Buster',
    permissions: [
      {
        name: 'EDIT_USER',
        value: true,
      },
      {
        name: 'MONITOR',
        value: true,
      },
      {
        name: 'READ',
        value: true,
      },
      {
        name: 'UPLOAD',
        value: true,
      },
    ],
    role: 'Administrator',
    subscription: 'org',
  },
  {
    avatarUrl:
      'https://www.gravatar.com/avatar/1f6f18e919cf36f6ea313c5f9f72e02a?d=identicon',
    email: 'xavier@buster.ai',
    firstName: 'Xavier',
    firstSeen: '2022-12-10T11:22:23.585250Z',
    id: 'L0TF-4QBfEFywU3Ln5zh',
    lastName: 'Bost',
    lastSeen: '2022-12-10T11:22:23.585252Z',
    organization: 'Buster',
    permissions: [
      {
        name: 'EDIT_USER',
        value: true,
      },
      {
        name: 'MONITOR',
        value: true,
      },
      {
        name: 'READ',
        value: true,
      },
      {
        name: 'UPLOAD',
        value: true,
      },
    ],
    role: 'Administrator',
    subscription: 'org',
  },
  {
    avatarUrl:
      'https://www.gravatar.com/avatar/58693b9ceea7a53c51f1ecffae5350d8?d=identicon',
    email: 'badr@buster.ai',
    firstName: 'Badr',
    firstSeen: '2022-12-10T11:22:23.734645Z',
    id: 'METF-4QBfEFywU3LoJx3',
    lastName: 'Chentouf',
    lastSeen: '2022-12-10T11:22:23.734647Z',
    organization: 'Buster',
    permissions: [
      {
        name: 'EDIT_USER',
        value: true,
      },
      {
        name: 'MONITOR',
        value: true,
      },
      {
        name: 'READ',
        value: true,
      },
      {
        name: 'UPLOAD',
        value: true,
      },
    ],
    role: 'Contributor',
    subscription: 'org',
  },
]

const initialState = []

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    createUser(state, action) {
      const user = formatPermissions(action.payload)
      state.push(user)
    },
    editUser(state, action) {
      const newUser = formatPermissions(action.payload)
      return state.map((user) => (user.id === newUser.id ? newUser : user))
    },
    setUsers(state, action) {
      return action.payload
    },
    deleteUsers(state, action) {
      const ids = action.payload
      return state.filter((user) => !ids.includes(user.id))
    },
  },
})

export const { createUser, editUser, setUsers, deleteUsers } = userSlice.actions

export const signUp = (user) => {
  return async (dispatch) => {
    const response = await api.post('/users/me', user)
    if (response.ok) {
      dispatch(createUser(response.body))
    } else if (response.status === 400) {
      throw new ValidationError(
        response.body.message,
        response.body.errors.json
      )
    }
  }
}

export const addUser = (user) => {
  return async (dispatch) => {
    const response = await api.post(`/users`, user)
    if (response.ok) {
      dispatch(createUser(response.body))
    } else if (response.status === 400) {
      throw new ValidationError(
        response.body.message,
        response.body.errors.json
      )
    }
  }
}

export const updateUser = (id, user) => {
  return async (dispatch) => {
    const response = await api.put(`/users/${id}`, user)
    if (response.ok) {
      dispatch(editUser(response.body))
    } else if (response.status === 400) {
      throw new ValidationError(
        response.body.message,
        response.body.errors.json
      )
    }
  }
}

export const removeUsers = (userIds) => {
  return async (dispatch) => {
    await Promise.all(
      userIds.map(async (id) => await api.delete(`/users/${id}`))
    )
    dispatch(deleteUsers(userIds))
  }
}

export const initializeUsers = () => {
  return async (dispatch) => {
    /*
      const response = await api.get('/users')
	 if (response.ok) {
	     dispatch(setUsers(response.body))
	 }
      */
    dispatch(setUsers(users))
  }
}

export default userSlice.reducer
