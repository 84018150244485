import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Card, CardBody, CardTitle, Col, Container, Input, Row } from 'reactstrap'
import PieChart from '../../components/PieChart'
import TopPublisher from '../../components/dashboard/TopPublisher'
import Badges from '../../components/dashboard/Badges'
import Loader from '../../components/Loader'
import TopNE from '../../components/dashboard/TopNE'
import StackedColumnChart from '../../components/dashboard/StackedColumnChart'
import TreemapChart from '../../components/dashboard/TreemapChart'
import { useDispatch, useSelector } from 'react-redux'
import { useAlertsFindAllQuery, useAuthorsFindAllMutation, useDashboardGetMetricsQuery, useReviewsFindQuery } from '../../store/sna/snaApi'
import { useSearchSearchPostMutation } from '../../store/ana/anaApi'
import { default as mapper } from '../../utils/dashboardMapper'
import { default as mediaMapper } from '../../utils/dashboardMediaMapper'
import SelectSearchBar from 'components/SelectSearchBar'
import { selectAlert } from 'store/sna/postSlice'
import { getDateInterval, getTimezone } from 'utils/utils'
import { skipToken } from '@reduxjs/toolkit/query'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const [interval, setInterval] = useState('lastWeek')
  const [infoType, setInfoType] = useState('social')
  const isSocialNetworkType = useMemo(() => infoType === 'social', [infoType])
  const currentAlertId = useSelector((state) => state.post.currentAlertId)
  const { currentData: alertsData, isFetching } = useAlertsFindAllQuery()
  const [getUsers, { data: currentUsers }] = useAuthorsFindAllMutation()

  const alerts = useMemo(
    () =>
      alertsData
        // ?.filter((a) => a.active)
        ?.slice()
        .sort((a, b) => a?.text.localeCompare(b?.text)),
    [alertsData],
  )

  const dates = useMemo(() => {
    const alert = alerts?.find((alert) => alert.id === currentAlertId)
    if (alert) return getDateInterval(interval, alert?.lastFetched)
    return undefined
  }, [alerts, currentAlertId, interval])

  const [searchMedia, { data: searchResponse, isLoading: isFetchingMedia }] = useSearchSearchPostMutation()

  const { currentData, isFetching: isFetchingSocial } = useDashboardGetMetricsQuery(
    currentAlertId && dates && interval && isSocialNetworkType
      ? {
          alertId: currentAlertId,
          platform: 'twitter',
          start: dates?.start.toISOString(),
          end: dates?.end.toISOString(),
          timezone: getTimezone(),
        }
      : skipToken,
  )

  const { currentData: currentReview, isFetching: isFetchingReview } = useReviewsFindQuery(
    currentAlertId && dates && interval
      ? {
          alertId: currentAlertId,
          platform: isSocialNetworkType ? 'twitter' : 'media',
          start: new Date(dates?.start.getTime() - dates?.start.getTimezoneOffset() * 60000).toISOString(),
          end: new Date(dates?.end.getTime() - dates?.end.getTimezoneOffset() * 60000).toISOString(),
          interval: interval === 'today' || interval === 'yesterday' ? 'day' : 'week',
        }
      : skipToken,
  )

  const socialMetrics = useMemo(() => {
    const sentiment = currentData && mapper.parseSentiments(currentData?.sentiments)
    const authors = currentData && mapper.parseAuthors(currentData?.topAuthors)
    const entities = currentData && mapper.parseNers(currentData?.topEntities)
    const reports = currentData && mapper.getReports(currentData?.metrics)
    const verdicts = currentData && mapper.getVerdicts(currentData?.verdicts)
    const emotions = currentData && mapper.parseEmotions(currentData?.emotions)
    const languages = currentData && mapper.getLanguages(currentData?.lang)
    const toxicities = currentData && currentData?.toxicities
    return { sentiment, authors, topics: currentData?.topTopics, entities, reports, verdicts, emotions, languages, toxicities }
  }, [currentData])

  const mediaMetrics = useMemo(() => {
    const stats = searchResponse && searchResponse?.articles_stats
    const sentiment = stats && mediaMapper.parseSentiments(stats.top_polarity_per_day)
    const authors = stats && mediaMapper.parseAuthors(stats.top_sources)
    const entities = stats && mediaMapper.parseNers(stats.top_named_entities)
    const reports = stats && mediaMapper.getReports({ count: stats.total_count_articles, ...stats.top_polarity })
    const emotions = stats && mediaMapper.parseEmotions(stats.top_emotions)
    const languages = stats && mediaMapper.getLanguages(stats.top_languages)
    const topics = stats && mediaMapper.getTopics(stats.top_iptc_tags)
    // const toxicities = stats && stats.toxicities
    return {
      sentiment,
      authors,
      topics,
      entities,
      reports,
      emotions,
      languages,
    }
  }, [searchResponse])

  const isLoading = useMemo(() => isFetching || isFetchingMedia || isFetchingSocial, [isFetching, isFetchingMedia, isFetchingSocial])
  const metrics = useMemo(() => (isSocialNetworkType ? socialMetrics : mediaMetrics), [socialMetrics, mediaMetrics, isSocialNetworkType])

  // get all uniques users, based on selected alert posts
  useEffect(() => {
    if (currentData?.topAuthors?.length > 0)
      getUsers({ queryAuthor: { ids: [...new Set(currentData?.topAuthors?.map((author) => author.authorId))] } })
  }, [currentData?.topAuthors, getUsers])

  const onSearch = useCallback(
    (id) => {
      dispatch(selectAlert(id))
    },
    [dispatch],
  )

  // set initial default alert
  useEffect(() => {
    if (alerts?.length && !currentAlertId) dispatch(selectAlert(alerts?.[0]?.id))
  }, [alerts, currentAlertId, dispatch])

  useEffect(() => {
    if (!isSocialNetworkType && dates) {
      const alert = alerts?.find((alert) => alert.id === currentAlertId)
      searchMedia({
        articleSearchRequest: {
          keyphrases: [alert?.text],
          publication_date_start: new Date(dates?.start.getTime() - dates?.start.getTimezoneOffset() * 60000).toISOString(),
          publication_date_end: new Date(dates?.end.getTime() - dates?.end.getTimezoneOffset() * 60000).toISOString(),
          top_articles_with_content: 0,
          with_aggregated_stats: true,
          max_request_size: 0,
        },
      })
    }
  }, [isSocialNetworkType, alerts, searchMedia, dates, currentAlertId])

  return (
    <React.Fragment>
      <div className="page-content dashboard-page">
        <Container fluid className="position-relative">
          <Loader isLoading={isLoading} />
          <Row>
            <Col sm={9}>
              <Card style={{ minHeight: 84 }}>
                <CardBody className="py-2">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex" style={{ gap: 10 }}>
                      {!isFetching && (
                        <SelectSearchBar
                          navClass={''}
                          search={alerts?.find((alert) => alert.id === currentAlertId) ?? alerts?.[0]}
                          onSearch={onSearch}
                          options={alerts?.map((alert) => ({
                            value: alert?.id,
                            label: alert?.text,
                            lang: alert?.lang,
                            date: alert?.startTime,
                            dateEnd: alert?.endTime,
                          }))}
                        />
                      )}
                      <div className="">
                        <Input
                          type="select"
                          name="interval"
                          // value={event ? event.category : "bg-primary"}
                          onChange={(e) => setInterval(e.target.value)}
                          value={interval}>
                          <option value="today">Today</option>
                          <option value="yesterday">Yesterday</option>
                          <option value="lastWeek">Last week</option>
                        </Input>
                      </div>
                      <div className="">
                        <Input type="select" name="infoType" onChange={(e) => setInfoType(e.target.value)} value={infoType}>
                          <option value="social">Social network</option>
                          <option value="media">Media</option>
                        </Input>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-end" style={{ gap: 4 }}>
                      {metrics?.toxicities > 0 && (
                        <div className="d-flex toxicity-count">
                          <i className="bx bx-info-circle ms-1" />
                          <span>{metrics?.toxicities} toxic posts detected</span>
                        </div>
                      )}
                      {metrics?.topics?.[0] && (
                        <span
                          className="mt-1 p-1 font-size-11 lh-1 fw-bold rounded"
                          style={{
                            backgroundColor: 'rgb(244, 248, 254)',
                            border: '1px solid rgb(205, 220, 252)',
                            color: 'rgb(3, 81, 240)',
                            width: 'max-content',
                          }}>
                          {metrics?.topics?.[0].value.replaceAll('_', ' ')}
                          <Badge className="ms-1 bg-secondary font-size-11 fw-bold rounded text-white">{metrics?.topics?.[0]?.count}</Badge>
                        </span>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* Reports Render */}
            <Badges
              reports={metrics?.reports}
              currentReview={currentReview}
              isFetchingReview={isFetchingReview}
              isTwitter={isSocialNetworkType}
            />
            <Col sm={3}>
              {isSocialNetworkType && (
                <Card style={{ height: '281px' }}>
                  <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                    <div className="d-flex">
                      <span>Verdicts</span>
                    </div>
                  </CardTitle>
                  <CardBody className="pt-0 pb-0">
                    <div className="d-flex justify-content-center" style={{ height: 164 }}>
                      <PieChart data={metrics?.verdicts ?? []} height={'100%'} />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={5}>
              <Card>
                <CardTitle className="p-3 pt-2 pb-0 mb-0 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Polarity over time</span>
                  </div>
                </CardTitle>
                <CardBody className="pt-0 pb-1">
                  <StackedColumnChart
                    periodData={metrics?.sentiment?.dataPolarity ?? []}
                    xaxis={metrics?.sentiment?.xaxis ?? ['1', '2']}
                    dataColors={['#e91e63', '#00e1bd', 'gray']}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={3}>
              <Card>
                <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Entities</span>
                  </div>
                </CardTitle>
                <CardBody className="pt-0">
                  <div className="d-flex flex-column">
                    <TreemapChart nersData={metrics?.entities ?? []} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={4} className="d-flex">
              <TopPublisher publishers={metrics?.authors ?? []} users={currentUsers} isTwitter={isSocialNetworkType} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={2} className="d-flex">
              <Card className="flex-fill">
                <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Topics</span>
                  </div>
                </CardTitle>
                <CardBody className="pt-0 d-flex">
                  <div className="d-flex flex-column">
                    {metrics?.topics?.map((topic, i) => (
                      <span
                        key={'topic-topK-' + i}
                        className="mt-2 p-1 font-size-11 lh-1 fw-bold rounded"
                        style={{
                          backgroundColor: 'rgb(244, 248, 254)',
                          border: '1px solid rgb(205, 220, 252)',
                          color: 'rgb(3, 81, 240)',
                          width: 'max-content',
                        }}>
                        {topic.value.replaceAll('_', ' ')}
                        <Badge className="ms-1 bg-secondary font-size-11 fw-bold rounded text-white">{topic?.count}</Badge>
                      </span>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={3} className="d-flex">
              <Card className="flex-fill">
                <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Emotions</span>
                  </div>
                </CardTitle>
                <CardBody className="pt-0 pb-0 d-flex justify-content-center align-items-center">
                  <PieChart data={metrics?.emotions ?? []} showLabels={false} legend={true} height={220} donut={'80%'} position="top" />
                </CardBody>
              </Card>
            </Col>
            <Col sm={3} className="d-flex">
              <Card className="flex-fill">
                <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Languages</span>
                  </div>
                </CardTitle>
                <CardBody className="pt-0 pb-0 d-flex justify-content-center align-items-center">
                  <PieChart data={metrics?.languages ?? []} height={220} donut={'80%'} showLabels={false} legend={true} position={'top'} />
                </CardBody>
              </Card>
            </Col>
            <Col sm={4} className="d-flex">
              <Card className="flex-fill">
                <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Top Entities</span>
                  </div>
                </CardTitle>
                <CardBody className="pt-0 pb-0 entities-table">
                  <TopNE entities={metrics?.entities?.slice(0, 5) ?? []} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardPage
