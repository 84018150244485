import React from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

const StackedColumnChart = ({ dataColors, periodData, xaxis }) => {
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: '15%',
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: xaxis,
      labels: {
        show: true,
      },
    },
    colors: dataColors,
    legend: {
      position: 'bottom',
    },
    fill: {
      opacity: 1,
    },
  }
  return <ReactApexChart options={options} series={[...periodData]} type="bar" height="350" className="apex-charts" />
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
