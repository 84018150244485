import React, { useState } from 'react'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import CustomPieChart from 'components/CustomPieChart'
import CustomStackedColumnChart from 'components/dashboard/CustomStackedColumnChart'
import AssetTable from 'components/dashboard/AssetsTable'
import ChatBox from 'components/dashboard/ChatBox'

const ProgressBar = ({ value, total, color }) => {
  const percentage = (value / total) * 100

  const progressBarStyle = {
    width: `${percentage}%`,
    backgroundColor: color,
    height: 'inherit',
  }

  return (
    <div className="progress-bar-container rounded" style={{ background: '#F4F3F6', height: 8 }}>
      <div className="progress-bar rounded" style={progressBarStyle}></div>
    </div>
  )
}

const DataList = ({ data }) => {
  const total = data.reduce((acc, item) => acc + item.value, 0)

  return (
    <div>
      {data.map((item, index) => (
        <div key={index} className="data-item mb-3">
          <div className="d-flex justify-content-between py-1 fw-bold">
            <span>{item.label}</span>
            <div
              className="fw-bold rounded"
              style={{ color: item.color, background: `${item.color}33`, fontSize: 10, fontWeight: 600, padding: '2px' }}>
              {item.value}
            </div>
          </div>
          <ProgressBar value={item.value} total={total} color={item.color} />
        </div>
      ))}
    </div>
  )
}
const DashboardAssetsPage = () => {
  const data = [
    {
      label: 'Positive Intel',
      value: 89,
      color: '#00E1BD',
    },
    {
      label: 'Negative Intel',
      value: 53,
      color: '#FF126D',
    },
    {
      label: 'Neutral Intel',
      value: 497,
      color: '#D1CFD5',
    },
  ]

  const dataPolarity = {
    sentiment: {
      xaxis: [
        '11/01',
        '11/02',
        '11/03',
        '11/04',
        '11/05',
        '11/06',
        '11/07',
        '11/08',
        '11/09',
        '11/10',
        '11/11',
        '11/12',
        '11/13',
        '11/14',
        '11/15',
        '11/16',
        '11/17',
        '11/18',
        '11/19',
        '11/20',
        '11/21',
      ],
      dataPolarity: [
        {
          name: 'Resolved Risks',
          data: [7, 4, 9, 2, 8, 3, 6, 5, 10, 1, 6, 8, 9, 3, 7, 1, 5, 9, 4, 2, 8],
        },
        {
          name: 'Unresolved Risks',
          data: [9, 3, 8, 6, 2, 5, 4, 7, 1, 10, 7, 4, 6, 8, 3, 1, 9, 5, 2, 10, 4],
        },
      ],
    },
  }

  const columns = [
    {
      Header: 'Asset Name',
      accessor: 'name',
    },
    {
      Header: 'Risk Level',
      accessor: 'riskLevel',
    },
    {
      Header: 'Positive',
      accessor: 'positive',
    },
    {
      Header: 'Negative',
      accessor: 'negative',
    },
    {
      Header: 'Daily Recommendation',
      accessor: 'dailyRecommendation',
    },
  ]

  const tableData = [
    { name: 'Asset 1', riskLevel: 2, positive: 100, negative: 20, dailyRecommendation: 'Buy' },
    { name: 'Asset 2', riskLevel: 3, positive: 120, negative: 30, dailyRecommendation: 'Hold' },
    { name: 'Asset 3', riskLevel: 1, positive: 90, negative: 10, dailyRecommendation: 'Sell' },
    { name: 'Asset 4', riskLevel: 2, positive: 110, negative: 25, dailyRecommendation: 'Buy' },
    { name: 'Asset 5', riskLevel: 4, positive: 150, negative: 40, dailyRecommendation: 'Hold' },
    { name: 'Asset 6', riskLevel: 3, positive: 130, negative: 35, dailyRecommendation: 'Sell' },
    { name: 'Asset 7', riskLevel: 1, positive: 95, negative: 15, dailyRecommendation: 'Buy' },
    { name: 'Asset 8', riskLevel: 2, positive: 105, negative: 22, dailyRecommendation: 'Hold' },
    { name: 'Asset 9', riskLevel: 3, positive: 125, negative: 32, dailyRecommendation: 'Sell' },
    { name: 'Asset 10', riskLevel: 4, positive: 140, negative: 38, dailyRecommendation: 'Buy' },
  ]
  return (
    <React.Fragment>
      <div className="page-content dashboard-page">
        <Container fluid className="position-relative">
          <Row>
            <Col sm={8}>
              <Row>
                <Col sm={4}>
                  <Card style={{ height: '242px' }}>
                    <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                      <div className="d-flex">
                        <span>General</span>
                      </div>
                    </CardTitle>
                    <CardBody className="pt-0 pb-0">
                      <div className="d-flex">
                        <Col sm={6}>
                          <CustomPieChart
                            showLabels={false}
                            total={40}
                            legend={false}
                            position="left"
                            donut={60}
                            data={data}
                            height={'110%'}
                          />
                        </Col>
                        <Col sm={6}>
                          <DataList data={data} />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={8}>
                  <Card>
                    <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                      <div className="d-flex">
                        <span>Risk history</span>
                      </div>
                    </CardTitle>
                    <CardBody className="pt-0 pb-0">
                      <CustomStackedColumnChart
                        periodData={dataPolarity?.sentiment?.dataPolarity ?? []}
                        xaxis={dataPolarity?.sentiment?.xaxis ?? ['1', '2']}
                        dataColors={['#3A2D83', '#E0DEFF']}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <Card>
                    <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                      <div className="d-flex">
                        <span>AI Assistant</span>
                      </div>
                    </CardTitle>
                    <CardBody className="p-1">
                      <ChatBox />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={7}>
                  <Card>
                    <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                      <div className="d-flex">
                        <span>Portfolio list</span>
                      </div>
                    </CardTitle>
                    <CardBody className="pt-0 pb-0">
                      <AssetTable columns={columns} data={tableData} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col sm={4}>
              <Card>
                <CardTitle className="p-3 pt-2 pb-0 d-flex justify-content-between header">
                  <div className="d-flex">
                    <span>Economic Calendar</span>
                  </div>
                </CardTitle>
                <CardBody className="pt-0 pb-0">
                  <EventCard />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardAssetsPage

const EventCard = () => {
  const [selectedEvent, setSelectedEvent] = useState(null)

  const events = [
    {
      timestamp: '19:24 11/28/2023',
      description: 'Eurozone activity falls again in November, fueling recession fears',
    },
    {
      timestamp: '08:45 12/02/2023',
      description: 'Unemployment rates reach record low in the United States',
    },
    {
      timestamp: '15:30 12/05/2023',
      description: 'Global stock markets experience a sudden downturn',
    },
    {
      timestamp: '12:15 12/10/2023',
      description: 'Tech industry leaders announce major breakthroughs in AI research',
    },
    {
      timestamp: '09:20 12/15/2023',
      description: 'New environmental policies adopted by G7 nations',
    },
    {
      timestamp: '14:50 12/18/2023',
      description: 'SpaceX launches its first mission to Mars',
    },
    {
      timestamp: '11:05 12/22/2023',
      description: 'Major cyberattack targets global financial institutions',
    },
    {
      timestamp: '07:30 12/25/2023',
      description: 'Renewable energy sources surpass fossil fuels in power generation',
    },
    {
      timestamp: '16:40 12/28/2023',
      description: 'World Health Organization declares end to a major pandemic',
    },
    {
      timestamp: '10:15 01/02/2024',
      description: 'Scientists discover a new species in the Amazon rainforest',
    },
    {
      timestamp: '13:55 01/05/2024',
      description: 'Breakthrough in medical research leads to a potential cure for a rare disease',
    },
    {
      timestamp: '18:10 01/10/2024',
      description: 'Renowned artist unveils a groundbreaking interactive exhibit',
    },
    // Add more events as needed
  ]

  const handleEventClick = (index) => {
    console.log('clicked ', index)
    console.log('selectedEvent ', selectedEvent)

    setSelectedEvent(index === selectedEvent ? null : index)
  }

  return (
    <div className="event-card" style={{ height: 'calc(100vh - 150px)', overflowY: 'auto' }}>
      {events.map((event, index) => (
        <div key={index}>
          <div className="event-header d-flex" onClick={() => handleEventClick(index)}>
            <div className="d-flex" style={{ width: 80, minWidth: 80, color: '#ADABBE' }}>
              {event.timestamp}
            </div>
            <div className="ms-3" style={{ color: '#454351', fontWeight: 400 }}>
              {event.description}
            </div>
          </div>
          {selectedEvent === index && (
            <div className="event-details">
              {/* Display additional event details here */}
              event details
            </div>
          )}
          <hr />
        </div>
      ))}
    </div>
  )
}
