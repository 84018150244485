import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Alert } from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { getColumns } from './columns'
import React from 'react'
import AlertForm from './AlertForm'
import NotifModal from './NotifModal'
import { useAlertsDeleteAlertMutation, useAlertsFindAllQuery } from '../../../store/sna/snaApi'

const AlertsTable = () => {
  const { t } = useTranslation()

  const { currentData: alerts, isFetching } = useAlertsFindAllQuery()
  const [deleteAlert] = useAlertsDeleteAlertMutation()

  const defaultSorted = [
    {
      dataField: 'startTime',
      order: 'desc',
    },
  ]

  const options = {
    sizePerPage: 10,
    totalSize: alerts?.length,
    custom: true, // Hide original page numbers
  }

  const { SearchBar } = Search

  const [selectedAlert, setSelectedalert] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [modal, setModal] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)

  const handleAlertClicks = () => {
    setSelectedalert({})
    setIsEdit(false)
    toggle()
  }

  const toggle = () => {
    setModal(!modal)
  }

  const onEdit = (alert) => {
    setSelectedalert(alert)
    setIsEdit(true)
    setModal(true)
  }

  const onDelete = (alert) => {
    setDeleteModal(true)
    setSelectedalert(alert)
  }

  const onDeleteClick = async () => {
    try {
      await deleteAlert({ alertId: selectedAlert?.id })
      setFlashMessage({
        message: t('alert') + ' ' + selectedAlert.text + ' ' + t('flashMessageDeleteAlertSucces'),
        messageType: 'success',
      })
    } catch (error) {
      console.error('rejected', error)
    }
    setTimeout(() => setFlashMessage(null), 5_000)
    setDeleteModal(false)
    setSelectedalert({})
  }

  return (
    <>
      <NotifModal
        show={deleteModal}
        onConfirmClick={onDeleteClick}
        onCloseClick={() => setDeleteModal(false)}
        buttonName={t('confirmDelete')}>
        <h5>
          {t('messageVerify1')}
          <font className="text-danger">
            {selectedAlert.firstName} {selectedAlert.lastName}
          </font>
        </h5>
        <h6>({selectedAlert && t('messageVerify2')})</h6>
      </NotifModal>

      {isFetching && <div>loading</div>}
      {!isFetching && alerts && (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({
            paginationProps, // this include everything about pagination, you will use it when you render standalone component or your custom component.
            paginationTableProps, // have to give it as props when render BootstrapTable
          }) => (
            <ToolkitProvider keyField="id" columns={getColumns(onEdit, onDelete)} data={alerts || []} search>
              {(toolkitprops) => (
                <>
                  <Row className="mb-2">
                    {/**Search bar */}
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitprops.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    {/**Button add alert */}
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button color="primary" className="font-16 btn-block btn btn-primary" onClick={handleAlertClicks}>
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add alert
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/**Message operations */}
                  {flashMessage && (
                    <Alert
                      className="text-center"
                      severity={flashMessage?.msgType}
                      color={flashMessage?.msgType}
                      onClose={() => setFlashMessage(null)}>
                      {flashMessage.message}
                    </Alert>
                  )}

                  {/**Alerts table */}
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          {...paginationTableProps}
                          defaultSorted={defaultSorted}
                          classes={'table align-middle table-nowrap table-hover'}
                          bordered={false}
                          striped={false}
                          responsive
                        />
                      </div>
                    </Col>
                  </Row>
                  {/**Pages numbers */}
                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded justify-content-end mb-2">
                      <PaginationListStandalone {...paginationProps} />
                    </Col>
                  </Row>
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}
      {/**buttons control alert forms -> add alert or edit alert */}
      <Modal isOpen={modal} centered={true} fade className="min-width-60" size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? 'Edit Alert' : 'Add Alert'}
        </ModalHeader>
        <ModalBody>
          {isEdit && selectedAlert !== null ? (
            <AlertForm isEdit alert={selectedAlert} closeModal={() => setModal(false)} />
          ) : (
            <AlertForm closeModal={() => setModal(false)} />
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default AlertsTable
