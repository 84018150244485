import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import './i18n'
import App from './App'
import { store } from './store'
import reportWebVitals from './reportWebVitals'
import AuthProvider from './auth/AuthProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </Provider>,
)

/* root.render(
 *   <React.StrictMode>
 *     <Provider store={store}>
 *       <BrowserRouter>
 *         <App />
 *       </BrowserRouter>
 *     </Provider>
 *   </React.StrictMode>
 * ) */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
