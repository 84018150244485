import { useState } from 'react'
import md5 from 'md5'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import AuthService from '../auth/AuthService'
import { Link } from 'react-router-dom'

const ProfileMenu = () => {
  const currentUser = useSelector((state) => state.currentUser)
  const [menu, setMenu] = useState(false)
  const { t } = useTranslation()

  const toggle = () => {
    setMenu(!menu)
  }

  const signOut = () => {
    AuthService.signOut()
  }

  return (
    <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
      <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
        <img
          className="rounded-circle header-profile-user"
          src={`https://www.gravatar.com/avatar/${md5(currentUser?.email)}?d=identicon&s=48`}
          alt="Header Avatar"
        />{' '}
        <span className="d-none d-xl-inline-block ms-1">{`${currentUser.firstName} ${currentUser.lastName}`}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem href={`${process.env.REACT_APP_AUTH_APP_DOMAIN}`}>
          <i className="bx bx-user font-size-16 align-middle ms-1" /> {t('profile')}
        </DropdownItem>
        <DropdownItem href={`${process.env.REACT_APP_AUTH_APP_DOMAIN}security`}>
          <i className="mdi mdi-key-variant font-size-16 align-middle ms-1" /> {t('changePassword')}
        </DropdownItem>
        <DropdownItem tag={Link} to="/app/sna-config">
          <i className="bx bx-bell font-size-16 align-middle ms-1" /> Alerts
        </DropdownItem>
        <div className="dropdown-divider" />
        <DropdownItem onClick={signOut}>
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> {t('logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ProfileMenu
