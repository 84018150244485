import { Navbar, Collapse, Nav, NavItem } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import SourcesTypeSelect from './check/SourcesTypeSelect'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setStopCheck } from 'store/check/checkSlice'

const SearchBar = ({ navClass, search, placeholder, onSearch, value, isLoading }) => {
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(yup.object({ search: yup.string() })),
    defaultValues: { search: search ?? '' },
  })

  const { id } = useSelector((state) => state.check)
  const dispatch = useDispatch()

  const onSubmit = (data) => {
    // const rawQuery = data.search
    // const queryWords = rawQuery.trim().split(/\s+/g)
    // const query = queryWords.join('+')
    // let uri = '/app/search'
    // if (query !== '') {
    //   uri += `?q=${query}`
    // }
    // navigate(uri)
    onSearch(data)
  }

  useEffect(() => {
    setValue('search', value)
  }, [setValue, value])

  return (
    <>
      <Navbar expand="xs" fixed="top" className={`searchbar ${navClass}`}>
        <Collapse defaultOpen navbar>
          <Nav className="ms-3 mt-2 mb-sm-3 d-flex justify-content-between w-100">
            <NavItem>
              <Form onSubmit={handleSubmit(onSubmit)} className="d-flex">
                <div className="position-relative">
                  <Form.Control type="text" placeholder={placeholder} autoComplete="on" spellCheck="false" {...register('search')} />
                  {!isLoading && !id && <span className="bx bx-check-shield"></span>}
                  {isLoading && (
                    <span
                      className="bx bx-stop-circle stop"
                      style={{ fontSize: 22, color: 'red' }}
                      onClick={() => dispatch(setStopCheck(true))}></span>
                  )}
                  {!isLoading && id && (
                    <span className="bx bxs-caret-right-circle start" style={{ fontSize: 22 }} onClick={handleSubmit(onSubmit)}></span>
                  )}
                </div>
              </Form>
            </NavItem>
            <SourcesTypeSelect />
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

export default SearchBar
