import { Filters } from 'components/sna/FilterManager'
import { Post } from './snaApi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type SortOrder = 'asc' | 'desc'

export interface PostState {
  currentAlertId: string | undefined

  searchAfter: string | undefined
  order: SortOrder
  hasPagination: boolean

  filters: Filters<Post>[]

  selectedPost: number | undefined
  selectedPosts: number[] | undefined

  expandedPostId: number | undefined
  currentPostDocuments: Post | undefined
}

const initialState: PostState = {
  currentAlertId: undefined,

  searchAfter: undefined,
  order: 'desc',
  hasPagination: false,

  filters: [],

  selectedPost: undefined,
  selectedPosts: undefined,

  expandedPostId: undefined,
  currentPostDocuments: undefined,
}

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    selectAlert(state, action: PayloadAction<string>) {
      return { ...initialState, currentAlertId: action.payload, searchAfter: undefined }
    },
    goToPage(state, action: PayloadAction<{ from: string; order: SortOrder }>) {
      return {
        ...initialState,
        currentAlertId: state.currentAlertId,
        searchAfter: action.payload.from,
        order: action.payload.order,
        hasPagination: true,
      }
    },
    selectPost(state, action: PayloadAction<number>) {
      return { ...state, selectedPost: action.payload, selectedPosts: undefined }
    },
    setFilters(state, action: PayloadAction<Filters<Post>[]>) {
      return { ...state, filters: action.payload }
    },
    selectPosts(state, action: PayloadAction<number[]>) {
      return { ...state, selectedPost: undefined, selectedPosts: action.payload }
    },
    showPostAnalysis(state, action: PayloadAction<number>) {
      return { ...state, expandedPostId: action.payload }
    },
    showPostDocuments(state, action: PayloadAction<Post | undefined>) {
      return { ...state, currentPostDocuments: action.payload }
    },
  },
})

export const { selectAlert, selectPost, setFilters, selectPosts, showPostDocuments, showPostAnalysis, goToPage } = postSlice.actions
