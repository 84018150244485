import React from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const NotifModal = ({ show, onCloseClick, onConfirmClick, children, buttonName, onNavigateLoginClick }) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={show} toggle={onCloseClick} centered={true} className="max-width-20">
        <ModalBody className="py-2 px-3">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i className="mdi mdi-alert-circle-outline" style={{ fontSize: '3em', color: 'gray' }} />
                {children}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-1">
                <button
                  type="button"
                  className={buttonName === 'confirmDelete' ? 'btn btn-danger btn-xl me-2' : 'btn btn-info btn-xl me-2'}
                  onClick={onConfirmClick}>
                  {buttonName}
                </button>
                <button type="button" className="btn btn-secondary btn-xl me-2" onClick={onCloseClick}>
                  {t('cancel')}
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

NotifModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default NotifModal
