import { Button, Card, Collapse } from 'reactstrap'
import { isValid, parseISO, format } from 'date-fns'
import userLogo from '../../assets/images/twitter-user.png'

import PostAnalysis from './PostAnalysis'
import { selectPost, showPostAnalysis } from '../../store/sna/postSlice'
import { useDispatch } from 'react-redux'
import { truncateText } from 'utils/formatting'

const Post = ({ post, user: { name, username }, selected, expanded }) => {
  const dispatch = useDispatch()
  const polarity = post?.behavior?.sentimentPolarity?.sentiment

  const handleClick = (e) => {
    if (e.ctrlKey) window.open(`https://twitter.com/${username}/status/${post.id.split('twitter-')?.[1]}`, '_blank')
    else dispatch(selectPost(post?.id))
  }

  return (
    <Card className={`post p-3 pb-1 mb-2 ${selected ? 'selected' : ''} ${polarity?.toLowerCase()}`} id={post?.id}>
      <div className="polarity" />
      <div>
        <div className="d-flex">
          <img className="rounded-circle avatar-sm" width={30} height={27} src={userLogo} alt={`user-${post?.authorId}`} />
          <div className="ps-3 d-flex justify-content-between w-100">
            <div onClick={handleClick} style={{ cursor: 'pointer', minHeight: 58 }}>
              <span className="title">{name}</span>
              <p>{username && `@${username}`}</p>
            </div>

            <div className="d-flex align-items-center" style={{ height: '20px' }}>
              <span
                className="ms-2 px-1 fw-bold font-size-12"
                style={{
                  backgroundColor: 'rgb(244, 248, 254)',
                  border: '1px solid rgb(205, 220, 252)',
                  color: 'rgb(3, 81, 240)',
                  borderRadius: '3px',
                  width: 'max-content',
                }}>
                <i className="fa fa-retweet" aria-hidden="true"></i> {post?.nbShared}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="post-content">{truncateText(post?.text, 1000)}</div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {' '}
          <span className="time">{isValid(parseISO(post?.createdAt)) && format(parseISO(post?.createdAt), 'dd-MM-yyyy HH:mm')}</span>{' '}
        </div>
        <div>
          {/* TODO expose analysed field on backend and use it  */}
          {polarity && (
            <Button className="analysis-btn" onClick={() => dispatch(showPostAnalysis(expanded ? undefined : post?.id))}>
              Analysis
            </Button>
          )}
        </div>
      </div>

      <Collapse isOpen={expanded}>
        <hr />
        {expanded && post?.behavior && <PostAnalysis post={post} showDetails={expanded} />}
      </Collapse>
    </Card>
  )
}

export default Post
