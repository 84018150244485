import { Tooltip } from 'reactstrap'
import UserCard from './UserCard'

const UserTooltip = ({ target, user }) => {
  return (
    <Tooltip placement="top" isOpen={!!user} target={target} className="circle-tooltip">
      <UserCard user={user} />
    </Tooltip>
  )
}

export default UserTooltip
