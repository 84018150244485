import { Dropdown, DropdownMenu, DropdownItem, FormGroup, Label } from 'reactstrap'
import React, { useState } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

type Props = {
  filters: { name: string; options: string[] }[]
  register: UseFormRegister<FieldValues>
  children: React.ReactNode
}
const AllOptionsDropdown = (props: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} className="d-inline-block">
      {props.children}
      <DropdownMenu direction="left" className="custom-dropdown" flip={true} end={true}>
        <div className="d-flex container">
          {props.filters.map((filter) => (
            <div className="filter-container" key={`${filter.name}`}>
              <DropdownItem header>{filter.name}</DropdownItem>
              <div style={{ minWidth: 170 }}>
                <FormGroup>
                  {filter.options.map((option) => {
                    return (
                      <div className="input-container" key={`${filter.name}${option}`}>
                        <input
                          type={filter.name === 'Interval' ? 'radio' : 'checkbox'}
                          value={option}
                          id={option}
                          {...props.register('custom-filters.' + filter.name)}
                        />
                        <Label for={option}>{option?.replaceAll('_', ' ').toLowerCase()}</Label>
                      </div>
                    )
                  })}
                </FormGroup>
              </div>
            </div>
          ))}
        </div>
        <DropdownItem className="text-center">
          <span className="save-btn" style={{ width: 100 }}>
            save
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AllOptionsDropdown
