import React from 'react'
import { useTable, useSortBy, useGlobalFilter } from 'react-table'
import { Input, Table } from 'reactstrap'

const AssetTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
  )

  const { globalFilter } = state

  return (
    <div>
      <Input type="text" placeholder="Search by name" value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} />

      {/* Other filters can be added similarly */}

      <Table {...getTableProps()} responsive>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default AssetTable
