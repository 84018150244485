import { Dropdown, DropdownMenu, DropdownItem, FormGroup, Label } from 'reactstrap'
import React, { useState } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

type Props = {
  header: string
  options: any[]
  defaultChecked?: any
  keys?: { id: string; label: string }
  register: UseFormRegister<FieldValues>
  children: React.ReactNode
}
const OptionsDropdown = (props: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      className="d-inline-block"
      style={{ width: '100%', height: '100%' }}>
      {props.children}
      <DropdownMenu direction="left" className="custom-dropdown main" flip={true} end={true}>
        <div className="filter-container">
          <DropdownItem header>{props.header}</DropdownItem>
          <div style={{ minWidth: 170 }}>
            <FormGroup>
              {props.options.map((option) => {
                const id = props.keys ? option[props.keys?.id] : option
                const label = props.keys ? option[props.keys?.label] : option
                return (
                  <div className="input-container" key={`${props.header}${id}`}>
                    <input
                      type={props?.header === 'Interval' ? 'radio' : 'checkbox'}
                      value={id}
                      id={id}
                      defaultChecked={props?.defaultChecked === id}
                      {...props.register(props.header)}
                    />
                    <Label for={id}>{label}</Label>
                  </div>
                )
              })}
            </FormGroup>
          </div>
          <DropdownItem className="save-btn" onClick={() => setDropdownOpen(false)}>
            save
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default OptionsDropdown
