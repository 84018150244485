import { nerColors } from '../../pages/app/CheckTextData'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import uniqWith from 'lodash/uniqWith'

const TOP_N_ENTITIES = 3
const TOP_N_TOPICS = 2

const NerOccurrence = ({ entity, color, text, id, occurrence }) => {
  return (
    <>
      <span className="p-1 font-size-10 fw-bold text-white rounded shadow" id={id} style={{ backgroundColor: color }}>
        {text} <Badge className="ms-1 bg-white font-size-11 fw-bold rounded text-black">{occurrence}</Badge>
      </span>
      <UncontrolledTooltip placement="top" target={id}>
        {entity}
      </UncontrolledTooltip>
    </>
  )
}

const getTopNEntities = (entities) => {
  const occurrences = entities?.reduce((acc, curr) => {
    acc[`${curr?.text}${curr?.entity}`] ? ++acc[`${curr?.text}${curr?.entity}`] : (acc[`${curr?.text}${curr?.entity}`] = 1)
    return acc
  }, {})
  const sortedByOccurrenceAndScore = entities
    ?.map((ner) => ({ ...ner, occurrence: occurrences[`${ner?.text}${ner?.entity}`] }))
    ?.sort((a, b) => b.occurrence - a.occurrence || b.score - a.score)
  return uniqWith(sortedByOccurrenceAndScore, (a, b) => `${a?.text}${a?.entity}` === `${b?.text}${b?.entity}`)?.splice(0, TOP_N_ENTITIES)
}

const getTopNTopics = (topics) => {
  const occurrences = topics?.reduce((acc, curr) => {
    acc[curr?.topic] ? ++acc[curr?.topic] : (acc[curr?.topic] = 1)
    return acc
  }, {})
  const sortedByOccurrenceAndScore = topics
    ?.filter((topic) => topic?.score >= 0.4)
    ?.map((item) => ({ ...item, occurrence: occurrences[item?.topic] }))
    ?.sort((a, b) => b.occurrence - a.occurrence || b.score - a.score)
  return uniqWith(sortedByOccurrenceAndScore, (a, b) => a?.topic === b?.topic)?.splice(0, TOP_N_TOPICS)
}

const TopKAnalysis = ({ posts }) => {
  const entities = posts?.flatMap((post) => post?.entities ?? [])
  const topics = posts?.flatMap((post) => post?.topics ?? [])
  return (
    <div className="ms-2 d-flex flex-wrap align-items-start" style={{ gap: 5 }}>
      {!!entities?.length &&
        getTopNEntities(entities)?.map((ner, idx) => (
          <NerOccurrence
            key={'ner-topK-' + idx}
            entity={ner.entity}
            color={nerColors[ner.entity]}
            text={ner.text}
            occurrence={ner?.occurrence}
            id={'ner-topK' + idx}
          />
        ))}
      {!!topics?.length &&
        getTopNTopics(topics)?.map((t, idx) => {
          return (
            <span
              key={'topic-topK-' + idx}
              className="p-1 font-size-11 lh-1 fw-bold rounded"
              style={{
                backgroundColor: 'rgb(244, 248, 254)',
                border: '1px solid rgb(205, 220, 252)',
                color: 'rgb(3, 81, 240)',
              }}>
              {t.topic.replaceAll('_', ' ')}
              <Badge className="ms-1 bg-secondary font-size-11 fw-bold rounded text-white">{t?.occurrence}</Badge>
            </span>
          )
        })}
    </div>
  )
}

export default TopKAnalysis
