import * as React from 'react'
import ReactHighlightWords from 'react-highlight-words'

export interface HighLightProps {
  text?: string
  highlights: string[]
}

export const HighLight = ({ text, highlights }: HighLightProps) => {
  return (
    <ReactHighlightWords
      highlightClassName="highlight"
      searchWords={highlights as string[]}
      autoEscape
      // highlightTag={({ children: }) => <strong>{children}</strong>}
      textToHighlight={text!}
    />
  )
}
