import React, { useMemo, useState } from 'react'
import * as d3 from 'd3'
import Form from 'react-bootstrap/Form'
import { FormGroup, Input } from 'reactstrap'
import { default as mapper } from '../../utils/analysisMapper'

import { useChartDimensions } from '../../contexts/ChartProvider'
import UserTooltip from './UserTooltip'

const chartSettings = {
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,
  height: 310,
}

const comColors = [
  '#EF5350',
  '#EC407A',
  '#BA68C8',
  '#6A1B9A',
  '#4527A0',
  '#651FFF',
  '#F57F17',
  '#536DFE',
  '#0097A7',
  '#4DB6AC',
  '#D4E157',
  '#607D8B',
  '#00BFA5',
  '#F57F17',
  '#03a9f4',
  '#FF8F00',
  '#0288D1',
  '#5d4037',
]

const UsersNetwork = ({ users, posts, selectedPosts }) => {
  const [ref, dms] = useChartDimensions(chartSettings)
  const [displayCom, setDisplayCom] = useState(false)
  const [currentCircle, setCurrentCircle] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [isSharedCentrality, setSharedCentrality] = useState(true)

  const xScale = d3.scaleLinear().domain([-1.05, 1.05]).range([0, dms.boundedWidth])
  const yScale = d3.scaleLinear().domain([-1.05, 1.05]).range([0, dms.boundedHeight])
  const rScale = d3
    .scaleLinear()
    .domain(d3.extent(users, (u) => u.centrality))
    .range([3, 13])

  const onPostHover = (user, prefix) => {
    setCurrentUser(user)
    setCurrentCircle(`${prefix}-${getLastItem(user?.postId, '-')}`)
  }

  const onPostLeave = () => {
    setCurrentUser(null)
    setCurrentCircle(null)
  }

  // note: list of users that tweet or retweet filtred posts
  const filtredUsers = useMemo(() => {
    if (users?.length && posts?.length)
      return posts
        ?.map((post) => ({ postId: post?.id, ...users?.find((user) => user?.id === post?.authorId) }))
        .filter((u) => u !== undefined)
    return []
  }, [posts, users])

  const getUserById = (id) => users?.find((user) => user?.id === id)

  const getLastItem = (str, delimiter) => ((arr) => arr?.[arr?.length - 1])(str?.split(delimiter))

  return (
    <>
      <div className="chart__network__wrapper" ref={ref}>
        {currentCircle && <UserTooltip user={currentUser} target={currentCircle} />}
        <svg width={dms.width} height={dms.height}>
          <g key="users">
            {filtredUsers
              ?.filter((u) => u?.posX !== null)
              .map((u) => {
                return (
                  <circle
                    id={`circle-${getLastItem(u?.postId, '-')}`}
                    key={`circle-${getLastItem(u?.postId, '-')}`}
                    cx={xScale(u.posX)}
                    cy={yScale(u.posY)}
                    r={rScale(isSharedCentrality ? u.sharedCentrality : u.sharingCentrality)}
                    fill={displayCom ? comColors[u.communityIdx % comColors.length] : '#cccccc'}
                    stroke={currentUser?.twitterId === u.twitterId ? '#2a3135' : '#ffffff'}
                    onMouseMove={() => onPostHover(u, 'circle')}
                    onMouseLeave={onPostLeave}
                  />
                )
              })}
          </g>
          <g key="selected-users">
            {selectedPosts?.map((p) => {
              const polarity = p?.behavior?.sentimentPolarity?.sentiment
              const color = mapper.getPolarityColor(polarity)
              const user = {
                ...getUserById(p?.authorId),
                postId: p?.id,
                original: true,
              }
              const sharedUsers = posts
                ?.filter((post) => post?.sharedFrom === p?.id)
                .map((post) => ({ ...getUserById(post?.authorId), postId: post?.id }))
              return (
                <React.Fragment key={`selected-users-${p?.id}`}>
                  {sharedUsers?.concat(user)?.map((u) => (
                    <circle
                      id={`circle-cascade-${getLastItem(u?.postId, '-')}`}
                      key={`circle-cascade-${getLastItem(u?.postId, '-')}`}
                      cx={xScale(u?.posX)}
                      cy={yScale(u?.posY)}
                      r={rScale(isSharedCentrality ? u.sharedCentrality : u.sharingCentrality)}
                      fill={color}
                      stroke={u?.original ? '#2a3135' : 'transparent'}
                      strokeWidth={u?.original ? 3 : 0}
                      onMouseMove={() => onPostHover(u, 'circle-cascade')}
                      onMouseLeave={onPostLeave}
                    />
                  ))}
                </React.Fragment>
              )
            })}
          </g>
        </svg>
      </div>
      <hr />
      <Form className="d-flex" style={{ gap: 10 }}>
        <FormGroup switch>
          <Input type="switch" role="switch" checked={displayCom} onChange={() => setDisplayCom(!displayCom)} />
          <p className="fw-bold">Display communities</p>
        </FormGroup>
        <FormGroup switch>
          <Input type="switch" role="switch" checked={isSharedCentrality} onChange={() => setSharedCentrality(!isSharedCentrality)} />
          <p className="fw-bold">Shared</p>
        </FormGroup>
      </Form>
    </>
  )
}

export default UsersNetwork
