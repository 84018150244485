import { splitApi as api } from './splitApi'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    factcheck: build.mutation<FactcheckApiResponse, FactcheckApiArg>({
      query: (queryArg) => ({ url: `/check`, method: 'POST', body: queryArg.body }),
    }),
    query: build.mutation<QueryApiResponse, QueryApiArg>({
      query: (queryArg) => ({ url: `/check/query`, method: 'POST', body: queryArg.body }),
    }),
    result: build.query<ResultApiResponse, ResultApiArg>({
      query: (queryArg) => ({ url: `/check/result`, params: { input: queryArg.input, waitFor: queryArg.waitFor } }),
    }),
    postVerifyCorpus: build.mutation<PostVerifyCorpusApiResponse, PostVerifyCorpusApiArg>({
      query: (queryArg) => ({ url: `/verify/corpus`, method: 'POST', body: queryArg.body }),
    }),
    postVerifyFilter: build.mutation<PostVerifyFilterApiResponse, PostVerifyFilterApiArg>({
      query: (queryArg) => ({ url: `/verify/filter`, method: 'POST', body: queryArg.body }),
    }),
    postAnalyzeEntities: build.mutation<PostAnalyzeEntitiesApiResponse, PostAnalyzeEntitiesApiArg>({
      query: (queryArg) => ({ url: `/analyze/entities`, method: 'POST', body: queryArg.body }),
    }),
    postAnalyzeSentiments: build.mutation<PostAnalyzeSentimentsApiResponse, PostAnalyzeSentimentsApiArg>({
      query: (queryArg) => ({ url: `/analyze/sentiments`, method: 'POST', body: queryArg.body }),
    }),
    postAnalyzePolarity: build.mutation<PostAnalyzePolarityApiResponse, PostAnalyzePolarityApiArg>({
      query: (queryArg) => ({ url: `/analyze/polarity`, method: 'POST', body: queryArg.body }),
    }),
    classification: build.mutation<ClassificationApiResponse, ClassificationApiArg>({
      query: (queryArg) => ({ url: `/analyze/classify/buster`, method: 'POST', body: queryArg.body }),
    }),
    postAnalyzeClassifyIptc: build.mutation<PostAnalyzeClassifyIptcApiResponse, PostAnalyzeClassifyIptcApiArg>({
      query: (queryArg) => ({ url: `/analyze/classify/iptc`, method: 'POST', body: queryArg.body }),
    }),
    postAnalyzeClassifyGarm: build.mutation<PostAnalyzeClassifyGarmApiResponse, PostAnalyzeClassifyGarmApiArg>({
      query: (queryArg) => ({ url: `/analyze/classify/garm`, method: 'POST', body: queryArg.body }),
    }),
    postAnalyzeUrl: build.mutation<PostAnalyzeUrlApiResponse, PostAnalyzeUrlApiArg>({
      query: (queryArg) => ({ url: `/analyze/url`, method: 'POST', body: queryArg.body }),
    }),
    postAnalyzeHighlight: build.mutation<PostAnalyzeHighlightApiResponse, PostAnalyzeHighlightApiArg>({
      query: (queryArg) => ({ url: `/analyze/highlight`, method: 'POST', body: queryArg.body }),
    }),
    summarization: build.mutation<SummarizationApiResponse, SummarizationApiArg>({
      query: (queryArg) => ({ url: `/analyze/summarization`, method: 'POST', body: queryArg.body }),
    }),
    postSearchDocuments: build.mutation<PostSearchDocumentsApiResponse, PostSearchDocumentsApiArg>({
      query: (queryArg) => ({ url: `/search/documents`, method: 'POST', body: queryArg.body }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as nlpApi }
export type FactcheckApiResponse = /** status 200 OK */ FactCheckAnalysisResponse
export type FactcheckApiArg = {
  /** Input text */
  body: {
    claim?: string
    publisherCountry?: string
    userLanguage?: string
    preprocess?: boolean
    processHighlight?: boolean
    processReport?: boolean
    publicationStartDate?: string
    publicationEndDate?: string
  }
}

export type QueryApiResponse = /** status 200 OK */ Uuid
export type QueryApiArg = {
  /** Input text */
  body: {
    claim?: string
    publisherCountry?: string
    userLanguage?: string
    preprocess?: boolean
    processHighlight?: boolean
    processReport?: boolean
    publicationStartDate?: string
    publicationEndDate?: string
  }
}
export type PostVerifyCorpusApiResponse = /** status 200 OK */ FactCheckCorpusAnalysisResponse
export type PostVerifyCorpusApiArg = {
  body: {
    claim?: string
    corpusList?: object[]
  }
}
export type PostVerifyFilterApiResponse = /** status 200 OK */ FactCheckAnalysisResponse
export type PostVerifyFilterApiArg = {
  body: {
    claim?: string
    filters?: {
      keywords?: string[]
      organisations?: string[]
      organizationType?: OrganizationKindEnum[]
      organizationTheme?: string[]
      authors?: string[]
      documentBusterCategories?: string[]
      documentIptcCategories?: DocumentIptcCategories[]
      language?: string
      publisherCountry?: string
      temporality?: {
        start?: string
        end?: string
      }
    }
  }
}
export type PostAnalyzeEntitiesApiResponse = /** status 200 OK */ NamedEntitiesAnalysisResponse
export type PostAnalyzeEntitiesApiArg = {
  /** Input text */
  body: string
}
export type ResultApiResponse = /** status 200 OK */ FactCheckAnalysisResponse
export type ResultApiArg = {
  input: Uuid
  waitFor: WaitOption
}
export type PostAnalyzeSentimentsApiResponse = /** status 200 OK */ BehaviorAnalysisResponse
export type PostAnalyzeSentimentsApiArg = {
  /** Input text */
  body: string
}
export type PostAnalyzePolarityApiResponse = /** status 200 OK */ EntityPolarityAnalysisResponse
export type PostAnalyzePolarityApiArg = {
  /** Input text */
  body: {
    text?: string
    entity?: string
  }
}
export type ClassificationApiResponse = /** status 200 OK */ BusterTopicClassifierResponse
export type ClassificationApiArg = {
  /** Input text */
  body: string
}
export type PostAnalyzeClassifyIptcApiResponse = /** status 200 OK */ IptcTopicClassifierResponse
export type PostAnalyzeClassifyIptcApiArg = {
  /** Input text */
  body: string
}
export type PostAnalyzeClassifyGarmApiResponse = /** status 200 OK */ GarmClassifierResponse
export type PostAnalyzeClassifyGarmApiArg = {
  /** Input text */
  body: string
}
export type PostAnalyzeUrlApiResponse = /** status 200 OK */ UrlAnalysisResponse
export type PostAnalyzeUrlApiArg = {
  /** Input text */
  body: string
}
export type PostAnalyzeHighlightApiResponse = /** status 200 OK */ HighlightKeywordsResponse
export type PostAnalyzeHighlightApiArg = {
  /** Input text */
  body: {
    referenceText?: string
    texts?: string[]
  }
}
export type SummarizationApiResponse = /** status 200 OK */ SummarizationResponse
export type SummarizationApiArg = {
  body: {
    texts?: string[]
  }
}
export type PostSearchDocumentsApiResponse = /** status 200 OK */ SearchResponse
export type PostSearchDocumentsApiArg = {
  body: {
    keyphrases?: string[]
    naturalLanguageText?: string
    filters?: {
      languages?: string[]
      publisherCountry?: string[]
      temporality?: {
        start?: string
        end?: string
      }
    }
  }
}
export type Uuid = string
export type OrganizationKindEnum =
  | 'ACADEMIC_JOURNAL'
  | 'ASSOCIATION'
  | 'COMPANY'
  | 'DEVOLVED_ADMINISTRATION'
  | 'EMBASSY_OR_CONSULATE'
  | 'ENCYCLOPEDIA'
  | 'EUROPEAN_ORGANIZATION'
  | 'FACT_CHECKING_ORGANIZATION'
  | 'INDEPENDENT_AUTHORITY'
  | 'INTERNATIONAL_DELEGATION'
  | 'INTERNATIONAL_ORGANIZATION'
  | 'LEARNED_SOCIETY'
  | 'LOCAL_ADMINISTRATION'
  | 'MINISTERIAL_DEPARTMENT'
  | 'NA'
  | 'NATIONAL_INSTITUTION'
  | 'NATIONAL_JURISDICTION'
  | 'NEWS_AGENCY'
  | 'NEWS_MEDIA'
  | 'NON_GOVERNMENTAL_ORGANIZATION'
  | 'NON_PROFIT_ORGANIZATION'
  | 'OPEN_DATABASE'
  | 'POLITICAL_PARTY'
  | 'PRE_PRINT_SERVER'
  | 'PUBLIC_BODY'
  | 'PUBLIC_CORPORATION'
  | 'PUBLISHING_COMPANY'
  | 'STATISTICAL_OFFICE'
  | 'STOCK_EXCHANGE'
  | 'TRADE_UNION'
  | 'UNIVERSITY_PRESS'
  | 'UNRECOGNIZED'
export type Organization = {
  name?: string
  kind?: OrganizationKindEnum
  country?: string
}
export type Passage = {
  text?: string
}
export type DocumentKindEnum = 'NA' | 'TABLE' | 'TEXT' | 'UNSPECIFIED'
export type VerdictLabelEnum = 'CONFIRMS' | 'DISCUSSES' | 'NA' | 'REFUTES' | 'UNDEF' | 'UNRELATED'
export type Document = {
  id?: string
  title?: string
  publisher?: Organization
  dateCreated?: string
  dateModified?: string
  url?: string
  language?: string
  matchingScore?: string
  passages?: Passage[]
  kind?: DocumentKindEnum
  verdictLabel?: VerdictLabelEnum
}
export type GlobalVerdictLabelEnum =
  | 'CANNOT_SAY'
  | 'CONFIRMED'
  | 'CONTROVERSIAL'
  | 'NA'
  | 'RATHER_CONFIRMED'
  | 'RATHER_REFUTED'
  | 'REFUTED'
  | 'UNDEF'
  | 'UNVERIFIABLE'
export type GlobalVerdictLabel = {
  label?: GlobalVerdictLabelEnum
  score?: string
}
export type FactCheckAnalysisResponse = {
  requestId?: Uuid
  claim?: string
  curatedClaim?: string
  dateVerified?: string
  highlight?: string[]
  report?: string
  documents?: Document[]
  globalVerdictLabel?: GlobalVerdictLabel
}
export type WaitOption = 'DOCUMENTS' | 'VERDICT' | 'REPORT' | 'HIGHLIGHT'

export type FactCheckCorpusAnalysisResponse = {
  requestId?: Uuid
  claim?: string
  documents?: {
    id?: string
    matchingScore?: number
    verdictLabel?: VerdictLabelEnum
  }[]
}
export type DocumentIptcCategories =
  | 'HEALTH'
  | 'CONFLICT'
  | 'JUSTICE'
  | 'POLITICS'
  | 'SOCIETY'
  | 'FINANCE'
  | 'LABOUR'
  | 'ENVIRONMENT'
  | 'CULTURE'
  | 'EDUCATION'
  | 'HUMANITY'
  | 'WEATHER'
  | 'LIFESTYLE'
  | 'SPORT'
  | 'SciTech'
  | 'RELIGION'
  | 'DISASTER'
export type NamedEntityEnum =
  | 'PERSON'
  | 'NORP'
  | 'FACILITY'
  | 'ORGANIZATION'
  | 'GPE'
  | 'LOCATION'
  | 'PRODUCT'
  | 'EVENT'
  | 'WORK_OF_ART'
  | 'LAW'
  | 'LANGUAGE'
  | 'DATE'
  | 'TIME'
  | 'PERCENT'
  | 'MONEY'
  | 'QUANTITY'
  | 'ORDINAL'
  | 'CARDINAL'
export type NamedEntityText = {
  entity?: NamedEntityEnum
  text?: string
  score?: number
  surfaceFrom?: string
  start?: number
  end?: number
}
export type NamedEntitiesAnalysisResponse = {
  requestId?: Uuid
  claim?: string
  texts?: NamedEntityText[]
}
export type SentimentEnum = 'POSITIVE' | 'NEUTRAL' | 'NEGATIVE' | 'MIX_POSITIVE_NEGATIVE' | 'UNDEF'
export type SentimentPolarity = {
  sentiment?: SentimentEnum
  score?: number
}
export type EmotionEnum = 'ANGER' | 'DISGUST' | 'FEAR' | 'JOY' | 'NEUTRAL' | 'SADNESS' | 'SURPRISE' | 'UNDEF'
export type EmotionScore = {
  emotion?: EmotionEnum
  score?: number
}
export type ToxicityEnum = 'TOXICITY' | 'SEVERE_TOXICITY' | 'OBSCENE' | 'IDENTITY_ATTACK' | 'INSULT' | 'THREAT' | 'SEXUAL_EXPLICIT'
export type ToxicityScore = {
  label?: ToxicityEnum
  score?: number
}
export type BehaviorAnalysisResponse = {
  requestId?: Uuid
  claim?: string
  sentimentPolarity?: SentimentPolarity
  emotions?: EmotionScore[]
}
export type EntityPolarityAnalysisResponse = {
  requestId?: Uuid
  text?: string
  entity?: string
  sentiment?: SentimentEnum
}
export type BusterTopicEnum =
  | 'PRODUCTION_MANUFACTURING'
  | 'SPORTS'
  | 'WEATHER_GEOGRAPHY'
  | 'SCIENTIFIC_ARTICLES'
  | 'GEOPOLITICS_WAR'
  | 'GENERAL_INFORMATION'
  | 'PUBLIC_HEALTH'
  | 'MEDICAL_STUDIES'
  | 'BUSINESS_CONSULTING'
  | 'LEGAL_INVESTMENTS'
  | 'ECONOMY'
  | 'BASEBALL'
  | 'PHILOSOPHY_RELIGIONS_WELLBEING'
  | 'ELECTIONS_AGREEMENTS'
export type BusterTopicClassifierScore = {
  topic?: BusterTopicEnum
  score?: number
}
export type BusterTopicClassifierResponse = {
  requestId?: Uuid
  claim?: string
  topics?: BusterTopicClassifierScore[]
}
export type IptcTopicEnum =
  | 'SPORT'
  | 'CRIME_LAW_JUSTICE'
  | 'ENVIRONMENTAL_ISSUE'
  | 'SOCIAL_ISSUE'
  | 'ARTS_CULTURE_ENTERTAINMENT'
  | 'POLITICS'
  | 'HEALTH'
  | 'SCIENCE_TECHNOLOGY'
  | 'ECONOMY_BUSINESS_FINANCE'
  | 'EDUCATION'
  | 'LIFESTYLE_LEISURE'
  | 'DISASTER_ACCIDENT'
  | 'HUMAN_INTEREST'
  | 'UNREST_CONFLICTS_WAR'
  | 'RELIGION_BELIEF'
  | 'WEATHER'
  | 'LABOUR'
export type IptcTopicClassifierScore = {
  topic?: IptcTopicEnum
  score?: number
}
export type IptcTopicClassifierResponse = {
  requestId?: Uuid
  claim?: string
  topics?: IptcTopicClassifierScore[]
}
export type GarmTopicEnum =
  | 'UNSPECIFIED'
  | 'ADULT_EXPLICIT_SEXUAL_CONTENT'
  | 'ARMS_AMMUNITION'
  | 'CRIME_HARMFUL_ACTS_HUMAN_RIGHT_VIOLATIONS'
  | 'DEATH_INJURY_OR_MILITARY_CONFLICT'
  | 'DEBATED_SENSITIVE_SOCIAL_ISSUES'
  | 'HATE_SPEECH_AND_ACTS_OF_AGGRESSION'
  | 'ILLEGAL_DRUGS_TOBACCO_ECIGARETTES_VAPING_ALCOHOL'
  | 'OBSCENITY_AND_PROFANITY'
  | 'ONLINE_PIRACY'
  | 'SPAM_OR_HARMFUL_CONTENT'
  | 'TERRORISM'
  | 'NA'
export type GarmClassifierResponse = {
  requestId?: Uuid
  text?: string
  topics?: GarmTopicEnum[]
}
export type UrlSafetyEnum = 'UNSPECIFIED' | 'HARMLESS' | 'UNKNOWN' | 'SUSPICIOUS' | 'MALICIOUS'
export type UrlInfo = {
  detectedUrl?: string
  targetUrl?: string
  domain?: string
  urlSafety?: UrlSafetyEnum
}
export type UrlAnalysisResponse = {
  requestId?: Uuid
  urls?: UrlInfo[]
}
export type HighlightKeywordsResponse = {
  requestId?: Uuid
  matchingKeywords?: string[]
}
export type SummarizationResponse = {
  requestId?: Uuid
  texts?: string[]
  summarizedText?: string
}
export type DocumentPassage = {
  id?: string
  text?: string
  dateProcessed?: string
  orderId?: number
}
export type OrganizationType = {
  name?: string
  category?: string
}
export type OrganizationInfo = {
  name?: string
  nameEn?: string
  country?: string
  type?: OrganizationType
}
export type CreativeWork = {
  id?: string
  dateProcessed?: string
  name?: string
  passages?: DocumentPassage[]
  datePublished?: string
  text?: string
  headline?: string
  url?: string
  authors?: string[]
  publisher?: OrganizationInfo
  nlpAnalysis?: string
}
export type SearchResponse = {
  requestId?: Uuid
  keyphrases?: string[]
  naturalLanguageText?: string
  sources?: CreativeWork[]
}
export const {
  useFactcheckMutation,
  usePostVerifyCorpusMutation,
  usePostVerifyFilterMutation,
  usePostAnalyzeEntitiesMutation,
  useQueryMutation,
  useResultQuery,
  useLazyResultQuery,
  usePostAnalyzeSentimentsMutation,
  usePostAnalyzePolarityMutation,
  useClassificationMutation,
  usePostAnalyzeClassifyIptcMutation,
  usePostAnalyzeClassifyGarmMutation,
  usePostAnalyzeUrlMutation,
  usePostAnalyzeHighlightMutation,
  useSummarizationMutation,
  usePostSearchDocumentsMutation,
} = injectedRtkApi
