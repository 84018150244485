import { Link } from 'react-router-dom'
import { Collapse } from 'reactstrap'

const Navbar = () => {
  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
          <Collapse isOpen className="navbar-collapse" id="topnav-menu-content">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle arrow-none" to="/app/search/articles/list">
                  <i className="bx bx-search-alt me-2" />
                  Search
                </Link>
              </li>

              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/app/search"
                >
                  <i className="bx bx-search-alt me-2" />
                  Enregistrements
                </Link>
              </li> */}

              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle arrow-none" to="/app/users">
                  <i className="bx bx-user me-2" />
                  Users
                </Link>
              </li>
            </ul>
          </Collapse>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
