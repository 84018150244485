import { Tooltip } from 'reactstrap'
import { useState } from 'react'
import React from 'react'

const TooltipComponent = ({ text, children, id, position }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <div>
      <Tooltip isOpen={tooltipOpen} target={id} toggle={toggle} placement={position}>
        {text}
      </Tooltip>
    </div>
  )
}

export default TooltipComponent
