import React, { useState } from 'react'
import { Table, Input, Button, Form, Collapse } from 'reactstrap'

const NotificationsTable = () => {
  const [rows, setRows] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const handleAddRow = (e) => {
    e.preventDefault()
    const newRow = {
      polarite: '',
      verdict: '',
      viralite: '',
      threshold: '',
      periodicity: '',
    }

    setRows([...rows, newRow])
  }

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows]
    updatedRows.splice(index, 1)
    setRows(updatedRows)
  }

  const handleEditRow = (index, field, value) => {
    const updatedRows = [...rows]
    updatedRows[index][field] = value
    setRows(updatedRows)
  }

  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="mt-3">
      <h5 color="primary" onClick={toggleCollapse} style={{ marginBottom: '1rem' }}>
        Notification rules
      </h5>
      <Collapse isOpen={isOpen}>
        <Form onSubmit={handleAddRow}>
          <Table>
            <thead>
              <tr>
                <th>Polarité</th>
                <th>Verdict</th>
                <th>Viralité</th>
                <th>Threshold</th>
                <th>Periodicity</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Input type="select" value={row.polarite} onChange={(e) => handleEditRow(index, 'polarite', e.target.value)}>
                      <option value="">Select</option>
                      <option value="Positive">Positive</option>
                      <option value="Negative">Negative</option>
                      <option value="Neutral">Neutral</option>
                    </Input>
                  </td>
                  <td>
                    <Input type="select" value={row.verdict} onChange={(e) => handleEditRow(index, 'verdict', e.target.value)}>
                      <option value="">Select</option>
                      <option value="True">True</option>
                      <option value="False">False</option>
                      <option value="Controversial">Controversial</option>
                    </Input>
                  </td>
                  <td>
                    <Input type="select" value={row.viralite} onChange={(e) => handleEditRow(index, 'viralite', e.target.value)}>
                      <option value="">Select</option>
                      <option value="Importante">Importante</option>
                      <option value="Moyenne">Moyenne</option>
                      <option value="Faible">Faible</option>
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="number"
                      placeholder="Count"
                      value={row.threshold}
                      onChange={(e) => handleEditRow(index, 'threshold', e.target.value)}
                    />
                  </td>
                  <td>
                    <Input type="select" value={row.periodicity} onChange={(e) => handleEditRow(index, 'periodicity', e.target.value)}>
                      <option value="">Select</option>
                      <option value="10min">10min</option>
                      <option value="heure">heure</option>
                      <option value="journée">journée</option>
                      <option value="semaine">semaine</option>
                      <option value="mois">mois</option>
                    </Input>
                  </td>
                  <td>
                    <Button color="danger" onClick={() => handleDeleteRow(index)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button color="primary" onClick={handleAddRow}>
            Add Row
          </Button>
        </Form>
      </Collapse>
    </div>
  )
}

export default NotificationsTable
