export const SUPPORTED_LANGUAGES_CODES = [
  'ar',
  'bg',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fa',
  'fi',
  'fr',
  // 'fr-ca', FIXME current lib dosent support this code
  'gl',
  'gu',
  'he',
  'hi',
  'hr',
  'hu',
  'hy',
  'id',
  'it',
  'ja',
  'ka',
  'ko',
  'ku',
  'lt',
  'lv',
  'mk',
  'mn',
  'mr',
  'ms',
  'my',
  'nb',
  'nl',
  'pl',
  'pt',
  // 'pt-br',
  'ro',
  'ru',
  'sk',
  'sl',
  'sq',
  'sr',
  'sv',
  'th',
  'tr',
  'uk',
  'ur',
  'vi',
  'zh',
  // 'zh-cn',
  // 'zh-tw',
]
