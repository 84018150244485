import React from 'react'

type Props = {
  percent: string
  color: string
}

const ProgressBar = ({ percent, color }: Props) => (
  <div
    style={{
      border: '1px solid #ccc',
      width: '150px',
      height: '20px',
      position: 'relative',
    }}>
    <div
      style={{
        height: '100%',
        width: `${percent}%`,
        backgroundColor: color,
        position: 'absolute',
        top: 0,
        left: 0,
        fontWeight: 700,
        color: '#fff',
        paddingLeft: '5px',
      }}>
      {percent} %
    </div>
  </div>
)

export default ProgressBar
