import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MetisMenu from '@metismenu/react'

import SimpleBar from 'simplebar-react'

const SidebarContent = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const currentUser = useSelector((state) => state.currentUser)

  useEffect(() => {
    const ul = document.getElementById('sidebar-menu')
    const items = ul.getElementsByTagName('a')

    for (let i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        items[i].classList.add('mm-active')
      } else {
        items[i].classList.remove('mm-active')
      }
    }
  }, [location])

  return (
    <SimpleBar className="h-100">
      <div id="sidebar-menu">
        <MetisMenu className="ps-0">
          <li className="menu-title">{t('app')}</li>
          <li>
            <Link to="/app/dashboard">
              <i className="bx bx-home-circle"></i>
              <span>{t('dashboard')}</span>
            </Link>
          </li>
          <li>
            <Link to="/app/check" className="has-arrow">
              <i className="bx bx-check-shield" />
              <span>{t('check')}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/app/check/claim">{t('claim')}</Link>
              </li>
              <li>
                <Link to="/app/check/text">{t('text')}</Link>
              </li>
              {/* <li>
                <Link to="/app/check/image">{t('image')}</Link>
              </li> */}
            </ul>
          </li>
          {/* <li>
	      <Link to="/app/check">
	      <i className="bx bx-check-circle" />
	      <span>{t('check')}</span>
	      </Link>
	      </li> */}
          <li>
            <Link to="/app/sna">
              <i className="bx bxl-twitter"></i>
              <span>{t('track')}</span>
            </Link>
          </li>
          <li>
            <Link to="/app/search/articles/list">
              <i className="bx bx-search-alt" />
              <span>{t('alert')}</span>
            </Link>
          </li>
          {currentUser?.can?.EDIT_USER && (
            <>
              <li className="menu-title">{t('admin')}</li>
              <li>
                <Link to="/app/users">
                  <i className="bx bx-user" />
                  <span>{t('users')}</span>
                </Link>
              </li>
            </>
          )}
        </MetisMenu>
      </div>
    </SimpleBar>
  )
}

export default SidebarContent
