import { createSlice } from '@reduxjs/toolkit'

import api from '../ApiClient'

const initialState = {
  data: [],
  pagination: { count: 0, limit: 0, offset: 0, total: 0 },
  queryWords: [],
  termFeatures: { docCount: 0, termDocFreq: {} },
}

const recordingSlice = createSlice({
  name: 'recordings',
  initialState,
  reducers: {
    createRecording(state, action) {},

    appendRecordings(state, action) {
      const data = action.payload.data
      const pagination = action.payload.pagination
      const queryWords = action.payload.queryWords
      const termFeatures = action.payload.termFeatures
      return {
        ...state,
        data: [...state.data, ...data],
        pagination,
        queryWords,
        termFeatures: {
          docCount: termFeatures.docCount,
          termDocFreq: {
            ...state.termFeatures.termDocFreq,
            ...termFeatures.termDocFreq,
          },
        },
      }
    },

    resetRecordings(state, action) {
      return initialState
    },
  },
})

export const { createRecording, appendRecordings, resetRecordings } =
  recordingSlice.actions

export const initializeRecordings = (search = '', offset = 0) => {
  return async (dispatch) => {
    let options = { offset }
    if (search) {
      options = { ...options, search }
    }
    const response = await api.get('/recordings', options)
    if (response.ok) {
      if (offset === 0) {
        dispatch(resetRecordings(response.body))
      }
      dispatch(appendRecordings(response.body))
    } else {
      dispatch(resetRecordings())
    }
  }
}

export default recordingSlice.reducer
