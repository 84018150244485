import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { eraseCookie, getCookie } from './cookieUtils'

export type TokenPayload = {
  name: string
  given_name: string
  family_name: string
  email: string
  custom: {
    country: string
    language: string
    client_slug: string
  }
  resource_access: {
    [key: string]: {
      roles: string[]
    }
  }
  exp: number
  auth_time: number
  iat: number
}

export type User = {
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  roles?: string[]
  picture?: string
  accessToken?: string
  refreshToken?: string
  language: string
  clientSlug?: string
}

type RefreshTokenResponse = {
  accessToken: string
  refreshToken: string
}

export default class AuthService {
  static getUserFromToken(token: string) {
    try {
      const decodedToken: TokenPayload = jwtDecode(token)
      return {
        name: decodedToken?.name,
        firstName: decodedToken?.given_name,
        lastName: decodedToken?.family_name,
        email: decodedToken?.email,
        roles: decodedToken?.resource_access['buster-client']?.roles || [],
        accessToken: token,
        refreshToken: getCookie('bi_refresh_token'),
        language: this.getLang(),
        clientSlug: decodedToken?.custom?.client_slug,
      } as User
    } catch (error) {
      return undefined
    }
  }

  static hasRole(role: string, user: User) {
    return user?.roles?.includes(role)
  }

  static getUser(): User | undefined {
    let storageUser
    const persisted = localStorage.getItem('persist:bai')
    if (persisted) storageUser = JSON.parse(persisted)?.user
    if (storageUser) return JSON.parse(storageUser as string) as User
    return undefined
  }

  static getAccessToken(): string | undefined {
    const user = this.getUser()
    return getCookie('bi_access_token') || user?.accessToken
  }

  static getRefreshToken(): string | undefined {
    const user = this.getUser()
    return getCookie('bi_refresh_token') || user?.refreshToken
  }

  static eraseCookies(): void {
    eraseCookie('bi_access_token', process.env.REACT_APP_COOKIES_DOMAIN)
    eraseCookie('bi_refresh_token', process.env.REACT_APP_COOKIES_DOMAIN)
  }

  static updateAccessToken(token: string): void {
    eraseCookie('bi_access_token', process.env.REACT_APP_COOKIES_DOMAIN)
    document.cookie = `bi_access_token=${token};domain=${process.env.REACT_APP_COOKIES_DOMAIN}; path=/`
  }

  static updateRefreshToken(token: string): void {
    eraseCookie('bi_refresh_token', process.env.REACT_APP_COOKIES_DOMAIN)
    document.cookie = `bi_refresh_token=${token};domain=${process.env.REACT_APP_COOKIES_DOMAIN}; path=/`
  }

  static getLang(): 'fr' | 'en' {
    const userLang = this.getUserLang()
    const language = userLang || navigator.language
    return language.includes('fr') ? 'fr' : 'en'
  }

  private static getUserLang() {
    try {
      const parsedToken: TokenPayload = jwtDecode(this.getAccessToken()!)
      const language = parsedToken?.custom?.language || parsedToken?.custom?.country
      return language === 'fr' ? 'fr' : 'en'
    } catch (error) {
      return null
    }
  }

  static signOut = () => {
    this.eraseCookies()
    window.open(
      `${process.env.REACT_APP_MIFTAH_API_URI}auth/logout?redirect_uri=${process.env.REACT_APP_AUTH_APP_DOMAIN}signin?continue=${window.location.origin}`,
      '_self',
    )
  }

  static redirectToAuthApp(): void {
    if (process.env.REACT_APP_SKIP_AUTH === 'true') return
    // delete user access token if obsolete
    localStorage.removeItem('persist:bai')
    window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN}signin?continue=${window.location.href}`, '_self')
  }

  static getRefreshInterval(): number {
    try {
      const parsedToken: TokenPayload = jwtDecode(this.getAccessToken()!)
      const tokenLifetime = parsedToken.exp - parsedToken.iat
      return (tokenLifetime / 2) * 1000
    } catch (error) {
      return 300000
    }
  }

  static async refresh(onFailure?: () => void): Promise<User | undefined> {
    try {
      const response = await axios
        .create({
          headers: { Authorization: `Bearer ${this.getAccessToken()}` },
        })
        .post<RefreshTokenResponse>(`${process.env.REACT_APP_MIFTAH_API_URI}auth/refresh`, {
          refreshToken: this.getRefreshToken(),
        })
      const token = response.data
      if (token) {
        this.updateAccessToken(token.accessToken)
        this.updateRefreshToken(token.refreshToken)
      }
      return this.getUserFromToken(token.accessToken)
    } catch {
      if (onFailure) onFailure()
      return undefined
    }
  }
}
