import React from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

const CustomStackedColumnChart = ({ dataColors, periodData, xaxis }) => {
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 0,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: '20px',
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: xaxis,
      labels: {
        show: true,
        rotate: -30,
        rotateAlways: true,
      },
    },
    colors: dataColors,
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    fill: {
      opacity: 1,
    },
  }
  return <ReactApexChart options={options} series={[...periodData]} type="bar" height="205" className="apex-charts" />
}

CustomStackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default CustomStackedColumnChart
