import ReactApexChart from 'react-apexcharts'

const CustomPieChart = ({ data, total, legend, showLabels, height, donut, position /* see data tab */ }) => {
  const options = {
    labels: data?.map((e) => e.label),
    colors: data?.map((e) => e.color),
    legend: { show: legend ?? false, position: position ?? 'top' },
    dataLabels: {
      enabled: showLabels ?? true,
    },
    plotOptions: {
      pie: {
        margin: 0,
        expandOnClick: false,
        donut: {
          size: donut ?? '35%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '5px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: undefined,
              offsetY: 8,
              formatter: function (val) {
                return val
              },
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: '#6A677B',
              offsetY: -25,
              formatter: function (val) {
                return val
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Assets',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#6A677B',
              formatter: function (w) {
                return total
                // return w.globals.seriesTotals.reduce((a, b) => {
                //   return a + b
                // }, 0)
              },
            },
          },
        },
      },
    },
  }
  return <ReactApexChart options={options} series={data?.map((e) => e.value)} type="donut" height={height} className="apex-charts" />
}

export default CustomPieChart
