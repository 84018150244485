import React from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import BreadCrumb from '../../components/BreadCrumb'

const CheckImagePage = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('check')} breadcrumbItem={t('image')} />
          <Row className="justify-content-center">
            <Col xl={12} lg={12}>
              <Card className="py-4">
                <CardBody></CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CheckImagePage
