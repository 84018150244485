import { useSelector } from 'react-redux'
import Article from './Article'
import ISO3166 from 'iso-3166-1'

const Articles = ({ documents, enableSort = false }) => {
  const selectedId = useSelector((state) => state.check.selectedId)

  let sortedDocuments = documents
  if (enableSort) {
    sortedDocuments = sortedDocuments?.sort((a, b) => (b.datePublished > a.datePublished ? 1 : -1))
  }

  return (
    <>
      {sortedDocuments?.map((d, i) => {
        const country = d?.publisher?.country?.toLowerCase()
        const publisherCountry = country ? ISO3166.whereAlpha3(country)?.alpha2?.toLowerCase() : ''
        return (
          <Article
            id={d?.id}
            tagId={`article-${i + 1}`}
            key={d?.url}
            url={d?.url}
            verdictLabel={d?.verdictLabel}
            authorName={d?.authors?.[0]}
            publisherName={d?.publisher?.name}
            publisherCountry={publisherCountry}
            publisherKind={d?.publisher?.type?.name}
            publisherCategory={d?.publisher?.type?.category}
            datePublished={d?.dateCreated}
            title={d?.title}
            translatedTitle={d?.translatedTitle}
            headline={d?.headline}
            passages={d?.passages}
            className={selectedId === `${i + 1}` ? 'selected' : ''}
          />
        )
      })}
    </>
  )
}

export default Articles
