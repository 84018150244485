export const formatPermissions = (user) => {
  const { permissions, ...rest } = user
  const permObj = permissions.reduce((acc, p) => {
    return { ...acc, [p.name]: p.value }
  }, {})

  return { ...rest, can: permObj }
}

export const capitalize = (string, allWords) => {
  if (allWords) {
    const arr = string.split(' ')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      string = arr.join(' ')
    }
    return string
  } else {
    return string[0].toUpperCase() + string.slice(1)
  }
}

export const msToTime = (s) => {
  const ms = s % 1000
  s = (s - ms) / 1000
  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60
  const hrs = (s - mins) / 60

  return `${hrs > 0 ? hrs + ':' : ''}${mins}:${secs < 10 ? '0' + secs : secs}`
}

export const formatDuration = (end, start) => {
  if (!end || !start) return
  const value = (end - start) / 1000
  const color = value < 2.5 ? '#009688' : value < 3.5 ? '#ff9800' : '#e91e63'
  return { value: `${parseFloat(value).toFixed(2)} s`, color }
}

export const formattedUser = (currentUser) => {
  const user = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.email,
    role: currentUser.role,
  }
  return user
}

export const autoComplete = {
  firstName: 'given-name',
  lastName: 'family-name',
  password: 'current-password',
  newPassword: 'new-password',
  passwordConfirmation: 'new-password',
  newPasswordConfirmation: 'new-password',
}

export const getInitial = (firstName, lastName) => `${firstName?.[0]}${lastName?.[0]}`
export const truncateText = (text, limit = 400) => {
  if (text?.length < limit) return text
  return `${text?.substring(0, limit)} ...`
}
