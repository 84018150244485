import { Link } from 'react-router-dom'

import logo from '../../assets/images/buster/SVG/Symbol/Logo_picto_1000px_fullcolors_RVB.svg'
import logoDark from '../../assets/images/buster/SVG/Horizontal/Logo_1000px_fullcolors_RVB.svg'
import logoLightSvg from '../../assets/images/buster/SVG/Symbol/Logo_picto_1000px_pictowhite_RVB.svg'
import logoLightPng from '../../assets/images/buster/SVG/Horizontal/Logo_1000px_txtwhite_RVB.svg'
import SidebarContent from './SidebarContent'

const Sidebar = () => {
  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to="/app" className="logo logo-dark">
          <span className="logo-sm">
            <img src={logo} alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logoDark} alt="" height="17" />
          </span>
        </Link>
        <Link to="/app" className="logo logo-light">
          <span className="logo-sm">
            <img src={logoLightSvg} alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logoLightPng} alt="" height="19" />
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <SidebarContent />
      </div>
    </div>
  )
}

export default Sidebar
