import { useMemo, useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { parseISO, differenceInMinutes } from 'date-fns'
import LineChart from './LineChart'

const PostCharts = ({ links }) => {
  const [chartType, setChartType] = useState({ type: 'cascadeSize', x: 'createdAt', y: 'size' })
  const data = useMemo(() => {
    const date = parseISO(links?.[0].createdAt)
    const data = [
      {
        id: chartType?.type,
        data: links
          .map(({ [chartType?.type]: y, [chartType?.x]: x }) => ({ x: differenceInMinutes(parseISO(x), date), y }))
          .filter((i) => i.x < 600),
      },
    ]
    return data
  }, [chartType?.type, chartType?.x, links])
  return (
    <>
      <div className="chart-radio d-flex justify-content-center mt-3">
        {!!links?.length && (
          <ButtonGroup>
            <Button
              outline
              onClick={() => setChartType({ type: 'cascadeSize', x: 'createdAt', y: 'size' })}
              active={chartType?.type === 'cascadeSize'}>
              Size
            </Button>
            <Button
              outline
              onClick={() => setChartType({ type: 'cascadeDepth', x: 'createdAt', y: 'depth' })}
              active={chartType?.type === 'cascadeDepth'}>
              Depth
            </Button>
            <Button
              outline
              onClick={() => setChartType({ type: 'cascadeBreadth', x: 'createdAt', y: 'breadth' })}
              active={chartType?.type === 'cascadeBreadth'}>
              Breadth
            </Button>
            <Button
              outline
              onClick={() => setChartType({ type: 'cascadeVirality', x: 'createdAt', y: 'virality' })}
              active={chartType?.type === 'cascadeVirality'}>
              Virality
            </Button>
          </ButtonGroup>
        )}
      </div>
      {!!links?.length && (
        <div className="card d-flex justify-content-center" style={{ height: 200 }}>
          <LineChart data={data} legend={{ x: 'Time(minutes)', y: chartType?.y }} />
        </div>
      )}
    </>
  )
}

export default PostCharts
