import { behaviorMapping } from '../pages/app/CheckTextData'
export default class AnalysisMapper {
  static computeNerScores = (ners) => {
    const rawScores = ners?.reduce((acc, ner) => {
      let newScore = 1
      if (ner.entity in acc) {
        newScore = acc[ner.entity] + 1
      }
      return { ...acc, [ner.entity]: newScore }
    }, {})
    const nbEntities = Object.values(rawScores ?? {}).reduce((acc, v) => acc + v, 0)
    const nerScores = Object.entries(rawScores ?? {}).map(([k, v]) => {
      return { entity: k, percent: Math.round((v / nbEntities) * 100) }
    })

    return nerScores
  }

  static formatText = (text, ner) => {
    const nerOccurrences = ner?.reduce((acc, ner) => {
      let occs = [ner.text]
      if (ner.entity in acc) {
        occs = acc[ner.entity].concat(occs)
        occs = occs.sort((a, b) => b.length - a.length)
      }
      return { ...acc, [ner.entity]: occs }
    }, {})

    let textCopy = text
    let nerBounds = Object.entries(nerOccurrences ?? {}).reduce((acc, [entity, occs]) => {
      return acc
        .concat(
          occs.map((occ) => {
            const start = textCopy.indexOf(occ)
            const len = occ.length

            if (start > -1) {
              textCopy = textCopy.substring(0, start) + ' '.repeat(len) + textCopy.substring(start + len, text.length)

              return { start, len, entity }
            }
            return {}
          }),
        )
        .filter((span) => Object.keys(span).length > 0)
    }, [])

    nerBounds = nerBounds.sort((a, b) => a.start - b.start)

    let parts = []
    let lastEnd = 0
    for (let i = 0; i < nerBounds.length; i++) {
      if (nerBounds[i].start > 0) {
        parts.push({
          text: text.substring(lastEnd, nerBounds[i].start),
          label: undefined,
        })
      }

      lastEnd = nerBounds[i].start + nerBounds[i].len

      parts.push({
        text: text.substring(nerBounds[i].start, lastEnd),
        label: nerBounds[i].entity,
      })
    }

    if (lastEnd < text.length) {
      parts.push({
        text: text.substring(lastEnd, text.length),
        label: undefined,
      })
    }

    return parts
  }

  static computeBehaviorScores = (outputBehavior) => {
    const behaviorScores = Object.entries(outputBehavior).map(([k, v]) => {
      const signal = behaviorMapping[k]
      let label = undefined
      let score = 0
      let color = '#353d42'
      let scores = []

      switch (signal) {
        case 'Polarity':
          label = v?.sentiment
          score = v?.score ?? 0
          if (label === 'POSITIVE') {
            color = '#00e1bd'
          } else if (label === 'NEGATIVE') {
            color = '#e91e63'
          }
          break
        case 'Emotion':
          const emotions = v
            ?.slice()
            ?.filter((element) => element?.score >= 0.1)
            ?.sort((a, b) => b.score - a.score)
          label = emotions?.[0]?.emotion
          score = emotions?.[0]?.score ?? 0
          scores = emotions?.map((e) => ({ label: e.emotion, score: e.score }))
          break
        case 'Toxicity':
          const toxicity = v
            ?.slice()
            // ?.filter((element) => element?.score >= 0.4)
            ?.sort((a, b) => b.score - a.score)
          scores = toxicity
          const toxicityLabel = toxicity?.find((t) => t.label === 'TOXICITY')
          if (toxicityLabel && toxicity?.length > 1) {
            const index = toxicity.indexOf(toxicityLabel)
            if (index !== -1) {
              toxicity.splice(index, 1)
            }
          }
          label = toxicity?.[0]?.label
          score = toxicity?.[0]?.score
          scores = toxicity
          color = '#e91e63'
          break
        default:
          break
      }

      return { signal, label, score, color, scores }
    })
    return behaviorScores
  }

  static getPolarityColor = (polarity) => {
    let color
    switch (polarity) {
      case 'POSITIVE':
        color = '#009688'
        break
      case 'NEGATIVE':
        color = '#e91e63'
        break
      case 'NEUTRAL':
        color = '#484848'
        break
      default:
        color = '#8C8C8C'
        break
    }
    return color
  }
}
