import { useSelector } from 'react-redux'
import { usePostsFindAllQuery } from '../store/sna/snaApi'
import { RootState } from '../store'
import { useMemo } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'

function useSharedPostsFindAllQuery() {
  const currentAlertId = useSelector((state: RootState) => state.post.currentAlertId)
  const searchAfter = useSelector((state: RootState) => state.post.searchAfter)
  const sortOrder = useSelector((state: RootState) => state.post.order)

  const customPostsData = usePostsFindAllQuery(
    currentAlertId ? { platform: 'twitter', alertId: currentAlertId, searchAfter, order: sortOrder } : skipToken,
    { pollingInterval: parseInt(process.env.REACT_APP_SNA_POLLING_INTERVAL || '0', 10) * 1000 },
  )

  const posts = useMemo(() => {
    const sortedPosts = customPostsData?.currentData?.posts?.slice()?.sort((a, b) => b.createdAt!.localeCompare(a.createdAt!))
    return sortedPosts || []
  }, [customPostsData?.currentData?.posts])

  const interval = customPostsData?.currentData?.interval
  return { ...customPostsData, posts, interval }
}

export default useSharedPostsFindAllQuery
