import { createSlice } from '@reduxjs/toolkit'

import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from '../constants/layout'

const initialState = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.LIGHT,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.LIGHT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeLayout(state, action) {
      return {
        ...state,
        layoutType: action.payload,
      }
    },
    changePreLoader(state, action) {
      return {
        ...state,
        isPreloader: action.payload,
      }
    },
    changeLayoutWidth(state, action) {
      return {
        ...state,
        layoutWidth: action.payload,
      }
    },
    changeSideBarTheme(state, action) {
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    },
    changeSideBarThemeImage(state, action) {
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    },
    changeSideBarType(state, action) {
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    },
    changeTopbarTheme(state, action) {
      return {
        ...state,
        topbarTheme: action.payload,
      }
    },
    toggleRightSidebar(state, action) {
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      }
    },
    showRightSidebar(state, action) {
      return {
        ...state,
        showRightSidebar: true,
      }
    },
    hideRightSidebar(state, action) {
      return {
        ...state,
        showRightSidebar: false,
      }
    },
  },
})

export const {
  changeLayout,
  changePreLoader,
  changeLayoutWidth,
  changeSideBarTheme,
  changeSideBarThemeImage,
  changeSideBarType,
  changeTopbarTheme,
  toggleRightSideBar,
  showRightSidebar,
  hideRightSidebar,
} = layoutSlice.actions

const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value)
  return true
}

const manageBodyClass = (cssClass, action = 'toggle') => {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass)
      break
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass)
      break
    default:
      if (document.body) document.body.classList.toggle(cssClass)
      break
  }

  return true
}

export const updateLayout = (layout) => {
  return (dispatch) => {
    if (layout === 'horizontal') {
      // dispatch(changeTopbarTheme('dark'))
      document.body.removeAttribute('data-sidebar')
      document.body.removeAttribute('data-sidebar-image')
      document.body.removeAttribute('data-sidebar-size')
    } /* else {
       * dispatch(changeTopbarTheme('light'))
	 } */

    changeBodyAttribute('data-layout', layout)
    dispatch(changeLayout(layout))
  }
}

export const updatePreLoader = (isPreloader) => {
  return (dispatch) => {
    dispatch(changePreLoader(isPreloader))
  }
}

export const updateLayoutWidth = (width) => {
  return (dispatch) => {
    if (width === 'boxed') {
      // changeSidebarTypeAction("icon")
      changeBodyAttribute('data-layout-size', width)
      changeBodyAttribute('data-layout-scrollable', false)
    } else if (width === 'scrollable') {
      // changeSidebarTypeAction("default")
      changeBodyAttribute('data-layout-scrollable', true)
    } else {
      // changeSidebarTypeAction("default")
      changeBodyAttribute('data-layout-size', width)
      changeBodyAttribute('data-layout-scrollable', false)
    }
    dispatch(changeLayoutWidth(width))
  }
}

export const updateSideBarTheme = (theme) => {
  return (dispatch) => {
    changeBodyAttribute('data-sidebar', theme)
    dispatch(changeSideBarTheme(theme))
  }
}

export const updateSideBarThemeImage = (theme) => {
  return (dispatch) => {
    changeBodyAttribute('data-sidebar-image', theme)
    dispatch(changeSideBarThemeImage(theme))
  }
}

export const updateSideBarType = (type) => {
  return (dispatch) => {
    const { sidebarType, isMobile } = type

    switch (sidebarType) {
      case 'compact':
        changeBodyAttribute('data-sidebar-size', 'small')
        manageBodyClass('sidebar-enable', 'remove')
        manageBodyClass('vertical-collpsed', 'remove')
        break
      case 'icon':
        changeBodyAttribute('data-sidebar-size', '')
        changeBodyAttribute('data-keep-enlarged', 'true')
        manageBodyClass('vertical-collpsed', 'add')
        break
      case 'condensed':
        // alert('condensed');
        manageBodyClass('sidebar-enable', 'add')
        if (window.screen.width >= 998) {
          manageBodyClass('vertical-collpsed', 'remove')
          manageBodyClass('sidebar-enable', 'remove')
          manageBodyClass('vertical-collpsed', 'add')
          manageBodyClass('sidebar-enable', 'add')
          break
        } else {
          manageBodyClass('sidebar-enable', 'add')
          manageBodyClass('vertical-collpsed', 'add')
        }
        // if (!isMobile)
        break
      default:
        changeBodyAttribute('data-sidebar-size', '')
        manageBodyClass('sidebar-enable', 'remove')
        if (!isMobile) {
          manageBodyClass('vertical-collpsed', 'remove')
        }
        break
    }

    dispatch(changeSideBarType(type))
  }
}

export const updateTopbarTheme = (theme) => {
  return (dispatch) => {
    changeBodyAttribute('data-topbar', theme)
    dispatch(changeTopbarTheme(theme))
  }
}

export const switchRightSideBar = () => {
  return (dispatch) => {
    manageBodyClass('right-bar-enabled')
    dispatch(toggleRightSideBar())
  }
}

export const displayRightSideBar = () => {
  return (dispatch) => {
    manageBodyClass('right-bar-enabled', 'add')
    dispatch(showRightSidebar())
  }
}

export const dismissRightSideBar = () => {
  return (dispatch) => {
    manageBodyClass('right-bar-enabled', 'remove')
    dispatch(hideRightSidebar())
  }
}

export default layoutSlice.reducer
