import React from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

const TreemapChart = ({ nersData }) => {
  const options = {
    chart: {},
    plotOptions: {
      treemap: {
        enableShades: false,
        distributed: true,
      },
    },
    markers: {
      colors: ['#F44336', '#E91E63', '#9C27B0'],
    },
  }
  return (
    <ReactApexChart
      options={options}
      series={[{ data: [...nersData?.map((e) => ({ x: e.text, y: e.count }))] }]}
      type="treemap"
      height="327"
      className="apex-charts"
    />
  )
}

TreemapChart.propTypes = {
  nersData: PropTypes.any,
}
export default TreemapChart
