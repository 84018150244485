import Select from 'react-select'
import { formatDistance, isBefore } from 'date-fns'

const Option = (props) => {
  const { innerProps, innerRef } = props
  return (
    <div ref={innerRef} {...innerProps} className="custom-option p-2 d-flex justify-content-between">
      <div className="sub">{props.data.label} </div>
      {props.data?.lang && props?.data?.date && props?.data.dateEnd && (
        <div className="d-flex flex-column align-items-end">
          <div className="d-flex align-items-center">
            {props.data?.lang?.map((l) => (
              <div className="badge badge-soft-primary font-size-11 me-1" key={`lang-${props.data.id}-${l}`}>
                {l}
              </div>
            ))}
            {isBefore(new Date(props.data?.dateEnd), new Date()) ? (
              <div className="text-white px-1 font-size-11 fw-bold rounded" style={{ background: '#e91e63' }}>
                Inactive
              </div>
            ) : (
              <div className="text-white px-1 font-size-11 fw-bold rounded" style={{ background: '#009688' }}>
                Active
              </div>
            )}
          </div>
          <div className="d-block text-muted font-size-11 fw-bold">
            {formatDistance(new Date(props.data?.date), new Date(), { addSuffix: true })}
          </div>
        </div>
      )}
    </div>
  )
}

const SelectSearchBar = ({ options, search, onSearch }) => {
  return (
    <>
      <div className="searchbar">
        <div className="position-relative">
          <Select
            className="search-select"
            defaultValue={{ value: search?.id || search, label: search?.text || search }}
            onChange={(option) => onSearch(option?.value)}
            options={options}
            components={{ Option }}
          />
          <span className="bx bx-bell" />
        </div>
      </div>
    </>
  )
}

export default SelectSearchBar
