import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Row } from 'reactstrap'

import Header from './vertical_layout/Header'
import Sidebar from './vertical_layout/Sidebar'

import {
  updateLayout,
  updateSideBarTheme,
  updateLayoutWidth,
  updateSideBarThemeImage,
  updateSideBarType,
  updateTopbarTheme,
} from '../reducers/layoutReducer'
import CopyRight from './CopyRight'

const VDashBoard = () => {
  const dispatch = useDispatch()
  const sidebarmenu = document.getElementById('sidebar-menu')

  const toggleMenu = () => {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable')
    } else {
      body.classList.toggle('vertical-collpsed')
      body.classList.toggle('sidebar-enable')
    }
    // console.log(sidebarmenu.classList.contains('position-fixed'))
    if (sidebarmenu.classList.contains('position-fixed')) {
      sidebarmenu.classList.remove('position-fixed')
    } else {
      sidebarmenu.classList.add('position-fixed')
    }
  }

  useEffect(() => {
    // Scroll Top to 0
    window.scrollTo(0, 0)
    // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

    // document.title =
    //   currentage + " | Skote - React Admin & Dashboard Template"
    dispatch(updateLayout('vertical'))
    dispatch(updateSideBarTheme('light'))
    dispatch(updateSideBarThemeImage('none'))
    dispatch(updateLayoutWidth('fluid'))
    dispatch(updateSideBarType('default'))
    dispatch(updateTopbarTheme('light'))
  }, [dispatch])

  return (
    <div id="layout-wrapper">
      <Header toggleMenu={toggleMenu} />
      <Sidebar />
      <div className="main-content">
        <Outlet />
      </div>
      <footer className="footer">
        <Container fluid>
          <Row>
            <CopyRight />
          </Row>
        </Container>
      </footer>
    </div>
  )
}

export default VDashBoard
