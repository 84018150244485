import * as React from 'react'

export interface KeyphraseProps {
  text: string
  onDelete: (event: React.MouseEvent<HTMLElement>, text: string) => void
}

export const Keyphrase = ({ text, onDelete }: KeyphraseProps) => {
  return (
    <span className="claim">
      {text}
      <i className="bx bx-x" onClick={(event) => onDelete(event, text)} />
    </span>
  )
}
