import React from 'react'
import LottieAnimation from './LoadingAnimation'
import animationData from '../../assets/animations/animation-search_in_progress.json'

const LoadingComponent = ({
  hasData,
  isEmpty,
  isLoading,
  error,
}: {
  hasData: boolean
  isEmpty: boolean
  isLoading: boolean
  error: any
}) => {
  return (
    <div className="mt-5 d-flex justify-content-center load-container">
      <div className="source-loading d-flex flex-column align-items-center">
        <LottieAnimation animationData={animationData} autoplay={isLoading} style={{ width: 200 }} />
        <div className="title">Searching for sources</div>
        <div className="description">We are scanning different types of sources to find documents related to what you want to search.</div>
        {!hasData && !isLoading && <span>Type information that you would like to check</span>}
        {isLoading && <span>It may take a few seconds.</span>}
        {error && (
          <div className="alert alert-danger mt-2" role="alert">
            {/* <a href="#" className="alert-link">
              {error?.data?.code}
            </a>{' '} */}
            {error?.data?.message}
          </div>
        )}
        {isEmpty && !error && (
          <div className="alert alert-warning mt-2" role="alert">
            No result match this query!
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadingComponent
