import React from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import BreadCrumb from '../../components/BreadCrumb'
import AlertsTable from '../../components/sna/alert/AlertsTable'

const SocialNetworkAlertPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Buster.Ai" breadcrumbItem={'Alert'} />
          <Row className="justify-content-center">
            <Col xl={12} lg={12}>
              <Card className="py-4">
                <CardBody>
                  <AlertsTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SocialNetworkAlertPage
