import { verdictColors } from 'pages/app/CheckTextData'
import ISO6391 from 'iso-639-1'
import languages from '@cospired/i18n-iso-languages'
export default class DashboardMediaMapper {
  static parseSentiments = (inputData) => {
    const xaxis = []
    const dataPolarity = [
      { name: 'NEGATIVE', data: [] },
      { name: 'POSITIVE', data: [] },
      { name: 'NEUTRAL', data: [] },
    ]

    const dates = Object.keys(inputData).sort()

    dates.forEach((date, index) => {
      const objects = inputData[date]
      console.log({ objects, date, inputData })
      const formattedDate = new Date(date).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
      })
      xaxis.push(formattedDate)

      dataPolarity.forEach((polarityObject) => {
        const polarity = polarityObject.name
        const value = objects?.[polarity.toLowerCase()]
        if (value) {
          polarityObject.data[index] = value
        } else if (polarityObject.data.length === index) {
          polarityObject.data[index] = 0
        }
      })
    })

    return { xaxis, dataPolarity }
  }

  static getReports = (data) => {
    return [
      { title: 'Article', iconClass: 'bx-copy-alt', count: data?.count },
      { title: 'Positive', iconClass: 'bxs-right-top-arrow-circle', count: data?.positive },
      {
        title: 'Negative',
        iconClass: 'bxs-right-down-arrow-circle',
        count: data?.negative,
      },
    ]
  }

  static getVerdicts = (data) => {
    const verdictData = []
    Object.keys(data).forEach((key) => {
      if (key !== 'count') {
        verdictData.push({
          label: key.split('_').join(' '),
          value: data?.[key] ?? 0,
          color: verdictColors[key],
        })
      }
    })
    return verdictData
  }

  static getLanguages = (data) => {
    const langData = []
    Object.keys(data).forEach((key) => {
      if (key !== 'count') {
        const lang = languages.alpha3TToAlpha2(key.toLowerCase())
        langData.push({
          label: ISO6391.getName(lang) ?? lang,
          value: data?.[key] ?? 0,
          color: this.getLangColor(lang),
        })
      }
    })
    return langData
      ?.slice()
      ?.sort((a, b) => b.value - a.value)
      ?.slice(0, 5)
  }
  static getTopics = (data) => {
    const topics = []
    Object.keys(data).forEach((key) => {
      topics.push({
        value: key,
        count: data?.[key],
      })
    })
    return topics.slice(0, 5)
  }

  static parseAuthors = (data) => {
    const authors = []
    Object.keys(data).forEach((key) => {
      authors.push({
        name: key,
        count: data?.[key],
      })
    })
    return authors?.slice(0, 3)
  }

  static parseNers = (entities) => {
    const combinedEntities = entities?.reduce((acc, entity) => {
      const existingEntity = acc.find((item) => item.value === entity.value && item.type === entity.type)
      if (existingEntity) {
        // Create a new object with the updated count property
        const updatedEntity = { ...existingEntity, count: existingEntity.count + entity.count }
        // Replace the existing entity with the updated one in the accumulator
        acc = acc.map((item) => (item.value === updatedEntity.value && item.type === updatedEntity.type ? updatedEntity : item))
      } else {
        acc.push(entity)
      }
      return acc
    }, [])

    const sortedEntities = combinedEntities?.sort((a, b) => b.doc_count - a.doc_count)
    return sortedEntities?.map((e) => ({ count: e.count, label: e.type, text: e.value })).slice(0, 10)
  }

  static parseEmotions = (data) => {
    const emotionsData = []
    Object.keys(data).forEach((key) => {
      emotionsData.push({ label: key, value: data[key], color: this.getEmotionColor(key) })
    })
    return emotionsData
      ?.slice()
      ?.sort((a, b) => b.value - a.value)
      ?.filter((e) => e.value > 0)
      ?.slice(0, 5)
  }

  static getPolarityColor = (polarity) => {
    let color
    switch (polarity) {
      case 'POSITIVE':
        color = '#009688'
        break
      case 'NEGATIVE':
        color = '#e91e63'
        break
      case 'NEUTRAL':
        color = '#484848'
        break
      default:
        color = '#3f51b5'
        break
    }
    return color
  }
  static getEmotionColor = (emotion) => {
    const emotionColors = {
      ANGER: '#F44336',
      DISGUST: '#4CAF50',
      FEAR: '#FF9800',
      JOY: '#FFEB3B',
      NEUTRAL: '#9E9E9E',
      SADNESS: '#03A9F4',
      SURPRISE: '#673AB7',
      ADMIRATION: '#E91E63',
      AMUSEMENT: '#009688',
      ANNOYANCE: '#795548',
      APPROVAL: '#8BC34A',
      CARING: '#FFC107',
      CONFUSION: '#607D8B',
      CURIOSITY: '#00BCD4',
      DESIRE: '#9C27B0',
      DISAPPOINTMENT: '#FF5722',
      DISAPPROVAL: '#FFCDD2',
      EMBARRASSMENT: '#FFEBEE',
      EXCITEMENT: '#CDDC39',
      GRATITUDE: '#F8BBD0',
      GRIEF: '#ECEFF1',
      LOVE: '#FFEBEE',
      NERVOUSNESS: '#D1C4E9',
      OPTIMISM: '#FFF3E0',
      PRIDE: '#C5E1A5',
      REALIZATION: '#FFF9C4',
      RELIEF: '#B3E5FC',
      REMORSE: '#EF9A9A',
      UNDEF: '#EEEEEE',
      NA: '#FFFFFF',
    }

    const color = emotionColors[emotion]

    if (color) {
      return color
    } else {
      return '#000000'
    }
  }

  static getLangColor = (languageCode) => {
    const materialColors = {
      en: '#F44336',
      es: '#4CAF50',
      fr: '#FF9800',
      de: '#FFEB3B',
      it: '#9E9E9E',
      pt: '#03A9F4',
      ja: '#673AB7',
      zh: '#E91E63',
      ru: '#009688',
      ar: '#795548',
      hi: '#8BC34A',
      ko: '#FFC107',
      tr: '#607D8B',
      nl: '#00BCD4',
      pl: '#9C27B0',
      sv: '#FF5722',
      da: '#FFCDD2',
      fi: '#FFEBEE',
      no: '#CDDC39',
      el: '#F8BBD0',
      he: '#ECEFF1',
      id: '#FFEBEE',
      ms: '#D1C4E9',
      th: '#FFF3E0',
      cs: '#C5E1A5',
      sk: '#FFF9C4',
      hu: '#B3E5FC',
      ro: '#EF9A9A',
      uk: '#EEEEEE',
      na: '#FFFFFF',
    }

    const color = materialColors[languageCode]

    if (color) {
      return color
    } else {
      return '#000000'
    }
  }
}
