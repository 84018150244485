import React, { useMemo, useState } from 'react'
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../store'
import { showPostDocuments } from '../../store/sna/postSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useClaimsReportMutation, usePostsGetPostDocumentsQuery } from '../../store/sna/snaApi'
import Article from '../Article'
import ISO3166 from 'iso-3166-1'
import Select, { MultiValue } from 'react-select'
import { skipToken } from '@reduxjs/toolkit/query'

type Option = { label: string; value: string }

const SourcesModal = () => {
  const { t } = useTranslation()
  const currentPost = useSelector((state: RootState) => state.post.currentPostDocuments)
  const { currentData: postDocuments, isFetching } = usePostsGetPostDocumentsQuery(
    currentPost?.id ? { postId: currentPost?.id } : skipToken,
  )
  const [reportClaim, { isLoading: reportLoading, data: reportData }] = useClaimsReportMutation()

  const [showSend, setShowSend] = useState(false)
  const [recipient, setRecipient] = useState([
    { label: 'Author', value: 'author' },
    { label: 'Community', value: 'community' },
  ])

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const documents = useMemo(
    () =>
      postDocuments?.documents
        ?.filter((source) => source?.verdictLabel !== 'NA')
        ?.filter((d) => d.datePublished !== undefined)
        ?.sort((a, b) => (b.datePublished! > a.datePublished! ? 1 : -1)),
    [postDocuments],
  )
  const isReportAvailable = useMemo(() => reportData && reportData?.claim === currentPost?.text, [reportData, currentPost])
  const dispatch = useDispatch()

  const generateArgumentation = async () => {
    setIsDrawerOpen(true)
    if (!isReportAvailable) {
      await reportClaim({
        claim: {
          claim: currentPost?.text,
          documents,
          globalVerdictLabel: currentPost?.globalVerdictLabel?.label,
        },
      })
    }
  }

  const onClose = () => {
    setShowSend(false)
    dispatch(showPostDocuments(undefined))
  }

  const selectRecipient = (selectedMulti: MultiValue<Option>) => {
    setRecipient(selectedMulti as Option[])
  }

  // const [postHighlight, { data: highlights }] = usePostAnalyzeHighlightMutation()

  // TODO enable highlight when fixed in back
  // useEffect(() => {
  //   if (currentPost && documents?.length) {
  //     postHighlight({
  //       body: {
  //         referenceText: currentPost.text,
  //         texts: documents?.map((d) => d?.passages?.[0]?.text) as string[],
  //       },
  //     })
  //   } // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPost])

  return (
    <Modal isOpen={currentPost !== undefined && !isFetching} size="lg" toggle={onClose} centered={true} fade={false}>
      <ModalHeader>Sources</ModalHeader>
      <div className={`drawer ${isDrawerOpen && isReportAvailable ? 'open' : ''}`}>
        <div>
          {isReportAvailable && (
            <div className="p-3">
              <div className="drawerHeader d-flex justify-content-between py-2">
                <h4>Counter argumentation</h4>
                <button
                  onClick={() => setIsDrawerOpen(false)}
                  style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    padding: 0,
                    fontSize: 22,
                  }}>
                  <i className="mdi mdi-close me-1" />
                </button>
              </div>
              <div className="mb-3">
                <label className="control-label">To</label>
                <Select
                  value={recipient}
                  isMulti={true}
                  onChange={selectRecipient}
                  options={[
                    { label: 'Author', value: 'author' },
                    { label: 'Community', value: 'community' },
                  ]}
                />
              </div>
              <label className="control-label">Message</label>
              <Input name="report" type="textarea" rows="15" defaultValue={reportData?.report ?? ''} />
              {!showSend && (
                <div className="text-sm-end mt-3">
                  <Button color="primary" className="font-16 btn-block btn btn-primary">
                    <i className="mdi mdi-send me-1" />
                    Send
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ModalBody className="py-2 px-3" style={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
        <div>
          {currentPost &&
            !isFetching &&
            documents?.map((d, i) => {
              const country = d?.publisher?.country?.toLowerCase()
              const publisherCountry = country ? ISO3166.whereAlpha3(country)?.alpha2?.toLowerCase() : ''
              return (
                <Article
                  id={d?.id}
                  tagId={`article-${i + 1}`}
                  key={d?.url}
                  url={d?.url}
                  verdictLabel={d?.verdictLabel}
                  authorName={d?.authors?.[0]}
                  publisherName={d?.publisher?.name}
                  publisherCountry={publisherCountry}
                  publisherKind={d?.publisher?.type?.name}
                  publisherCategory={d?.publisher?.type?.category}
                  datePublished={d?.datePublished}
                  headline={d?.headline}
                  passages={d?.passages?.slice(0, 1) ?? []}
                  title={''}
                  translatedTitle={''}
                  className={''}
                />
              )
            })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <div className="d-flex mt-1">
              {currentPost?.globalVerdictLabel?.label !== 'CONFIRMED' && (
                <button
                  type="button"
                  className="d-flex align-items-center btn btn-primary btn-xl me-2"
                  style={{ gap: 10 }}
                  disabled={(isReportAvailable && isDrawerOpen) || reportLoading}
                  onClick={generateArgumentation}>
                  {reportLoading && <Spinner color="light" size="sm" className="mr-2"></Spinner>}
                  Counter arg.
                </button>
              )}
              <button type="button" className="btn btn-secondary btn-xl me-2" onClick={onClose}>
                {t('close')}
              </button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default SourcesModal
