import { nextStep, setError, setDocuments, setReport, setReportHighlights, setGlobalVerdict } from 'store/check/checkSlice'
import { snaApi } from './snaApi'
import { RootState } from 'store'

const api = snaApi.enhanceEndpoints({
  addTagTypes: ['Alert'],
  endpoints: {
    alertsFindAll: {
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) => (result ? [...result.map(({ id }) => ({ type: 'Alert' as const, id })), 'Alert'] : ['Alert']),
    },
    alertsUpdateAlert: {
      onQueryStarted: async (arg, { queryFulfilled, getState, dispatch }) => {
        await queryFulfilled.then(() => dispatch(api.util.invalidateTags([{ type: 'Alert', id: arg.alertId }])))
      },
    },
    alertsNew: {
      invalidatesTags: ['Alert'],
    },
    alertsDeleteAlert: {
      onQueryStarted: async (arg, { queryFulfilled, getState, dispatch }) => {
        await queryFulfilled.then(() => dispatch(api.util.invalidateTags([{ type: 'Alert', id: arg.alertId }])))
      },
    },
    claimsCheck: {
      onQueryStarted: async (arg, { queryFulfilled, getState, dispatch }) => {
        // `onStart` side-effect
        const previousClaim = (getState() as RootState)?.check?.claim
        try {
          const data = await queryFulfilled
          const currentClaim = (getState() as RootState)?.check?.claim
          if (currentClaim === previousClaim) {
            dispatch(setDocuments({ documents: data.data.documents, status: 'CHECK' }))
            dispatch(setGlobalVerdict(data.data.globalVerdictLabel ?? ''))
            dispatch(nextStep('CHECK'))
          }
        } catch (err) {
          dispatch(setError({ error: 'Something went wrong...', status: 'CHECK' }))
        }
      },
    },
    claimsSearchAndCheck: {
      keepUnusedDataFor: 10,
    },
    claimsHighlight: {
      onQueryStarted: async (arg, { queryFulfilled, getState, dispatch }) => {
        // `onStart` side-effect
        const previousClaim = (getState() as RootState)?.check?.claim
        try {
          const data = await queryFulfilled
          const currentClaim = (getState() as RootState)?.check?.claim
          if (currentClaim === previousClaim) {
            dispatch(setDocuments({ documents: data.data.documents, status: 'HIGHLIGHT' }))
            dispatch(setReportHighlights({ reportHighlights: data.data.reportHighlights, status: 'HIGHLIGHT' }))
            dispatch(nextStep('HIGHLIGHT'))
          }
        } catch (err) {
          dispatch(setError({ error: 'Something went wrong...', status: 'HIGHLIGHT' }))
        }
      },
    },
    claimsReport: {
      onQueryStarted: async (arg, { queryFulfilled, getState, dispatch }) => {
        // `onStart` side-effect
        const previousClaim = (getState() as RootState)?.check?.claim
        try {
          const data = await queryFulfilled
          const currentClaim = (getState() as RootState)?.check?.claim
          if (currentClaim === previousClaim) {
            dispatch(nextStep('REPORT'))
            dispatch(setReport(data.data.report ?? ''))
          }
        } catch (err) {
          dispatch(setError({ error: 'Something went wrong...', status: 'REPORT' }))
        }
      },
    },
  },
})

export const apiSnaActions = api.util
export const apiSnaReducer = api.reducer
export const apiSnaSlice = api
