import { useRef } from 'react'
import { arc } from 'd3'
import { Col, Card, CardBody, UncontrolledTooltip } from 'reactstrap'

const Donut = ({ radius, signal, label, score, color, scores }) => {
  const ref = useRef(null)

  const scoreArc = arc()
    .innerRadius(radius - 5)
    .outerRadius(radius)
    .startAngle(0)
    .endAngle(2 * score * Math.PI)

  return (
    <Col className="text-center">
      <p className="text-muted" id={signal}>
        {signal}
        {scores?.length > 1 && <i ref={ref} className="bx bx-info-circle ms-1" />}
      </p>
      {scores?.length > 0 && (
        <UncontrolledTooltip
          className="t-behavior"
          placement="top"
          target={ref}
          style={{ background: 'white', border: '1px solid #5589f5b5', padding: '8px 8px' }}>
          <div className="d-flex flex-column bg-white" style={{ gap: 5 }}>
            {scores?.map((s, i) => (
              <span key={`dnt-${signal}-${i}`} className="topics p-1 font-size-11 lh-1 fw-bold" style={{ opacity: s.score + 0.5 }}>
                {s.label.replaceAll('_', ' ')}: {Math.round(s.score * 100)}%
              </span>
            ))}
          </div>
        </UncontrolledTooltip>
      )}
      <Card>
        <CardBody className="p-0">
          <svg width={radius * 2} height={radius * 2}>
            <g transform={`translate(${[radius, radius].join(',')})`}>
              <circle r={radius - 2.5} stroke="#cccccc" strokeWidth="5" fill="none" />
              <path d={scoreArc()} fill={color} />
            </g>
            {(label ?? '0%')?.split('_').map((l, i) => {
              const y = radius * 2 * 0.5
              return (
                <text
                  dy={y + i * 10}
                  dx="50%"
                  textAnchor="middle"
                  key={`txt-${i}`}
                  style={{
                    fill: '#2a3135',
                    fontWeight: 550,
                    fontSize: 10,
                  }}>
                  <tspan x="0" y="0">
                    {l}
                  </tspan>
                </text>
              )
            })}
          </svg>
        </CardBody>
      </Card>
    </Col>
  )
}

export default Donut
