import { Link } from 'react-router-dom'

import logo from '../../assets/images/logo.svg'
import logoLight from '../../assets/images/logo-light.png'
import logoLightSvg from '../../assets/images/logo-light.svg'
import logoDark from '../../assets/images/logo-dark.png'

import ProfileMenu from '../ProfileMenu'
import NotificationDropdown from '../NotificationDropdown'

const Header = () => {
  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="17" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightSvg} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="19" />
              </span>
            </Link>
          </div>
        </div>

        <div className="d-flex">
          <form className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input type="text" className="form-control" placeholder="Search..." />
              <span className="bx bx-search-alt"></span>
            </div>
          </form>
        </div>

        <div className="d-flex">
          <div className="dropdown d-none d-lg-inline-block ms-1">
            <button type="button" onClick={toggleFullscreen} className="btn header-item noti-icon" data-toggle="fullscreen">
              <i className="bx bx-fullscreen"></i>
            </button>
          </div>

          <ProfileMenu />
          <NotificationDropdown />
        </div>
      </div>
    </header>
  )
}

export default Header
