import React, { useMemo } from 'react'
import Select, { ActionMeta, ClearIndicatorProps, MultiValue, PropsValue, SingleValue } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { selectSourcesType } from 'store/check/checkSlice'
import { RootState } from 'store'

interface Option {
  value: string
  label: string
  abbreviation: string
}

const options: Option[] = [
  { value: 'INFORMATION', label: 'Media', abbreviation: 'MEDIA' },
  { value: 'INSTITUTIONAL', label: 'Institutional', abbreviation: 'INST' },
  { value: 'NON_PROFIT', label: 'Non-Profit', abbreviation: 'NP' },
  { value: 'RESEARCH', label: 'Research', abbreviation: 'RSCH' },
  { value: 'OTHER', label: 'Other', abbreviation: 'OTH' },
]

const SourcesTypeSelect: React.FC = () => {
  const dispatch = useDispatch()

  const sourceTypes = useSelector((state: RootState) => state.check.sourceTypes)

  // Map sourceTypes to Option objects
  const selectedOptions: Option[] | undefined = useMemo(() => {
    if (sourceTypes) {
      return sourceTypes.map((value) => {
        const option = options.find((opt) => opt.value === value)
        return option || { value: '', label: '', abbreviation: '' } // Fallback if not found
      })
    }
    return undefined
  }, [sourceTypes])

  const selectCategory = (newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
      // Only update the category when an option is selected or removed
      dispatch(selectSourcesType((newValue as Option[]).map((e) => e.value)))
    }
  }

  const handleClear = () => {
    dispatch(selectSourcesType([]))
  }

  // Custom function to format the label with abbreviation
  const formatOptionLabel = ({ label, abbreviation }: Option, { context }: { context: string }) => {
    if (context === 'value') {
      // Display abbreviation for selected value tags
      return <div>{abbreviation}</div>
    }
    // Display only the label for dropdown options
    return <div>{label}</div>
  }

  return (
    <div style={{ width: 400, maxWidth: '90vw' }} className="source-type">
      <Select
        value={selectedOptions as PropsValue<Option>} // Ensure type matches PropsValue<Option>
        isMulti={true}
        onChange={selectCategory}
        placeholder="Select sources type..."
        options={options}
        formatOptionLabel={formatOptionLabel} // Use the custom label formatter
        components={{
          ClearIndicator: (clearProps: ClearIndicatorProps<Option>) => (
            <div onClick={handleClear} className="font-size-16">
              <i className="bx bx-x" />
            </div>
          ),
        }}
      />
    </div>
  )
}

export default SourcesTypeSelect
