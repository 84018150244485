const Ner = ({ entity, color, percent, setCurrNer }) => {
  const nerEnter = (ev) => {
    setCurrNer(ev.target.textContent)
  }

  const nerLeave = (ev) => {
    setCurrNer(undefined)
  }

  return (
    <span
      className="p-1 font-size-10 fw-bold text-white rounded shadow"
      style={{
        backgroundColor: color,
      }}
      onMouseEnter={(ev) => nerEnter(ev)}
      onMouseLeave={(ev) => nerLeave(ev)}>
      {entity}
    </span>
  )
}

export default Ner
