import userLogo from '../../assets/images/twitter-user.png'
import { isValid, parseISO, format } from 'date-fns'

const UserCard = ({ user: { name, username }, publishedTime, postId }) => {
  const handleClick = (e) => {
    if (e.ctrlKey) window.open(`https://twitter.com/${username}/status/${postId}`, '_blank')
  }
  return (
    <div className="user p-2" onClick={handleClick}>
      <div className="d-flex">
        <img className="rounded-circle" width={30} height={27} src={userLogo} alt={`user-${username}`} />
        <div className="ps-3 w-100">
          <span className="title">{name}</span>
          <p className="m-0">{username && `@${username}`}</p>
          <span className="time">
            {publishedTime && isValid(parseISO(publishedTime)) && format(parseISO(publishedTime), 'dd-MM-yyyy HH:mm')}
          </span>{' '}
        </div>
      </div>
    </div>
  )
}

export default UserCard
