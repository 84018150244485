import { startOfMonth, startOfQuarter, startOfToday, startOfWeek, startOfYear, subDays, subHours, subYears } from 'date-fns'

export const getDatesFromRange = (range: string) => {
  let dates: Date[] = []
  switch (range) {
    case 'Last 12 hours':
      dates = [subHours(new Date(), 12), new Date()]
      break
    case 'Last 24 Hours':
      dates = [subHours(new Date(), 24), new Date()]
      break
    case 'Today':
      dates = [startOfToday(), new Date()]
      break
    case 'Last 7 Days':
      dates = [subDays(new Date(), 7), new Date()]
      break
    case 'Last 30 Days':
      dates = [subDays(new Date(), 30), new Date()]
      break
    case 'Last 12 Month':
      dates = [subYears(new Date(), 1), new Date()]
      break
    case 'This Week':
      dates = [startOfWeek(new Date()), new Date()]
      break
    case 'This Month':
      dates = [startOfMonth(new Date()), new Date()]
      break
    case 'This Quarter':
      dates = [startOfQuarter(new Date()), new Date()]
      break
    case 'This Year':
      dates = [startOfYear(new Date()), new Date()]
      break
    default:
      dates = [subYears(new Date(), 1), new Date()]
      break
  }
  return dates?.map((d) => d.toISOString())
}

export const isoDateToLocale = (date: string) =>
  new Date(date)?.toLocaleDateString('fr-FR', { hour: '2-digit', minute: '2-digit' }).replace(/\//g, '-')

export const dateToISO = (date: Date) => {
  if (date) {
    date.setMilliseconds(0)
    return date.toISOString().replace('.000Z', 'Z')
  }
  return null
}
