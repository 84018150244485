import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import Header from './horizontal_layout/Header'
import Navbar from './horizontal_layout/Navbar'
import Footer from './Footer'

import {
  updateLayout,
  updateLayoutWidth,
  updateTopbarTheme,
} from '../reducers/layoutReducer'

const HDashBoard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Scrollto 0,0
    window.scrollTo(0, 0)

    // const title = this.props.location.pathname
    // let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    // document.title =
    //   currentage + " | Skote - React Admin & Dashboard Template"
    dispatch(updateLayout('horizontal'))
    dispatch(updateLayoutWidth('fluid'))
    dispatch(updateTopbarTheme('dark'))
  }, [dispatch])

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default HDashBoard
