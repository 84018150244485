import { useRef, useState, useEffect } from 'react'
import * as d3 from 'd3'

const combineChartDimensions = (dimensions) => {
  const parsedDimensions = {
    ...dimensions,
    marginTop: dimensions.marginTop !== undefined ? dimensions.marginTop : 10,
    marginRight: dimensions.marginRight !== undefined ? dimensions.marginRight : 10,
    marginBottom: dimensions.marginBottom !== undefined ? dimensions.marginBottom : 40,
    marginLeft: dimensions.marginLeft !== undefined ? dimensions.marginLeft : 75,
  }

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom, 0),
    boundedWidth: Math.max(parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight, 0),
  }
}

export const useChartDimensions = (passedSettings) => {
  const ref = useRef()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const dimensions = combineChartDimensions(passedSettings)

  useEffect(() => {
    if (dimensions.width && dimensions.height) {
      return [ref, dimensions]
    }

    const element = ref.current

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) {
        return
      }

      if (!entries.length) {
        return
      }

      const entry = entries[0]

      if (width !== entry.contentRect.width) {
        setWidth(entry.contentRect.width)
      }

      if (height !== entry.contentRect.height) {
        setHeight(entry.contentRect.height)
      }
    })

    resizeObserver.observe(element)

    return () => resizeObserver.unobserve(element)
  }, [dimensions, width, height])

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height,
  })

  return [ref, newSettings]
}

const ZOOM_STEP = 500

export const useChartZoom = () => {
  const svgRef = useRef()
  const [scale, setScale] = useState(50)
  const zoom = d3.zoom().on('zoom', () => {
    if (svgRef.current) {
      if (d3.event.transform.k < 0) {
        setScale(Math.max(ZOOM_STEP * d3.event.transform.k, 50))
      } else {
        setScale(Math.min(ZOOM_STEP * d3.event.transform.k, 5000))
      }
    }
  })

  useEffect(() => {
    if (svgRef.current) d3.select(svgRef.current)?.call(zoom)
  }, [zoom])

  return [svgRef, scale]
}
