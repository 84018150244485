import React, { useRef, useState, useEffect } from 'react'
import { Button, Collapse } from 'reactstrap'
import { TwitterFilters } from './TweetsFilters'

const FilterButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const accordionRef = useRef<HTMLDivElement | null>(null)
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  // closing collapse on click outside
  useEffect(() => {
    // Add event listener to the document object
    const handleOutsideClick = (event: MouseEvent) => {
      // Check if the click event occurred outside the collapse content
      if (accordionRef.current && !accordionRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)

    // Remove event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [accordionRef])

  return (
    <div style={{ position: 'relative' }} className="filter-button">
      <Button color="light" onClick={toggle} size="sm" className="d-flex align-items-center font-size-14">
        <i className="bx bx-filter font-size-20 me-1" />
        Filter
      </Button>
      <Collapse isOpen={isOpen}>
        <div
          ref={accordionRef}
          style={{
            position: 'absolute',
            top: '40px',
            right: 0,
            backgroundColor: '#fff',
            padding: '15px',
            zIndex: 9,
            boxShadow: '4px 4px 8px rgba(141,138,160,.05),-4px -4px 8px rgba(141,138,160,.05)',
            borderRadius: 8,
          }}>
          <TwitterFilters />
        </div>
      </Collapse>
    </div>
  )
}

export default FilterButton
