import { Row, Col, Spinner } from 'reactstrap'

const AnalyzeHeader = ({ loading, title, duration, hasError }) => {
  return (
    <Row>
      <Col>
        <p className="fw-bold">{title}</p>
      </Col>
      <Col className="text-end">
        {loading && <Spinner color="primary" size="sm"></Spinner>}
        {process.env.REACT_APP_IS_QA === 'true' && !loading && duration && (
          <span className="duration-metric" style={{ color: duration?.color }}>
            {duration?.value}
          </span>
        )}
        {hasError && <i className="error bi bi-x-circle-fill"></i>}
      </Col>
    </Row>
  )
}

export default AnalyzeHeader
