import { RootState } from 'store'
import { nlpApi } from './nlpApi'
import { nextStep, setError, setCurrentRequestId, setResponse } from 'store/check/checkSlice'

const api = nlpApi.enhanceEndpoints({
  endpoints: {
    query: {
      onQueryStarted: async (arg, { queryFulfilled, getState, dispatch }) => {
        const previousClaim = (getState() as RootState)?.check?.claim
        try {
          const data = await queryFulfilled
          const currentClaim = (getState() as RootState)?.check?.claim
          if (currentClaim === previousClaim) {
            if (data.data) {
              dispatch(nextStep('QUERY'))
              dispatch(setCurrentRequestId(data.data))
            }
          }
        } catch (err: any) {
          // TODO implement error message handler
          const errorCode = err?.error?.data?.code
          let errorMessage = err?.error?.data?.message ?? 'Something went wrong...'
          if (['315', '316'].includes(errorCode)) errorMessage = 'This claim is too short.'
          if (errorCode === '301') errorMessage = 'This claim is too long.'
          if (arg?.body.claim) {
            dispatch(setError({ error: errorMessage, status: 'QUERY' }))
          }
        }
      },
    },
    result: {
      onQueryStarted: async (arg, { queryFulfilled, getState, dispatch }) => {
        const previousClaim = (getState() as RootState)?.check?.claim
        const previousId = (getState() as RootState)?.check?.id
        try {
          const data = await queryFulfilled
          const currentClaim = (getState() as RootState)?.check?.claim
          const currentId = (getState() as RootState)?.check?.id
          const status = (getState() as RootState)?.check?.status

          if (currentClaim === previousClaim) {
            if (status === 'REPORT' && data.data.highlight?.length) {
              dispatch(
                setResponse({
                  response: {
                    reportHighlights: data.data.highlight!,
                  },
                  status: 'HIGHLIGHT',
                }),
              )
              dispatch(nextStep('HIGHLIGHT'))
              return
            }
            if (status === 'CHECK' && data.data.report?.length) {
              dispatch(
                setResponse({
                  response: {
                    report: data.data.report!,
                  },
                  status: 'REPORT',
                }),
              )
              dispatch(nextStep('REPORT'))
              return
            }
            if (status === 'SEARCH' && data.data.globalVerdictLabel?.label) {
              dispatch(
                setResponse({
                  response: {
                    documents: data.data.documents,
                    globalVerdict: data.data.globalVerdictLabel?.label,
                  },
                  status: 'CHECK',
                }),
              )
              dispatch(nextStep('CHECK'))
              return
            }
            if (status === 'QUERY' && data.data.documents) {
              dispatch(
                setResponse({
                  response: {
                    documents: data.data.documents,
                  },
                  status: 'SEARCH',
                }),
              )
              dispatch(nextStep('SEARCH'))
              return
            }
          }
          if (currentId === previousId && status === 'INITIAL') {
            if (data.data.globalVerdictLabel?.label) {
              dispatch(
                setResponse({
                  response: {
                    claim: data.data.claim,
                    documents: data.data.documents,
                    globalVerdict: data.data.globalVerdictLabel?.label,
                    report: data.data.report!,
                    reportHighlights: data.data.highlight!,
                  },
                  status: 'HIGHLIGHT',
                  skipStatusValidation: true,
                }),
              )
              return
            }
          }
        } catch (err: any) {
          const status = (getState() as RootState)?.check?.status
          // TODO implement error message handler
          const errorCode = err?.error?.data?.code
          let errorMessage = err?.error?.data?.message ?? 'Something went wrong...'
          if (['315', '316'].includes(errorCode)) errorMessage = 'This claim is too short.'
          if (errorCode === '301') errorMessage = 'This claim is too long.'
          if (errorCode === '007') errorMessage = 'As a non factual statement, the claim cannot be verified.'
          if (arg?.input && arg?.waitFor) {
            dispatch(setError({ error: errorMessage, status: status }))
          }
        }
      },
    },
  },
})

export const apiActions = api.util
export const apiReducer = api.reducer
export const apiSlice = api
