import ReactApexChart from 'react-apexcharts'

const PieChart = ({ data, legend, showLabels, height, donut, position /* see data tab */ }) => {
  const options = {
    labels: data?.map((e) => e.label),
    colors: data?.map((e) => e.color),
    legend: { show: legend ?? false, position: position ?? 'top' },
    dataLabels: {
      enabled: showLabels ?? true,
    },
    plotOptions: {
      pie: {
        donut: {
          size: donut ?? '35%',
        },
      },
    },
  }
  return <ReactApexChart options={options} series={data?.map((e) => e.value)} type="donut" height={height} className="apex-charts" />
}

export default PieChart
