/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goToPage } from '../../store/sna/postSlice'
import { RootState } from '../../store'

type PaginationButtonsProps = {
  interval: { start: string; end: string }
  firstPostDate: string
  lastPostDate: string
}

const PaginationButtons = ({ interval, firstPostDate, lastPostDate }: PaginationButtonsProps) => {
  const dispatch = useDispatch()

  const hasPagination = useSelector((state: RootState) => state.post.hasPagination)

  const disabledNext = useMemo(() => {
    const maxDate = Number(interval?.end)
    const searchFrom = new Date(firstPostDate).getTime()
    if (searchFrom && maxDate) return searchFrom >= maxDate
    return true
  }, [firstPostDate, interval?.end])

  const disabledPrevious = useMemo(() => {
    const minDate = Number(interval?.start)
    const searchFrom = new Date(lastPostDate).getTime()
    if (searchFrom && minDate) return searchFrom <= minDate
    return true
  }, [interval?.start, lastPostDate])

  const previous = () => {
    dispatch(goToPage({ from: lastPostDate, order: 'desc' }))
  }

  const next = () => {
    dispatch(goToPage({ from: firstPostDate, order: 'asc' }))
  }
  return (
    (hasPagination || !disabledNext || !disabledPrevious) && (
      <nav>
        <ul className="pagination pagination-sm">
          <li className={`page-item ${disabledPrevious ? 'disabled' : ''}`}>
            <a className="page-link" href="#" aria-label="Previous" onClick={previous}>
              <span>&laquo; Previous</span>
            </a>
          </li>
          <li className={`page-item ${disabledNext ? 'disabled' : ''}`}>
            <a className="page-link" href="#" aria-label="Next" onClick={next}>
              <span>Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    )
  )
}

export default PaginationButtons
