import { useMemo, useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Row, Col, Card, CardBody, CardTitle, Input } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import { FormGroup, UncontrolledTooltip } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import BreadCrumb from '../../components/BreadCrumb'
import Ner from '../../components/Ner'
import Donut from '../../components/Donut'
import ProgressBar from '../../components/ProgressBar'
import AnalyzeHeader from '../../components/AnalyzeHeader'
import { defaultText, verdictColors, nerColors } from './CheckTextData'
import {
  useFactcheckMutation,
  usePostAnalyzeClassifyGarmMutation,
  usePostAnalyzeClassifyIptcMutation,
  usePostAnalyzeEntitiesMutation,
  usePostAnalyzeSentimentsMutation,
  usePostAnalyzeUrlMutation,
  useSummarizationMutation,
} from '../../store/nlpApi'
import { default as mapper } from '../../utils/analysisMapper'
import { formatDuration } from '../../utils/formatting'
import axios from 'axios'

const CheckTextPage = () => {
  const { t } = useTranslation()
  const schema = yup.object({ inputText: yup.string() })
  const defaultValues = { inputText: defaultText }

  const [selectedTasks, setSelectedTasks] = useState({
    topicsIptc: true,
    topicsGARM: true,
    ner: true,
    behavior: true,
    summary: false,
    verdict: false,
    genereted: false,
  })

  const [
    postNers,
    { isLoading: nerLoading, data: nersData, error: nersError, fulfilledTimeStamp: nersEnd, startedTimeStamp: nersStart, reset: resetNers },
  ] = usePostAnalyzeEntitiesMutation({ skip: !selectedTasks?.ner })

  const [
    postTopicsIptc,
    {
      isLoading: topicsIptcLoading,
      data: topicsIptcData,
      error: topicsIptcError,
      fulfilledTimeStamp: topicsIptcReqEnd,
      startedTimeStamp: topicsIptcReqStart,
      reset: resetTopicsIptc,
    },
  ] = usePostAnalyzeClassifyIptcMutation()

  const [
    postTopicsGARM,
    {
      isLoading: topicsGARMLoading,
      data: topicsGARMData,
      error: topicsGARMError,
      fulfilledTimeStamp: topicsGARMReqEnd,
      startedTimeStamp: topicsGARMReqStart,
      reset: resetTopicsGARM,
    },
  ] = usePostAnalyzeClassifyGarmMutation()

  const [
    postUrlAnalysis,
    {
      isLoading: urlAnalysisLoading,
      data: urlAnalysisData,
      error: urlAnalysisError,
      fulfilledTimeStamp: urlAnalysisReqEnd,
      startedTimeStamp: urlAnalysisReqStart,
      reset: resetUrlAnalysis,
    },
  ] = usePostAnalyzeUrlMutation()

  const [
    postSummary,
    {
      isLoading: summaryLoading,
      data: summaryData,
      error: summaryError,
      fulfilledTimeStamp: summaryReqEnd,
      startedTimeStamp: summaryReqStart,
      reset: resetSummary,
    },
  ] = useSummarizationMutation()

  const [
    postBehavior,
    {
      isLoading: behaviorLoading,
      data: behaviorData,
      error: behaviorError,
      fulfilledTimeStamp: behaviorReqEnd,
      startedTimeStamp: behaviorReqStart,
      reset: resetBehavior,
    },
  ] = usePostAnalyzeSentimentsMutation()

  const [
    postCheck,
    {
      isLoading: verdictLoading,
      data: verdictData,
      error: verdictError,
      fulfilledTimeStamp: verdictReqEnd,
      startedTimeStamp: verdictReqStart,
      reset: resetVerdict,
    },
  ] = useFactcheckMutation()

  const [analysisText, setAnalysisText] = useState('')
  const [currNer, setCurrNer] = useState(undefined)

  const nersScores = useMemo(() => mapper.computeNerScores(nersData?.texts), [nersData?.texts])

  const behaviorScores = useMemo(() => {
    const { sentimentPolarity, toxicity, emotions } = behaviorData ?? {}
    return { scores: mapper.computeBehaviorScores({ sentimentPolarity, emotions, toxicity }) }
  }, [behaviorData])

  const outputParts = useMemo(() => mapper.formatText(analysisText, nersData?.texts), [analysisText, nersData?.texts])
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  })

  const resetAll = () => {
    resetTopicsIptc()
    resetTopicsGARM()
    resetNers()
    resetBehavior()
    resetUrlAnalysis()
    resetSummary()
    resetVerdict()
    setTextSource(null)
  }

  const onSubmit = async (data) => {
    resetAll()
    setAnalysisText(data.inputText)
    const body = data.inputText
    let summary
    if (selectedTasks?.topicsIptc) await postTopicsIptc({ body })
    if (selectedTasks?.topicsGARM) await postTopicsGARM({ body })
    if (selectedTasks?.ner) await postNers({ body })
    if (selectedTasks?.urlAnalysis) await postUrlAnalysis({ body })
    if (selectedTasks?.behavior) await postBehavior({ body })
    if (selectedTasks?.summary)
      summary = await postSummary({
        body: {
          texts: [data.inputText],
        },
      })

    if (selectedTasks?.genereted) await checkGenereted(body)
    if (selectedTasks?.verdict && summary?.data?.summarizedText)
      await postCheck({ body: { claim: summary.data.summarizedText?.split('.')[0] ?? summary.data.summarizedText } })
  }

  const onChangeCheckSummary = () => {
    if (selectedTasks.summary && selectedTasks.verdict)
      setSelectedTasks({
        ...selectedTasks,
        summary: false,
        verdict: false,
      })
    else
      setSelectedTasks({
        ...selectedTasks,
        summary: !selectedTasks.summary,
      })
  }

  const [textSourceLoading, setTextSourceLoading] = useState(false)
  const [textSource, setTextSource] = useState(null)

  const checkGenereted = async (text) => {
    setTextSourceLoading(true)
    const response = await axios.post(
      process.env.REACT_APP_GENERATED_TEXT_API_URL + 'query',
      { text },
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_GENERATED_TEXT_API_KEY}`,
        },
      },
    )

    setTextSourceLoading(false)
    setTextSource(response?.data)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('check')} breadcrumbItem={t('text')} />
          <Row className="justify-content-center">
            <Col lg={4}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Raw Input Text</CardTitle>
                  <p className="text-muted">Please copy/paste sample text</p>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <Form.Control
                        as="textarea"
                        spellCheck="false"
                        style={{ height: '400px' }}
                        {...register('inputText')}
                        disabled={isSubmitting}
                      />
                    </FormGroup>
                    <div className="mt-3 d-grid">
                      <button className="btn btn-primary btn-block" type="submit" disabled={isSubmitting}>
                        {t('analyze')}
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informations</CardTitle>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      checked={selectedTasks?.topicsIptc}
                      onChange={() =>
                        setSelectedTasks({
                          ...selectedTasks,
                          topicsIptc: !selectedTasks.topicsIptc,
                        })
                      }
                    />
                    <AnalyzeHeader
                      loading={topicsIptcLoading}
                      title={
                        <span>
                          Topics <span className="badge bg-primary">IPTC</span>
                        </span>
                      }
                      duration={formatDuration(topicsIptcReqEnd, topicsIptcReqStart)}
                      hasError={!!topicsIptcError}
                    />
                  </FormGroup>
                  <Row>
                    {topicsIptcError && <span className="error d-flex justify-content-center"> {topicsIptcError?.data?.message}</span>}
                    <div className="d-flex flex-wrap" style={{ gap: 5 }}>
                      {topicsIptcData?.topics
                        ?.filter((topic) => topic?.score >= 0.4)
                        ?.sort((a, b) => b?.score - a?.score)
                        ?.map((t, idx) => {
                          return (
                            <span
                              key={'topic-' + idx}
                              id={'topic-' + idx}
                              className="topics p-1 font-size-11 lh-1 fw-bold"
                              style={{ opacity: t.score + 0.4 }}>
                              {t.topic.replaceAll('_', ' ')}
                              <UncontrolledTooltip placement="top" target={'topic-' + idx}>
                                {Math.round(t.score * 100)}%
                              </UncontrolledTooltip>
                            </span>
                          )
                        })}
                    </div>
                  </Row>
                  <hr />

                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      checked={selectedTasks?.topicsGARM}
                      onChange={() =>
                        setSelectedTasks({
                          ...selectedTasks,
                          topicsGARM: !selectedTasks.topicsGARM,
                        })
                      }
                    />
                    <AnalyzeHeader
                      loading={topicsGARMLoading}
                      title={
                        <span>
                          Topics <span className="badge bg-primary">GARM</span>
                        </span>
                      }
                      duration={formatDuration(topicsGARMReqEnd, topicsGARMReqStart)}
                      hasError={!!topicsGARMError}
                    />
                  </FormGroup>
                  <Row>
                    {topicsGARMError && <span className="error d-flex justify-content-center"> {topicsGARMError?.data?.message}</span>}
                    <div className="d-flex flex-wrap" style={{ gap: 5 }}>
                      {topicsGARMData?.topics?.map((t, idx) => {
                        return (
                          <span key={'topic-g-' + idx} className="topics p-1 font-size-11 lh-1 fw-bold">
                            {t.replaceAll('_', ' ')}
                          </span>
                        )
                      })}
                    </div>
                  </Row>
                  <hr />

                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      checked={selectedTasks?.ner}
                      onChange={() =>
                        setSelectedTasks({
                          ...selectedTasks,
                          ner: !selectedTasks.ner,
                        })
                      }
                    />
                    <AnalyzeHeader
                      loading={nerLoading}
                      title="Named Entities"
                      duration={formatDuration(nersEnd, nersStart)}
                      hasError={!!nersError}
                    />
                  </FormGroup>
                  <Row>
                    <span>
                      {nersError && <span className="error d-flex justify-content-center"> {nersError?.data?.message}</span>}
                      <div className="d-flex flex-wrap" style={{ gap: 5 }}>
                        {nersScores?.map((ner, idx) => (
                          <Ner
                            key={'ner-' + idx}
                            entity={ner.entity}
                            color={nerColors[ner.entity]}
                            Percent={ner.percent}
                            setCurrNer={setCurrNer}
                          />
                        ))}
                      </div>
                    </span>
                  </Row>
                  <hr />

                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      checked={selectedTasks?.urlAnalysis}
                      onChange={() =>
                        setSelectedTasks({
                          ...selectedTasks,
                          urlAnalysis: !selectedTasks.urlAnalysis,
                        })
                      }
                    />
                    <AnalyzeHeader
                      loading={urlAnalysisLoading}
                      title="Urls analysis"
                      duration={formatDuration(urlAnalysisReqEnd, urlAnalysisReqStart)}
                      hasError={!!urlAnalysisError}
                    />
                  </FormGroup>
                  <Row>
                    {urlAnalysisError && <span className="error d-flex justify-content-center"> {urlAnalysisError?.data?.message}</span>}
                    <div className="d-flex flex-wrap" style={{ gap: 5 }}>
                      {urlAnalysisData?.urls?.map((t, idx) => {
                        return (
                          <span
                            key={'url-' + idx}
                            id={'url-' + idx}
                            className={`urls p-1 font-size-11 lh-1 fw-bold ${t?.urlSafety?.toLowerCase()}`}>
                            {t?.detectedUrl.replaceAll('_', ' ')}
                            <UncontrolledTooltip placement="top" target={'url-' + idx}>
                              {t?.urlSafety}
                            </UncontrolledTooltip>
                          </span>
                        )
                      })}
                    </div>
                  </Row>
                  <hr />

                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      checked={selectedTasks?.behavior}
                      onChange={() =>
                        setSelectedTasks({
                          ...selectedTasks,
                          behavior: !selectedTasks.behavior,
                        })
                      }
                    />
                    <AnalyzeHeader
                      loading={behaviorLoading}
                      title="Behaviorial Signals"
                      duration={formatDuration(behaviorReqEnd, behaviorReqStart)}
                      hasError={!!behaviorError}
                    />
                  </FormGroup>
                  <Row>
                    {behaviorError && <span className="error d-flex justify-content-center"> {behaviorError?.data?.message}</span>}
                    {behaviorData &&
                      behaviorScores?.scores?.map(({ signal, label, score, color, scores }, idx) => {
                        return (
                          <Donut key={'pol-' + idx} radius={40} signal={signal} label={label} score={score} color={color} scores={scores} />
                        )
                      })}
                  </Row>
                  <hr />

                  <FormGroup switch>
                    <Input type="switch" role="switch" checked={selectedTasks?.summary} onChange={onChangeCheckSummary} />
                    <AnalyzeHeader
                      loading={summaryLoading}
                      title="Summary"
                      duration={formatDuration(summaryReqEnd, summaryReqStart)}
                      hasError={!!summaryError}
                    />
                  </FormGroup>
                  <Row>
                    {summaryError && <span className="error d-flex justify-content-center"> {summaryError?.data?.message}</span>}
                    <p className="fw-bold">{summaryData?.summarizedText}</p>
                  </Row>
                  <hr />

                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      checked={selectedTasks?.genereted}
                      onChange={() =>
                        setSelectedTasks({
                          ...selectedTasks,
                          genereted: !selectedTasks.genereted,
                        })
                      }
                    />
                    <AnalyzeHeader
                      loading={textSourceLoading}
                      title={'Check source'}
                      // duration={formatDuration(textSource?.duration * 1000, 1)}
                      hasError={!!textSource?.data?.[0]?.status}
                    />
                  </FormGroup>
                  {textSource && (
                    <Row>
                      {!!textSource?.data?.[0]?.status && (
                        <span className="error d-flex justify-content-center"> {textSource?.data?.[0]?.status}</span>
                      )}
                      {!textSource?.data?.[0]?.status && (
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                            <h5>
                              Written by <b>{textSource?.data?.[0]?.label === 'Artificial' ? 'A.I.' : 'Human'}</b>
                            </h5>
                          </span>
                          <ProgressBar
                            percent={parseFloat(textSource?.data?.[0]?.confidence.replace('%', '')).toFixed(2)}
                            color={textSource?.data?.[0]?.label === 'Artificial' ? '#e91e63' : '#009688'}
                          />
                        </div>
                      )}
                    </Row>
                  )}

                  <hr />
                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      disabled={!selectedTasks?.summary}
                      checked={selectedTasks?.verdict}
                      onChange={() =>
                        setSelectedTasks({
                          ...selectedTasks,
                          verdict: !selectedTasks.verdict,
                        })
                      }
                    />
                    <AnalyzeHeader
                      loading={verdictLoading}
                      title={'Fact-check'}
                      duration={formatDuration(verdictReqEnd, verdictReqStart)}
                      hasError={!!verdictError}
                    />
                  </FormGroup>
                  {verdictData && (
                    <Row>
                      {verdictError && <span className="error d-flex justify-content-center"> {verdictError?.data?.message}</span>}
                      {verdictData && (
                        <>
                          <p className="fw-bold">{verdictData?.curatedClaim}</p>
                          <div className="text-end">
                            <span
                              style={{ background: verdictColors[verdictData?.globalVerdictLabel?.label] }}
                              className="p-1 font-size-12 fw-bold text-white rounded">
                              {verdictData?.globalVerdictLabel?.label}
                            </span>
                          </div>
                        </>
                      )}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Processed Text</CardTitle>
                  {outputParts.map((part, idx) => {
                    let currColor = '#ffffff'
                    let color = '#000000'

                    if (part.label !== undefined) {
                      if (part.label === currNer) {
                        currColor = nerColors[part.label]
                        color = '#ffffff'
                      } else {
                        currColor = '#eeeeee'
                      }
                    }

                    return (
                      <span key={'part' + idx}>
                        <span
                          style={{
                            backgroundColor: currColor,
                            color,
                          }}>
                          {part.text}
                        </span>
                      </span>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default CheckTextPage
