import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Document, DocumentPassage } from 'store/sna/snaApi'

type STEPS = 'INITIAL' | 'QUERY' | 'SEARCH' | 'CHECK' | 'REPORT' | 'HIGHLIGHT'

type EnrichedDocument = Document & {
  translatedTitle?: string
  passages?: EnrichedDocumentPassage[]
}

type EnrichedDocumentPassage = DocumentPassage & {
  translation?: string
}

export interface CheckState {
  id: string | null
  status: STEPS
  sourceTypes: string[] | undefined
  claim: string | null
  report: string | null
  reportHighlights: string[] | undefined
  selectedId: string | null
  error: string | null
  stopCheck: boolean
  documents: EnrichedDocument[] | undefined
  globalVerdict: string | undefined
}

const initialState: CheckState = {
  id: null,
  status: 'INITIAL',
  sourceTypes: ['INFORMATION', 'INSTITUTIONAL'],
  report: null,
  reportHighlights: undefined,
  claim: null,
  selectedId: null,
  error: null,
  stopCheck: false,
  documents: undefined,
  globalVerdict: undefined,
}

// Define the valid next statuses based on the current status
const validNextStatuses: Record<STEPS, STEPS[]> = {
  INITIAL: ['QUERY'],
  QUERY: ['SEARCH'],
  SEARCH: ['CHECK'],
  CHECK: ['REPORT'],
  REPORT: ['HIGHLIGHT'],
  HIGHLIGHT: [],
}

export const checkSlice = createSlice({
  name: 'check',
  initialState,
  reducers: {
    nextStep(state, action: PayloadAction<STEPS>) {
      const nextStatus = action.payload

      if (nextStatus === 'INITIAL') {
        return { ...initialState, sourceTypes: state.sourceTypes, status: nextStatus }
      }

      const currentStatus = state.status

      // Check if the next status is valid based on the current status
      if (!validNextStatuses[currentStatus].includes(nextStatus)) {
        console.warn(`Invalid status transition from ${currentStatus} to ${nextStatus}`)
        return state // Return the current state if the transition is invalid
      }

      // Update the status to the next value
      return { ...state, status: nextStatus }
    },
    setCurrentRequestId(state, action: PayloadAction<string>) {
      return { ...state, id: action.payload }
    },
    setClaim(state, action: PayloadAction<string>) {
      return { ...state, claim: action.payload }
    },
    setStopCheck(state, action: PayloadAction<boolean>) {
      return { ...state, stopCheck: action.payload }
    },
    setReport(state, action: PayloadAction<string>) {
      return { ...state, report: state.status === 'REPORT' ? action.payload : state.report }
    },
    setGlobalVerdict(state, action: PayloadAction<string>) {
      return { ...state, globalVerdict: action.payload }
    },
    selectArticle(state, action: PayloadAction<string>) {
      return { ...state, selectedId: action.payload }
    },
    selectSourcesType(state, action: PayloadAction<string[]>) {
      return { ...state, sourceTypes: action.payload }
    },
    setError(state, action: PayloadAction<{ error: string; status: STEPS }>) {
      if (!validNextStatuses[state.status].includes(action.payload.status) && state.status !== action.payload.status) {
        console.warn(`Invalid status transition from ${state.status} to ${action.payload.status}`)
        return state
      }
      return { ...state, error: action.payload.error }
    },
    setDocuments(state, action: PayloadAction<{ documents: EnrichedDocument[] | undefined; status: STEPS }>) {
      if (!validNextStatuses[state.status].includes(action.payload.status)) {
        console.warn(`Invalid status transition from ${state.status} to ${action.payload.status}`)
        return state
      }
      return { ...state, documents: action.payload.documents }
    },
    setReportHighlights(state, action: PayloadAction<{ reportHighlights: string[] | undefined; status: STEPS }>) {
      if (!validNextStatuses[state.status].includes(action.payload.status)) {
        console.warn(`Invalid status transition from ${state.status} to ${action.payload.status}`)
        return state
      }
      return { ...state, reportHighlights: action.payload.reportHighlights }
    },

    setResponse(
      state,
      action: PayloadAction<{ response: Partial<CheckState> | undefined; status: STEPS; skipStatusValidation?: Boolean }>,
    ) {
      if (!action.payload.skipStatusValidation && !validNextStatuses[state.status].includes(action.payload.status)) {
        console.warn(`Invalid status transition from ${state.status} to ${action.payload.status}`)
        return state
      }
      return { ...state, ...action.payload.response }
    },
    updateDocument(state, action: PayloadAction<{ documentId: string; passageId: number; translation: string; translatedTitle: string }>) {
      const { documentId, passageId, translation, translatedTitle } = action.payload
      const updatedDocuments = (state.documents || []).map((document) => {
        if (document.id === documentId) {
          const updatedPassages = (document.passages || []).map((passage) => {
            if (passage.orderId === passageId) {
              return { ...passage, translation }
            }
            return passage
          })
          return { ...document, translatedTitle, passages: updatedPassages }
        }
        return document
      })
      return { ...state, documents: updatedDocuments }
    },
  },
})

export const {
  setCurrentRequestId,
  nextStep,
  setError,
  setDocuments,
  updateDocument,
  setClaim,
  selectArticle,
  selectSourcesType,
  setReport,
  setGlobalVerdict,
  setReportHighlights,
  setResponse,
  setStopCheck,
} = checkSlice.actions
