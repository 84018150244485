import React from 'react'
import { Table } from 'reactstrap'
import { nerColors } from '../../pages/app/CheckTextData'

const TopNe = ({ entities }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>NE</th>
          <th>Type</th>
          <th>Occurence</th>
        </tr>
      </thead>
      <tbody>
        {entities.map((ne, i) => (
          <tr key={`ne-${i}-${ne.text}`}>
            <th scope="row">{i + 1}</th>
            <td>{ne.text}</td>
            <td>
              <span
                className="p-1 font-size-10 fw-bold text-white rounded shadow"
                style={{ backgroundColor: nerColors?.[ne.label] ?? 'red' }}>
                {ne.label.replaceAll('_', ' ')}
              </span>
            </td>
            <td>{ne.count}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default TopNe
