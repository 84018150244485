import { verdictColors } from 'pages/app/CheckTextData'
import ISO6391 from 'iso-639-1'

export default class DashboardMapper {
  static parseSentiments = (inputData) => {
    const xaxis = []
    const dataPolarity = [
      { name: 'NEGATIVE', data: [] },
      { name: 'POSITIVE', data: [] },
      { name: 'NEUTRAL', data: [] },
    ]

    const dates = Object.keys(inputData).sort()

    dates.forEach((date, index) => {
      const objects = inputData[date]
      const formattedDate = new Date(date).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
      })
      xaxis.push(formattedDate)

      dataPolarity.forEach((polarityObject) => {
        const polarity = polarityObject.name
        const object = objects.find((obj) => Object.keys(obj)[0] === polarity)

        if (object) {
          const value = object[polarity]
          polarityObject.data[index] = value
        } else if (polarityObject.data.length === index) {
          polarityObject.data[index] = 0
        }
      })
    })

    return { xaxis, dataPolarity }
  }

  static getReports = (data) => {
    return [
      { title: 'Tweet', iconClass: 'bx-copy-alt', count: data?.count },
      { title: 'Positive', iconClass: 'bxs-right-top-arrow-circle', count: data?.POSITIVE },
      {
        title: 'Negative',
        iconClass: 'bxs-right-down-arrow-circle',
        count: data?.NEGATIVE,
      },
    ]
  }

  static getVerdicts = (data) => {
    const verdictData = []
    Object.keys(data).forEach((key) => {
      if (key !== 'count') {
        verdictData.push({
          label: key.split('_').join(' '),
          value: data?.[key] ?? 0,
          color: verdictColors[key],
        })
      }
    })
    return verdictData
  }

  static getLanguages = (data) => {
    const langData = []
    Object.keys(data).forEach((key) => {
      if (key !== 'count') {
        langData.push({
          label: ISO6391.getName(key),
          value: data?.[key] ?? 0,
          color: this.getLangColor(key),
        })
      }
    })
    return langData?.slice()?.sort((a, b) => b.value - a.value)
  }

  static parseAuthors = (data) => {
    return data?.map((author) => ({
      name: author.authorId,
      count: author.maxShared,
    }))
  }

  static parseNers = (entities) => {
    const combinedEntities = entities?.reduce((acc, entity) => {
      const existingEntity = acc.find((item) => item.text === entity.text && item.label === entity.label)
      if (existingEntity) existingEntity.count += entity.count
      else acc.push(entity)
      return acc
    }, [])
    const sortedEntities = combinedEntities?.sort((a, b) => b.doc_count - a.doc_count)
    return sortedEntities?.slice(0, 10)
  }

  static parseEmotions = (data) => {
    const emotionsData = []
    Object.keys(data).forEach((key) => {
      emotionsData.push({ label: key, value: data[key], color: this.getEmotionColor(key) })
    })
    return emotionsData?.slice()?.sort((a, b) => b.value - a.value)
  }

  static getPolarityColor = (polarity) => {
    let color
    switch (polarity) {
      case 'POSITIVE':
        color = '#009688'
        break
      case 'NEGATIVE':
        color = '#e91e63'
        break
      case 'NEUTRAL':
        color = '#484848'
        break
      default:
        color = '#3f51b5'
        break
    }
    return color
  }
  static getEmotionColor = (emotion) => {
    const emotionColors = {
      ANGER: '#F44336',
      DISGUST: '#4CAF50',
      FEAR: '#FF9800',
      JOY: '#FFEB3B',
      NEUTRAL: '#9E9E9E',
      SADNESS: '#03A9F4',
      SURPRISE: '#673AB7',
      ADMIRATION: '#E91E63',
      AMUSEMENT: '#009688',
      ANNOYANCE: '#795548',
      APPROVAL: '#8BC34A',
      CARING: '#FFC107',
      CONFUSION: '#607D8B',
      CURIOSITY: '#00BCD4',
      DESIRE: '#9C27B0',
      DISAPPOINTMENT: '#FF5722',
      DISAPPROVAL: '#FFCDD2',
      EMBARRASSMENT: '#FFEBEE',
      EXCITEMENT: '#CDDC39',
      GRATITUDE: '#F8BBD0',
      GRIEF: '#ECEFF1',
      LOVE: '#FFEBEE',
      NERVOUSNESS: '#D1C4E9',
      OPTIMISM: '#FFF3E0',
      PRIDE: '#C5E1A5',
      REALIZATION: '#FFF9C4',
      RELIEF: '#B3E5FC',
      REMORSE: '#EF9A9A',
      UNDEF: '#EEEEEE',
      NA: '#FFFFFF',
    }

    const color = emotionColors[emotion]

    if (color) {
      return color
    } else {
      return '#000000'
    }
  }

  static getLangColor = (languageCode) => {
    const materialColors = {
      en: '#F44336',
      es: '#4CAF50',
      fr: '#FF9800',
      de: '#FFEB3B',
      it: '#9E9E9E',
      pt: '#03A9F4',
      ja: '#673AB7',
      zh: '#E91E63',
      ru: '#009688',
      ar: '#795548',
      hi: '#8BC34A',
      ko: '#FFC107',
      tr: '#607D8B',
      nl: '#00BCD4',
      pl: '#9C27B0',
      sv: '#FF5722',
      da: '#FFCDD2',
      fi: '#FFEBEE',
      no: '#CDDC39',
      el: '#F8BBD0',
      he: '#ECEFF1',
      id: '#FFEBEE',
      ms: '#D1C4E9',
      th: '#FFF3E0',
      cs: '#C5E1A5',
      sk: '#FFF9C4',
      hu: '#B3E5FC',
      ro: '#EF9A9A',
      uk: '#EEEEEE',
      na: '#FFFFFF',
    }

    const color = materialColors[languageCode]

    if (color) {
      return color
    } else {
      return '#000000'
    }
  }
}
