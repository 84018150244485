import { useEffect } from 'react'
import { Control, FieldValues, UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form'
import { getDatesFromRange } from '../../../utils/dates'

export const useOnChangeInterval = (
  INTERVAL_FIELD_NAME: string,
  DATES_FIELD_NAME: string,
  control: Control<FieldValues, any>,
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
) => {
  const interval = useWatch({ control, name: INTERVAL_FIELD_NAME })
  useEffect(() => {
    const [startDate, endDate] = getDatesFromRange(watch(INTERVAL_FIELD_NAME))
    setValue(DATES_FIELD_NAME, { start: startDate, end: endDate })
  }, [interval, DATES_FIELD_NAME, INTERVAL_FIELD_NAME, setValue, watch])
}
